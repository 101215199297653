import { Radio } from '@material-ui/core';
import styles from './Radio.module.scss';

/**
 * # Radio
 * Simple Foodbomb Radio Tag
 */

const FBRadio = ({ ...props }) => (
  <Radio {...props} classes={{ root: styles.FBRadio, checked: styles.FBRadio__checked }} />
);

export default FBRadio;
