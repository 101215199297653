import ContentLoader from 'react-content-loader';

const TableLoadingBackground = () => (
  <ContentLoader
    speed={3}
    width={996}
    height={1006}
    viewBox="0 0 996 1006"
    backgroundColor="#f7f7f7"
    foregroundColor="#fdfdfd"
  >
    <rect x="0" y="0" rx="8" ry="8" width="996" height="12" />
    <rect x="0" y="5" rx="0" ry="0" width="996" height="20" />
    <rect x="0" y="55" rx="0" ry="0" width="996" height="20" />
    <rect x="0" y="15" rx="0" ry="0" width="29" height="48" />
    <rect x="118" y="15" rx="0" ry="0" width="53" height="48" />
    <rect x="422" y="16" rx="0" ry="0" width="89" height="48" />
    <rect x="954" y="16" rx="0" ry="0" width="47" height="48" />
    <rect x="717" y="15" rx="0" ry="0" width="57" height="48" />
    <rect x="-1" y="127" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="183" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="238" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="294" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="350" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="405" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="460" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="516" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="571" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="627" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="683" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="738" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="793" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="849" rx="0" ry="0" width="1000" height="5" />
    <rect x="-1" y="904" rx="0" ry="0" width="1000" height="5" />
    <rect x="375" y="938" rx="4" ry="4" width="250" height="30" />
    <rect x="30" y="86" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="86" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="86" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="86" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="143" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="143" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="143" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="143" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="199" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="199" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="199" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="199" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="256" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="256" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="256" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="256" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="310" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="310" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="310" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="310" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="364" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="364" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="364" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="364" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="420" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="420" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="420" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="420" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="477" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="477" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="477" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="477" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="531" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="531" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="531" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="531" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="587" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="587" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="587" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="587" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="644" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="644" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="644" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="644" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="698" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="698" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="698" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="698" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="752" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="752" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="752" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="752" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="808" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="808" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="808" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="808" rx="4" ry="4" width="130" height="30" />
    <rect x="30" y="865" rx="4" ry="4" width="100" height="30" />
    <rect x="173" y="865" rx="4" ry="4" width="298" height="30" />
    <rect x="510" y="865" rx="4" ry="4" width="228" height="30" />
    <rect x="822" y="865" rx="4" ry="4" width="130" height="30" />
  </ContentLoader>
);

export default TableLoadingBackground;
