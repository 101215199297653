import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './Menu.module.scss';

/**
 * # Menu
 * Simple Foodbomb Menu Component
 *
 * ## PropTypes:
 * ```js
 *  Menu.propTypes = {
 *    children: PropTypes.node,
 *    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
 *   *  };
 * ```
 */

const FBMenu = ({ anchorEl, children, ...props }) => (
  <Popover
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    classes={{
      paper: styles.MenuPaper,
    }}
    className={styles.MenuRoot}
    {...props}
  >
    {children}
  </Popover>
);

FBMenu.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default FBMenu;
