import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FoodbombAPI } from '../../AxiosInstances';
import { Typography } from '../UI/FB';
import { TYPOGRAPHY_TYPES } from '../../utils/Constants';

import styles from './Login.module.scss';
import QueryStringParser from '../../utils/queryStringParser';
import useEventTracking from '../../hooks/useEventTracking/useEventTracking.ts';
import LoginForm from './LoginForm.tsx';

const Login = (props) => {
  const [verifyStatus, setVerifyStatus] = useState('');
  const location = useLocation();
  const { trackVerifyEmail } = useEventTracking();
  const values = QueryStringParser(location.search);
  const { code } = values;

  const VERIFICATION_STATUS = {
    VERIFIED: 'verified',
    INVALID_CODE: 'invalid',
    USED_CODE: 'used',
    VERIFYING: 'verifying',
  };

  useEffect(() => {
    if (code) {
      setVerifyStatus(VERIFICATION_STATUS.VERIFYING);
      FoodbombAPI.post(`/account/verify-user/${code}`)
        .then((response) => {
          setVerifyStatus(VERIFICATION_STATUS.VERIFIED);
          trackVerifyEmail(response.data?.accountId);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setVerifyStatus(VERIFICATION_STATUS.INVALID_CODE);
          } else if (error.response && error.response.status === 403) {
            setVerifyStatus(VERIFICATION_STATUS.USED_CODE);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {verifyStatus === VERIFICATION_STATUS.VERIFIED ? (
        <div className={styles.LoginPageBanner}>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={styles.BannerText}>
            <span role="img" aria-label="celebrate">
              🎉&nbsp;
            </span>
            Congrats! You have officially joined Foodbomb!
          </Typography>
        </div>
      ) : null}
      {verifyStatus === VERIFICATION_STATUS.INVALID_CODE ? (
        <div className={[styles.LoginPageBanner, styles.Red].join(' ')}>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={styles.BannerText}>
            <span role="img" aria-label="examining-face">
              🧐&nbsp;
            </span>
            The link you clicked seems to be invalid. Please contact support.
          </Typography>
        </div>
      ) : null}
      {verifyStatus === VERIFICATION_STATUS.VERIFYING ? (
        <div className={[styles.LoginPageBanner, styles.Yellow].join(' ')}>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={[styles.BannerText, styles.Pending].join(' ')}>
            <span role="img" aria-label="in-progress">
              🍳&nbsp;
            </span>
            We are verifying your account...
          </Typography>
        </div>
      ) : null}
      {verifyStatus === VERIFICATION_STATUS.USED_CODE ? (
        <div className={styles.LoginPageBanner}>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={styles.BannerText}>
            <span role="img" aria-label="happy-face">
              🤗&nbsp;
            </span>
            Your account is already active. Let&apos;s start ordering!
          </Typography>
        </div>
      ) : null}

      <Helmet>
        <title>Sign In</title>
        <meta name="description" content="Sign in to your account. Don't have an account? Sign up." />
        <link rel="canonical" href="https://app.foodbomb.com.au/login/" />
      </Helmet>
      <div className={styles.LoginPageContainer}>
        <LoginForm {...props} />
      </div>
    </React.Fragment>
  );
};

export default Login;
