import { isAfter, isBefore } from 'date-fns';
import foodbombLogo from '../img/Navigation/foodbombLogo.png';
import foodbombChristmasLogo from '../img/Navigation/foodbombLogoChristmas.png';
import foodbombMardiGrasLogo from '../img/Navigation/foodbombLogoMardiGras.png';
import foodbombIcon from '../img/bombIcon.png';
import foodbombChristmasIcon from '../img/bombIconChristmas.png';
import foodbombMardiGrasIcon from '../img/bombIconMardiGras.png';

export const isChristmasTime = (): boolean => new Date().getMonth() + 1 === 12;

const START_OF_MARDI_GRAS = new Date('February 19, 2023');
const END_OF_MARDI_GRAS = new Date('March 5, 2023');

export const isMardisGrasTime = (): boolean =>
  isAfter(new Date(), START_OF_MARDI_GRAS) && isBefore(new Date(), END_OF_MARDI_GRAS);

export const getFoodbombLogo = (ignoreCustomLogos: boolean = false) => {
  if (ignoreCustomLogos) return foodbombLogo;
  if (isChristmasTime()) return foodbombChristmasLogo;
  if (isMardisGrasTime()) return foodbombMardiGrasLogo;

  return foodbombLogo;
};

export const getFoodbombBombIcon = () => {
  if (isChristmasTime()) return foodbombChristmasIcon;
  if (isMardisGrasTime()) return foodbombMardiGrasIcon;

  return foodbombIcon;
};

export const dynamicallyUpdateFavicon = () => {
  const oldFavicon = document.querySelectorAll("link[rel~='icon']");

  if (isChristmasTime()) {
    const newFavicon128 = document.createElement('link');
    newFavicon128.id = 'dynamic-favicon-128';
    newFavicon128.rel = 'icon';
    newFavicon128.href = '/faviconchristmas-128x128.png';

    const newFavicon32 = document.createElement('link');
    newFavicon32.id = 'dynamic-favicon-32';
    newFavicon32.rel = 'icon';
    newFavicon32.href = '/faviconchristmas-32x32.png';

    oldFavicon.forEach((icon) => {
      document.head.removeChild(icon);
    });

    document.head.appendChild(newFavicon32);
    document.head.appendChild(newFavicon128);
  }

  if (isMardisGrasTime()) {
    const newFavicon128 = document.createElement('link');
    newFavicon128.id = 'dynamic-favicon-128';
    newFavicon128.rel = 'icon';
    newFavicon128.href = '/faviconMardiGras-128x128.png';

    const newFavicon32 = document.createElement('link');
    newFavicon32.id = 'dynamic-favicon-32';
    newFavicon32.rel = 'icon';
    newFavicon128.href = '/faviconMardiGras-32x32.png';

    oldFavicon.forEach((icon) => {
      document.head.removeChild(icon);
    });

    document.head.appendChild(newFavicon32);
    document.head.appendChild(newFavicon128);
  }
};
