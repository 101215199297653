import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '../Checkbox/Checkbox';

import styles from './CheckboxButton.module.scss';

/**
 * # CheckboxButton
 * Simple Foodbomb CheckboxButton Component
 *
 * ## Modifiers
 * ### Variants
 * ```
 * small (bool)
 * ```
 *
 * ## PropTypes:
 * ```js
 *  FbCheckboxButton.propTypes = {
 *   checked: PropTypes.bool,
 *   onChange: PropTypes.func.isRequired,
 *   value: PropTypes.any,
 *   label: PropTypes.any,
 *   checkboxProps: PropTypes.object,
 *   small: PropTypes.bool,
 * };
 * ```
 */
const FBCheckboxButton = ({ label, checked, onChange, value, checkboxProps, small, ...props }) => {
  const rootStyles = [styles.CheckboxLabel__root];
  if (small) {
    rootStyles.push(styles.small);
  }
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} value={value} {...checkboxProps} />}
      label={label}
      classes={{ root: rootStyles.join(' '), label: styles.CheckboxLabel__label }}
      {...props}
    />
  );
};

FBCheckboxButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.any,
  checkboxProps: PropTypes.object,
  small: PropTypes.bool,
};

export default FBCheckboxButton;
