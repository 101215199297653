import * as Yup from 'yup';

export const validateAbnUsingLogic = (enteredAbn: string): boolean => {
  // Taken from https://abr.business.gov.au/Help/AbnFormat
  const abnPositionWeightingFactors: Array<number> = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  const abnToCheck = enteredAbn.replaceAll(' ', '');

  if (abnToCheck) {
    const abnWithOneRemovedFromMSF: string = (parseInt(abnToCheck[0], 10) - 1).toString().concat(abnToCheck.slice(1));
    const multipliedAndSummedAbn = abnWithOneRemovedFromMSF
      .split('')
      .reduce((previousValue: number, currentValue: string, currentIdx: number) => {
        const currentValueMultipliedByWeight = parseInt(currentValue, 10) * abnPositionWeightingFactors[currentIdx];
        return previousValue + currentValueMultipliedByWeight;
      }, 0);
    return multipliedAndSummedAbn % 89 === 0;
  }
  return false;
};

export const abnDetailsValidationSchema = Yup.object().shape({
  abn: Yup.string()
    .trim()
    .matches(/^[1-9]/, 'ABN must be a valid 11 digit number')
    .matches(/^(\s?\d){11}$/, 'ABN must be a valid 11 digit number')
    .required('You must provide an ABN')
    .test('Validate ABN number', 'The ABN entered must be valid', (abn) => validateAbnUsingLogic(abn || '')),
});
