import { FC } from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Link } from 'react-router-dom';
import { TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { getFoodbombLogo } from '../../utils/Logos';
import styles from './Footer.module.scss';
import { Typography } from '../UI/FB';
import useRedirect from '../../hooks/useRedirect/useRedirect';

const Footer: FC = () => {
  const {
    redirectToTermsPage,
    redirectToPrivacyPage,
    redirectToLogin,
    redirectToFoodbombInstagram,
    redirectToFoodbombFacebook,
    redirectToFoodbombLinkedIn,
    redirectToPublicAboutPage,
    redirectToPublicFeaturesPage,
    redirectToPublicResourcesPage,
    redirectToPublicCareersPage,
    redirectToPublicContactsPage,
    redirectToPublicNSWSuppliersPage,
    redirectToPublicVICSuppliersPage,
    redirectToPublicBecomeSupplierPage,
    redirectToPublicBrandsPage,
    redirectToPublicVenuesRestaurantsPage,
    redirectToPublicVenuesCafePage,
    redirectToPublicVenuesCateringPage,
    redirectToPublicVenuesPubPage,
    redirectToPublicVenuesClubPage,
    redirectToPublicVenuesSchoolsPage,
    redirectToPublicBookACallPage,
    redirectToPublicFoodbombFAQSPage,
  } = useRedirect();
  return (
    <div className={styles.FooterContainer}>
      <div className={styles.FooterContent}>
        <div className={styles.FooterLogo__wrapper}>
          <img src={getFoodbombLogo()} alt="Foodbomb" className={styles.FooterLogo} />
        </div>
        <div className={styles.FooterLinks__Section}>
          <div className={styles.FooterLinks__columnWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.FooterLinks__title}>
              Company
            </Typography>
            <Link to="#" onClick={redirectToPublicAboutPage} className={styles.FooterLink}>
              Who we are
            </Link>
            <Link to="#" onClick={redirectToPublicFeaturesPage} className={styles.FooterLink}>
              Features
            </Link>
            <Link to="#" onClick={redirectToPublicResourcesPage} className={styles.FooterLink}>
              Resources
            </Link>
            <Link to="#" onClick={redirectToPublicCareersPage} className={styles.FooterLink}>
              Careers
            </Link>
            <Link to="#" onClick={redirectToPublicContactsPage} className={styles.FooterLink}>
              Contact
            </Link>
          </div>
          <div className={styles.FooterLinks__columnWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.FooterLinks__title}>
              Suppliers
            </Typography>
            <Link to="#" onClick={redirectToPublicNSWSuppliersPage} className={styles.FooterLink}>
              NSW Suppliers
            </Link>
            <Link to="#" onClick={redirectToPublicVICSuppliersPage} className={styles.FooterLink}>
              VIC Suppliers
            </Link>
            <Link to="#" onClick={redirectToPublicBecomeSupplierPage} className={styles.FooterLink}>
              Become a Supplier
            </Link>
            <Link to="#" onClick={redirectToPublicBrandsPage} className={styles.FooterLink}>
              Popular Brands
            </Link>
          </div>
          <div className={styles.FooterLinks__columnWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.FooterLinks__title}>
              Foodbomb For
            </Typography>
            <Link to="#" onClick={redirectToPublicVenuesRestaurantsPage} className={styles.FooterLink}>
              Restaurants
            </Link>
            <Link to="#" onClick={redirectToPublicVenuesCafePage} className={styles.FooterLink}>
              Cafes
            </Link>
            <Link to="#" onClick={redirectToPublicVenuesCateringPage} className={styles.FooterLink}>
              Caterers
            </Link>
            <Link to="#" onClick={redirectToPublicVenuesPubPage} className={styles.FooterLink}>
              Pubs
            </Link>
            <Link to="#" onClick={redirectToPublicVenuesClubPage} className={styles.FooterLink}>
              Clubs
            </Link>
            <Link to="#" onClick={redirectToPublicVenuesSchoolsPage} className={styles.FooterLink}>
              Schools/Childcare
            </Link>
          </div>
          <div className={styles.FooterLinks__columnWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.FooterLinks__title}>
              Shortcuts
            </Typography>
            <Link to="#" onClick={redirectToPublicFoodbombFAQSPage} className={styles.FooterLink}>
              Support & FAQS
            </Link>
            <Link to="#" onClick={redirectToPublicBookACallPage} className={styles.FooterLink}>
              Book A call
            </Link>
            <Link to="#" onClick={redirectToLogin} className={styles.FooterLink}>
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.FooterLinks__Section}>
          <div className={styles.FooterLinks__socialLinks}>
            <Link to="#" onClick={redirectToPrivacyPage} className={styles.FooterLink}>
              Privacy Policy
            </Link>
            <Link to="#" onClick={redirectToTermsPage} className={styles.FooterLink}>
              Legal stuff
            </Link>
          </div>
          <div className={styles.FooterLinks__socialColumnWrapper}></div>
          <div className={styles.FooterLinks__socialColumn}>
            <Link to="#" onClick={redirectToFoodbombFacebook} className={styles.FooterLink__socialIcons}>
              <FacebookIcon className={styles.SocialIcon} />
            </Link>
            <Link to="#" onClick={redirectToFoodbombInstagram} className={styles.FooterLink__socialIcons}>
              <InstagramIcon className={styles.SocialIcon} />
            </Link>
            <Link to="#" onClick={redirectToFoodbombLinkedIn} className={styles.FooterLink__socialIcons}>
              <LinkedInIcon className={styles.SocialIcon} />
            </Link>
          </div>
        </div>
        <div className={styles.FooterBottom__section}>
          <div className={styles.FooterBottom__textWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.FooterBottom__text}>
              © COPYRIGHT {new Date().getFullYear()} Foodbomb. All rights reserved. Various trademarks held by their
              respective owners.
            </Typography>
          </div>
          <div className={styles.FooterBottom__textWrapper}>
            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.FooterBottom__text}>
              FOODBOMB PTY LTD, ABN 15 600 629 418. NSW Liquor Licence LIQW880015136. Liquor Act 2007: It is against the
              law to sell or supply alcohol to or to obtain alcohol on behalf of, a person under the age of 18 years.
            </Typography>
          </div>
          <div className={styles.FooterBottom__textWrapper}>
            {/* @ts-expect-error */}
            <Typography type={TYPOGRAPHY_TYPES.HEADING} className={styles.FooterBottom__textTitle}>
              Acknowledgment of country
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.FooterBottom__text}>
              Foodbomb acknowledges the traditional owners of the land on which we work and live. We pay our respects to
              Elders past, present and emerging and celebrate the food, stories and culture of all First Nations,
              Aboriginal and Torres Strait communities.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
