import Moment from 'moment';
import React, { Dispatch } from 'react';
import ReactGA from 'react-ga4';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { FoodbombAPI } from '../../AxiosInstances';
import {
  clearAnalyticsIdentity,
  identifyAccount,
  trackAnalyticsEvent,
} from '../../hooks/useEventTracking/useEventTracking';
import { NOTIFICATION_TYPES } from '../../utils/Constants';
import { getCookie } from '../../utils/CookieUtils';
import PresentAccount, { PresentedAccount } from '../../utils/Presenters/PresentAccount/PresentAccount';
import { onDesktopDevice } from '../../utils/ScreenUtils';
import { localStorageGetItem } from '../../utils/StorageUtils';
import {
  clearAuthorizationTokenFromCookies,
  clearAuthorizationTokenFromLocalStorage,
  getDetailsFromToken,
  getTokenExpirationDate,
  potentiallySetAxiosAuthorizationToken,
  removeAxiosAuthorizationToken,
  saveAuthorizationTokenToLocalStorage,
  TOKEN,
  tokenHasValidHeader,
} from '../../utils/Tokens/TokenHelper';
import { buildErrorMessages, logRaygunError } from '../ReduxUtilities';
import * as actionTypes from './ActionTypes';
import { notificationAdd } from './NotificationActions';
// eslint-disable-next-line
import { reinitialiseDefaultVenueState } from './VenueActions';

declare global {
  interface Window {
    analytics: any;
  }
}

const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authReset = () => ({
  type: actionTypes.AUTH_RESET,
});

const authFail = (error: any) => ({
  type: actionTypes.AUTH_FAIL,
  payload: {
    error: buildErrorMessages(error),
  },
});

const refreshAuthAccountSucces = (account: PresentedAccount) => ({
  type: actionTypes.AUTH_ACCOUNT_SUCCESS,
  payload: {
    account,
  },
});

export const refreshAuthAccount = () => async (dispatch: Dispatch<any>) => {
  try {
    const response = await FoodbombAPI.get('/account/');
    const accountData = response.data;
    const presentedAccount = PresentAccount(accountData);
    dispatch(refreshAuthAccountSucces(presentedAccount));
  } catch (error) {
    logRaygunError(error, 'Unable to refresh auth account [GET:/account]', 'Auth Actions - refreshAuthAccount');
    dispatch(
      notificationAdd({
        type: NOTIFICATION_TYPES.ERROR,
        content: (
          <React.Fragment>
            <div>Something went wrong!!</div>
            <div>Please try again or contact support.</div>
          </React.Fragment>
        ),
        timeout: 6000,
        closable: true,
      }),
    );
    dispatch(authFail(error));
  }
};

const authSuccess = (token: string, tokenExpirationDate: Date) => {
  // TODO: can we perform a network request here ? or do we have to do it above - how does redux thunk work?
  // Look into async redux actions

  const jwtData = getDetailsFromToken(token);

  // Mixpanel identifier with userId and username
  const analytics = window?.analytics;

  analytics.identify(jwtData.sub, {
    name: jwtData.username,
    isStaff: jwtData.isStaff,
    email: jwtData.email,
  });

  const ddUser = {
    id: jwtData.sub,
    isStaff: jwtData.isStaff,
    name: jwtData.username,
  };

  datadogRum.setUser(ddUser);
  datadogLogs.setUser(ddUser);

  identifyAccount(jwtData.sub, true);
  window.heap.addEventProperties({ isStaff: Boolean(jwtData?.isStaff) });
  trackAnalyticsEvent(`Auth - ${jwtData.isStaff ? 'Masquerade from Admin' : 'Login Form'} - Login`);

  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      token,
      tokenExpirationDate,
      isStaff: Boolean(jwtData.isStaff),
      tokenSub: jwtData.sub,
    },
  };
};
// eslint-disable-next-line
export const authAccountOverride = (account: PresentedAccount) => ({
  type: actionTypes.AUTH_OVERRIDE,
  payload: {
    account,
  },
});

const clearAllCookiesAndTokens = () => {
  clearAuthorizationTokenFromLocalStorage();
  // Admin override uses cookies
  clearAuthorizationTokenFromCookies();
};

const authLogout = () => {
  clearAllCookiesAndTokens();
  removeAxiosAuthorizationToken();
  clearAnalyticsIdentity();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const logout = () => (dispatch: Dispatch<any>) => {
  dispatch(authLogout());
  dispatch(reinitialiseDefaultVenueState());
};

const checkAuthTimeout = (expirationDate: Date) => (dispatch: Dispatch<any>) => {
  const now = new Date();
  const timeUntilLogout = expirationDate.getTime() - now.getTime();
  const timeUntilLogoutWarning = timeUntilLogout - 15 * 1000;

  // If we're too far in the future, don't bother with this
  const shouldUseNotifications = Math.abs(Moment(expirationDate).diff(Moment(), 'days')) < 10;

  if (shouldUseNotifications) {
    setTimeout(() => {
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.WARNING,
          content: (
            <React.Fragment>
              <div>To ensure your security, your session will expire in 15 seconds</div>
              <div>You will have to log in again.</div>
            </React.Fragment>
          ),
          timeout: 15000,
          closable: true,
        }),
      );
    }, timeUntilLogoutWarning);

    setTimeout(() => {
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.ERROR,
          content: (
            <React.Fragment>
              <div>Your session has expired</div>
              <div>Please log in again</div>
            </React.Fragment>
          ),
          timeout: 6000,
          closable: true,
        }),
      );
      dispatch(logout());
    }, timeUntilLogout);
  }
};

export const authLogin = (username: string, password: string) => (dispatch: Dispatch<any>) => {
  dispatch(authStart());
  FoodbombAPI.post('auth/login', {
    username,
    password,
    role: 'venue',
  })
    .then((response) => {
      // await the account here

      const token = response.data.access_token;
      const tokenExpirationDate: Date = getTokenExpirationDate(token);

      saveAuthorizationTokenToLocalStorage(token);
      potentiallySetAxiosAuthorizationToken(token);
      dispatch(checkAuthTimeout(tokenExpirationDate));
      dispatch(authSuccess(token, tokenExpirationDate));

      if (onDesktopDevice()) {
        dispatch(
          notificationAdd({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: (
              <React.Fragment>
                <div>Successfully logged in</div>
                <div>Your session will expire {Moment().to(tokenExpirationDate)}</div>
              </React.Fragment>
            ),
            timeout: 6000,
            closable: true,
          }),
        );
      }
    })
    .catch((error) => {
      ReactGA.event({
        category: 'sign_in_failed',
        action: 'unknown',
        label: "Couldn't log you in! Please check your login email and password and try again.",
      });
      dispatch(authFail(error));
    });
};

export const authCheckState = () => (dispatch: Dispatch<any>) => {
  // Cookies come from staff override
  const token = getCookie(TOKEN) || localStorageGetItem(TOKEN);
  if (!token || !tokenHasValidHeader(token)) {
    dispatch(authLogout());
    dispatch(reinitialiseDefaultVenueState());
  } else {
    const tokenExpirationDate = getTokenExpirationDate(token);
    // if the token has expired in their local storage, then log them out
    if (tokenExpirationDate > new Date()) {
      const tokenData = getDetailsFromToken(token);
      potentiallySetAxiosAuthorizationToken(token);
      // todo: get account here too
      dispatch(authSuccess(token, tokenExpirationDate));
      if (onDesktopDevice()) {
        dispatch(
          notificationAdd({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: (
              <React.Fragment>
                <div>Session restored</div>
                <div>Your session will expire {Moment().to(tokenExpirationDate)}</div>
              </React.Fragment>
            ),
            timeout: 6000,
            closable: true,
          }),
        );
      }
      dispatch(checkAuthTimeout(tokenExpirationDate));

      if (tokenData.isStaff) {
        clearAllCookiesAndTokens();
      }
    } else {
      // Note: Could use refresh token to get a new one
      dispatch(logout());
      dispatch(reinitialiseDefaultVenueState());
    }
  }
};
