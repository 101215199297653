import * as ErrorReportHelper from '../utils/ErrorReportHelper/ErrorReportHelper';

export const logRaygunError = (error, message, location, extraData) => {
  const tags = [];
  if (error.response && error.response.status) {
    tags.push(error.response.status);
  }

  ErrorReportHelper.sendError(
    error,
    message,
    {
      error: { status: error?.response?.status, data: error?.response?.data },
      location,
      ...extraData,
    },
    tags,
  );
};

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

// TODO: Define this error handler for axios requests in redux
export const buildErrorMessages = (error) => {
  let errorResponse = {};
  if (error?.response?.status && error?.response?.data) {
    errorResponse = {
      status: error.response.status,
      message: error.response.data?.message,
      error: error.response.data?.errors?.[0],
    };
  } else {
    errorResponse = {
      status: 500,
      message: 'Network Error',
    };
  }

  return errorResponse;
};

// TODO: Handle these errors for login
// LOGIN
// 404 -> user name an pw incorrect -> dont display error message
// 404 -> user doesnt exist -> dont display error message
// 500 -> missing param -> display error message that it comes with

// GET USER
// check for empty object and fail
// 200
