import React, { useState, useRef, useEffect, FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FoodbombAPI } from '../../AxiosInstances';
import useNotifications from '../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting';
import { authLogin } from '../../reduxStore/actions';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, UNVERIFIED_EMAIL_CODE } from '../../utils/Constants';
import { ReduxState } from '../../utils/Presenters/ReduxTypes';
import { FormikFormField, Button, Typography, ErrorNotification } from '../UI/FB';
import FullWidthLoadingMessage from '../UI/FullWidthLoadingMessage/FullWidthLoadingMessage';
import useRedirect from '../../hooks/useRedirect/useRedirect';
import styles from './LoginForm.module.scss';
import WithRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';

type FormValuesType = {
  email: string;
  password: string;
};
type PropsTypes = {
  location: any;
};

const LoginForm: FC<PropsTypes> = ({ location }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const emailInputElemRef = useRef<HTMLInputElement>(null);

  const hasVenue = useSelector((state: ReduxState) => state?.currentVenue?.venue);
  const loadingCurrentVenue = useSelector((state: ReduxState) => state?.currentVenue?.loading);
  const isAuthLoading = useSelector((state: ReduxState) => state?.auth.loading);
  const loginErrored = useSelector((state: ReduxState) => state?.auth.error);
  const hasToken = useSelector((state: ReduxState) => state?.auth?.token);

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const dispatch = useDispatch();
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const { redirectToSupplierPortal } = useRedirect();

  useEffect(() => {
    if (emailInputElemRef.current) {
      emailInputElemRef.current.focus();
    }
  });

  if (Boolean(hasToken) && Boolean(hasVenue)) {
    return <Redirect to={location?.state?.previousLink || '/home'} />;
  }

  const handleToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const attemptToLogin = (values: FormValuesType) => {
    dispatch(authLogin(values.email, values.password));
  };

  const sendEmailAgain = (accountId?: number) => {
    FoodbombAPI.post(`account/resend-verification-mail/${accountId}`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Verification email on its way!',
          closable: true,
          timeout: 6000,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to resend email', { error, location: 'Registration Page' });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'We were unable to resend the email. Please contact support.',
          closable: true,
          timeout: 6000,
        });
      });
  };

  if (loadingCurrentVenue) {
    return (
      <div className={styles.LoadingWrapper}>
        <FullWidthLoadingMessage loadingMessage="Restoring your session..." />
      </div>
    );
  }

  return (
    <div className={styles.LoginFormPaper}>
      <div className={styles.LoginFormContainer}>
        <div>
          <Typography className={styles.LoginForm__heading} type={TYPOGRAPHY_TYPES.HEADING_XL}>
            Sign in to Foodbomb
          </Typography>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginValidationSchema}
          onSubmit={attemptToLogin}
          id={'loginForm'}
        >
          {({ errors, touched }) => (
            <Form className={styles.LoginForm}>
              <div className={styles.LoginForm__formContent}>
                <div className={styles.LoginForm__inputs}>
                  <div className={styles.Login__inputContainer}>
                    <FormikFormField
                      // @ts-expect-error
                      id="loginEmailInput"
                      fieldName="email"
                      touched={touched}
                      errors={errors}
                      placeholder="Enter your email"
                      label="Email"
                      fieldProps={{
                        autoComplete: 'username',
                        innerRef: emailInputElemRef,
                        type: 'email',
                      }}
                    />
                  </div>
                  <div className={styles.Login__inputContainer} data-recording-sensitive>
                    <FormikFormField
                      // @ts-expect-error
                      id="loginPasswordInput"
                      fieldName="password"
                      touched={touched}
                      errors={errors}
                      placeholder="Enter your password"
                      label="Password"
                      fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'current-password' }}
                      endAdornment={
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          className={styles.PasswordField__endAdornment}
                          onClick={handleToggleShowPassword}
                          onMouseDown={(e: SyntheticEvent) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                    />
                  </div>
                </div>
                {/* @ts-expect-error */}
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  type="submit"
                  className={styles.SubmitBtn}
                  disabled={isAuthLoading || Boolean(errors && Object.keys(errors).length)}
                  fullWidth
                >
                  {isAuthLoading ? (
                    <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                  ) : (
                    <React.Fragment>Sign In</React.Fragment>
                  )}
                </Button>
                <div className={styles.LoginFormSecondaryLinks}>
                  <Link className={[styles.Link, styles.Orange].join(' ')} to="#" onClick={redirectToSupplierPortal}>
                    Sign in as a Supplier
                  </Link>
                  <Link className={[styles.Link, styles.Green].join(' ')} to="/reset-password">
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div className={styles.LoginForm__errors}>
                {loginErrored?.error?.code === UNVERIFIED_EMAIL_CODE ? (
                  <ErrorNotification
                    title="Your account has not been activated!"
                    // @ts-expect-error
                    ariaLabelForEmoji="thinking face"
                    className={styles.LoginForm__error}
                    hideContactInfo
                    body="Please check your email and verify your email address."
                    actions={
                      // @ts-expect-error
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        onClick={() => sendEmailAgain(loginErrored.error?.accountId)}
                      >
                        Send Email Again
                      </Button>
                    }
                  />
                ) : null}
                {loginErrored &&
                loginErrored.status !== 401 &&
                !(loginErrored.error?.code === UNVERIFIED_EMAIL_CODE) ? (
                  <ErrorNotification
                    title="We were unable to log you in!"
                    body="Please check your email and password and try again."
                    hideContactInfo
                    className={styles.LoginForm__error}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WithRedirectHelper(LoginForm);
