import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting.ts';

import { FormikFormField, Typography, Button, NotificationMessageSection } from '../UI/FB';
import { TYPOGRAPHY_TYPES, BUTTON_VARIANTS, NOTIFICATION_MESSAGE_TYPES } from '../../utils/Constants';

import { FoodbombAPI } from '../../AxiosInstances';
import styles from './ResetPassword.module.scss';

const ResetPassword = ({ redirectToLogin, hasToken, redirectToHome }) => {
  const { code } = useParams();
  const { sendDatadogError } = useDDErrorReporting();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  useEffect(() => {
    if (hasToken) {
      redirectToHome();
    }
  }, [hasToken, redirectToHome]);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const defaultErrorStatus = {
    success: false,
    type: NOTIFICATION_MESSAGE_TYPES.ERROR,
    title: 'Whoops, something went wrong!',
    message: (
      <React.Fragment>
        Please try again or contact us on&nbsp;
        <a className={styles.OrangeLink} href={`tel:1300309055`}>
          1300 309 055
        </a>
        &nbsp;or&nbsp;
        <a className={styles.OrangeLink} href={`mailto:support@foodbomb.com.au`}>
          support@foodbomb.com.au
        </a>
        &nbsp;for assistance
      </React.Fragment>
    ),
  };

  const errorBuilderForUI = (error) => {
    if (!error) return defaultErrorStatus;

    const updatePasswordErrorCodesToTitles = {
      password_reset_expired: 'The available time to reset your password has expired',
      password_reset_already_used: 'You have already attempted to reset your password using this email',
      password_reset_code_not_found: 'Whoops, something went wrong!',
    };

    return {
      success: false,
      type: NOTIFICATION_MESSAGE_TYPES.ERROR,
      title: updatePasswordErrorCodesToTitles[error.code],
      message: (
        <React.Fragment>
          Please attempt to reset your password again by&nbsp;
          <a className={styles.OrangeLink} href={`${window.location.host}/account/reset-password`}>
            clicking here
          </a>
        </React.Fragment>
      ),
    };
  };

  const requestPasswordResetEmail = (values, actions) => {
    FoodbombAPI.post('/account/reset-password', {
      email: values.email,
    })
      .then(() => {
        actions.setStatus({
          success: true,
          type: NOTIFICATION_MESSAGE_TYPES.SUCCESS,
          title: "We've sent you an email",
          message:
            'If this account exists with us, please check your inbox and follow the instructions included in the email to continue',
        });
      })
      .catch((error) => {
        sendDatadogError('Failed to request password reset email', { error, location: 'Reset Password' });
        actions.setStatus(defaultErrorStatus);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const updatePassword = (values, actions) => {
    FoodbombAPI.post('account/password/update?type=venue', {
      code,
      password: values.password,
    })
      .then(() => {
        actions.setStatus({
          success: true,
          type: NOTIFICATION_MESSAGE_TYPES.SUCCESS,
          title: 'Password successfully updated',
          message: (
            <React.Fragment>
              Please&nbsp;
              <button className={styles.FakeOrangeLinkBtn} onClick={redirectToLogin}>
                click here
              </button>
              &nbsp;to continue to log in with your new password
            </React.Fragment>
          ),
        });
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors?.[0];
        actions.setStatus(errorBuilderForUI(errors));
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const requestPasswordFormSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
  });

  const resetPasswordFormSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Must be at least 8 characters').required('Password is required!'),
    passwordConfirmation: Yup.string()
      .required('Password confirmation is required!')
      .test(
        'passwords-match',
        'Password confirmation does not match password',
        function testPasswordConfirmationMatches(value) {
          return this.parent.password === value;
        },
      ),
  });

  const resetPasswordForm = (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '' }}
      validationSchema={resetPasswordFormSchema}
      onSubmit={updatePassword}
    >
      {({ errors, touched, isSubmitting, status }) => (
        <Form className={styles.ResetPasswordForm}>
          <div className={styles.ResetPasswordForm__formContent}>
            <div className={styles.ResetPasswordForm__inputs}>
              <div className={styles.ResetPasswordForm__inputContainer}>
                <FormikFormField
                  fieldName="password"
                  touched={touched}
                  errors={errors}
                  placeholder="Create a password"
                  label="Password (8+ Characters)"
                  className={styles.Input__halfWidth}
                  fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'new-password' }}
                  endAdornment={
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      className={styles.PasswordField__endAdornment}
                      onClick={handleToggleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                />
                <FormikFormField
                  fieldName="passwordConfirmation"
                  touched={touched}
                  errors={errors}
                  placeholder="Confirm your password"
                  label="Password Confirmation"
                  className={styles.Input__halfWidth}
                  fieldProps={{
                    type: showPasswordConfirmation ? 'text' : 'password',
                    autoComplete: 'new-password-confirmation',
                  }}
                  endAdornment={
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      className={styles.PasswordField__endAdornment}
                      onClick={handleToggleShowPasswordConfirmation}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                />
              </div>
            </div>
          </div>
          {status && status.title ? (
            <NotificationMessageSection type={status.type} title={status.title}>
              {status.message}
            </NotificationMessageSection>
          ) : null}
          {!status || (status && !status.success) ? (
            <div className={styles.SubmitBtnContainer}>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                type="submit"
                className={styles.SubmitBtn}
                disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                ) : (
                  <React.Fragment>Update Password</React.Fragment>
                )}
              </Button>
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );

  const requestPasswordResetForm = (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={requestPasswordFormSchema}
      onSubmit={requestPasswordResetEmail}
    >
      {({ errors, touched, isSubmitting, status }) => (
        <Form className={styles.ResetPasswordForm}>
          <div className={styles.ResetPasswordForm__formContent}>
            <div className={styles.ResetPasswordForm__inputs}>
              <div className={styles.ResetPasswordForm__inputContainer}>
                <FormikFormField
                  fieldName="email"
                  touched={touched}
                  errors={errors}
                  placeholder="Enter your email"
                  label="Email"
                  fieldProps={{
                    autoComplete: 'email',
                  }}
                />
              </div>
            </div>
          </div>
          {status && status.title ? (
            <NotificationMessageSection type={status.type} title={status.title}>
              {status.message}
            </NotificationMessageSection>
          ) : null}
          {!status || (status && !status.success) ? (
            <div className={styles.SubmitBtnContainer}>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                type="submit"
                className={styles.SubmitBtn}
                disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                ) : (
                  <React.Fragment>Continue</React.Fragment>
                )}
              </Button>
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>Foodbomb - Reset Password</title>
        <meta name="description" content="Reset your Foodbomb password" />
        <link rel="canonical" href="https://app.foodbomb.com.au/account/reset-password/" />
      </Helmet>
      <div className={styles.ResetPasswordPageContainer}>
        <div>
          <div>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_S} className={styles.SubHeading}>
              <span role="img" aria-label="think">
                🤔
              </span>
              &nbsp;Having trouble remembering?
            </Typography>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL}></Typography>
            <Typography className={styles.ResetPassword__heading} type={TYPOGRAPHY_TYPES.HEADING_XL}>
              Reset your Foodbomb password
            </Typography>
          </div>
          {code ? resetPasswordForm : requestPasswordResetForm}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasToken: Boolean(state.auth.token),
});

ResetPassword.propTypes = {
  redirectToHome: PropTypes.func.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  hasToken: PropTypes.bool,
};

export default withRedirectHelper(connect(mapStateToProps, null)(ResetPassword));
