import { CircularProgress } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { DataMismatchError, PropertyRequiredError } from '../../../../exceptions';
import useEventTracking from '../../../../hooks/useEventTracking/useEventTracking.ts';
import useDDErrorReporting from '../../../../hooks/useDDErrorReporting/useDDErrorReporting.ts';
import { ADDRESS_ERROR_STATES, ANALYTICS_EVENT_CATEGORIES } from '../../../../utils/Constants';
import poweredByGoogleLogoSrc from '../powered_by_google_on_white.png';
import styles from './LocationSearchField.module.scss';

const LocationSearchField = ({
  handleAddressError,
  fillFieldOnSelect,
  handleManualAddressOveride,
  handleSelectAddress,
  clearErrors,
  onSuburbSelected,
  showManualAddressOverride,
  checkout,
}) => {
  const { sendDatadogError } = useDDErrorReporting();
  const { trackGAEvent } = useEventTracking();
  const [address, setAddress] = useState('');
  const [showAddressOverrideBtn, setShowAddressOverrideBtn] = useState(false);
  const [loadingPlaceDetails, setLoadingPlaceDetails] = useState(false);
  const sessionToken = useRef(new window.google.maps.places.AutocompleteSessionToken());

  const handleChange = (updatedAddress) => {
    clearErrors();
    if (!showAddressOverrideBtn) {
      setShowAddressOverrideBtn(true);
    }
    if (updatedAddress.includes('/') && updatedAddress.includes('-')) {
      handleAddressError(ADDRESS_ERROR_STATES.DIFFICULT_ADDRESS_FORMAT);
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.USER_BLOCKS,
        action: 'Difficult Address format detected',
        label: updatedAddress,
      });
    }
    setAddress(updatedAddress);
  };

  const searchOptions = {
    componentRestrictions: { country: 'au' },
    types: ['address'],
    sessionToken: sessionToken.current,
    strictBounds: true,
  };

  const ADDRESS_COMPONENTS = {
    UNIT_NUMBER: {
      googleKey: 'subpremise',
      name: 'unit number',
    },
    STREET_NUMBER: {
      googleKey: 'street_number',
      name: 'street number',
      required: true,
      error: ADDRESS_ERROR_STATES.MISSING_STREET_NUMBER,
    },
    STREET_NAME: {
      googleKey: 'route',
      name: 'street name',
      required: true,
      error: ADDRESS_ERROR_STATES.MISSING_STREET_NAME,
      reportToRaygun: true,
    },
    SUBURB: {
      googleKey: 'locality',
      name: 'postcode',
      required: true,
      error: ADDRESS_ERROR_STATES.MISSING_SUBURB,
      reportToRaygun: true,
    },
    POSTCODE: {
      googleKey: 'postal_code',
      name: 'postcode',
      required: true,
      error: ADDRESS_ERROR_STATES.MISSING_POSTCODE,
      reportToRaygun: true,
    },
  };

  const handleClear = () => {
    setAddress('');
    clearErrors();
  };

  const getValueForAddressComponentType = (addressComponents, keyToCheck) => {
    const componentName = addressComponents.find((ac) => ac.types.includes(keyToCheck.googleKey))?.short_name;

    if (!componentName && keyToCheck.required) {
      handleAddressError(keyToCheck.error);
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.USER_BLOCKS,
        action: `Autocomplete result missing ${keyToCheck.name} in location search`,
        label: addressComponents.map((ac) => ac.short_name).join(' '),
      });
      if (keyToCheck.reportToRaygun) {
        sendDatadogError('User selected google selection that was missing neccessary data', {
          error: new PropertyRequiredError(keyToCheck.name),
          addressComponents,
          location: 'Location Search Field',
        });
      }
    }
    return componentName;
  };

  const onSelectAddress = (selectedAddress, placeId) => {
    if (!placeId) {
      return;
    }
    setLoadingPlaceDetails(true);
    if (fillFieldOnSelect) {
      setAddress(selectedAddress);
    }
    const manuallyDetectedUnitNumber = selectedAddress.includes('/') ? address.split('/')[0] : null;
    const div = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(div);

    service.getDetails({ placeId, fields: ['address_components'] }, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = results.address_components;
        const unitNumber =
          getValueForAddressComponentType(addressComponents, ADDRESS_COMPONENTS.UNIT_NUMBER) ||
          manuallyDetectedUnitNumber;
        const streetNumber = getValueForAddressComponentType(addressComponents, ADDRESS_COMPONENTS.STREET_NUMBER);
        const streetName = getValueForAddressComponentType(addressComponents, ADDRESS_COMPONENTS.STREET_NAME);
        const suburb = getValueForAddressComponentType(addressComponents, ADDRESS_COMPONENTS.SUBURB);
        const postcode = getValueForAddressComponentType(addressComponents, ADDRESS_COMPONENTS.POSTCODE);
        const streetAddress = [streetNumber, streetName].join(' ');

        if (streetNumber && streetName && suburb && postcode) {
          const formattedAddress = {
            streetAddress: unitNumber ? `${unitNumber}/${streetAddress}` : streetAddress,
            suburb,
            postcode,
            displayAddress: selectedAddress,
          };
          handleSelectAddress(formattedAddress);
          setAddress(formattedAddress.displayAddress);
          if (onSuburbSelected) {
            onSuburbSelected(formattedAddress);
          }
        }
      } else {
        handleAddressError(ADDRESS_ERROR_STATES.FAILED_ADDRESS_LOOKUP);
        sendDatadogError('Unable to complete the get details request to google places service', {
          error: new DataMismatchError('Google places results', 'Status of OK', { results, status }),
          location: 'Location Search Field',
        });
      }
      setLoadingPlaceDetails(false);
    });
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={onSelectAddress}
      debounce={200}
      highlightFirstSuggestion
      shouldFetchSuggestions
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {showManualAddressOverride ? (
            <div className={styles.ShowManualAddressOveride__ButtonContainer}>
              <button type="button" className={styles.ButtonLink} onClick={handleManualAddressOveride}>
                Can&apos;t find your address?
              </button>
            </div>
          ) : null}
          <div className={styles.LocationSearch__inputContainer}>
            <input
              {...getInputProps({
                placeholder: checkout ? 'Start typing your address ...' : 'Search your address ...',
                className: styles.LocationSearch__inputField,
              })}
            />
            <CircularProgress
              size={24}
              className={
                loading || loadingPlaceDetails
                  ? [styles.LocationSearch__LoadingSpinner, styles.show].join(' ')
                  : styles.LocationSearch__LoadingSpinner
              }
            />
            <HighlightOff
              className={
                address.length
                  ? [styles.LocationSearch__ClearIcon, styles.show].join(' ')
                  : styles.LocationSearch__ClearIcon
              }
              onClick={handleClear}
            />
          </div>
          {suggestions && suggestions.length ? (
            <div
              className={
                checkout
                  ? [styles.LocationSearch__dropdown, styles.Checkout].join(' ')
                  : styles.LocationSearch__dropdown
              }
            >
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? [styles.LocationSearch__dropdownElement, styles.active].join(' ')
                  : styles.LocationSearch__dropdownElement;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.description.split(' ').join('_')}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
              <div className={styles.LocationSearch__redirectElements}>
                <div className={styles.PoweredByGoogleLogo__container}>
                  <img
                    src={poweredByGoogleLogoSrc}
                    alt="powered by google logo"
                    className={styles.PoweredByGoogleLogo}
                  ></img>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchField.propTypes = {
  fillFieldOnSelect: PropTypes.bool,
  handleManualAddressOveride: PropTypes.func,
  handleAddressError: PropTypes.func.isRequired,
  handleSelectAddress: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  onSuburbSelected: PropTypes.func,
  showManualAddressOverride: PropTypes.bool,
  checkout: PropTypes.bool,
};

export default LocationSearchField;
