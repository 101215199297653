import { FBUIConstants as FB_UI_CONSTANTS } from '../components/UI/FB';
import { STATES, PUBLIC_HOLIDAYS, CUSTOM_CUTOFFS_FOR_DATES, SUPPLIER_HOLIDAY_DATES } from './SupplierHolidays.ts';

// Category Icons
import meatIcon from '../img/icons/categories/meat.png';
import poultryIcon from '../img/icons/categories/poultry.png';
import fruitVegIcon from '../img/icons/categories/fruit_and_veg.png';
import seafoodIcon from '../img/icons/categories/seafood.png';
import dryGoodsIcon from '../img/icons/categories/dry_goods.png';
import smallGoodsIcon from '../img/icons/categories/smallgoods.png';
import dairyIcon from '../img/icons/categories/dairy.png';
import bakeryIcon from '../img/icons/categories/bakery.png';
import drinksIcon from '../img/icons/categories/drinks.png';
import alcoholIcon from '../img/icons/categories/alcohol.png';
import packagingIcon from '../img/icons/categories/packaging.png';
import cleaningProductsIcon from '../img/icons/categories/cleaning_products.png';
import readyMadeIcon from '../img/icons/categories/ready_made.png';
import plantBasedIcon from '../img/icons/categories/plant_based.png';
import internationalIcon from '../img/icons/categories/international.png';
import tablewareIcon from '../img/icons/categories/tableware.png';
import appliancesIcon from '../img/icons/categories/appliances.png';
import kitchenwareIcon from '../img/icons/categories/kitchenware.png';
import apparelIcon from '../img/icons/categories/apparel.png';

// Category Image
import meatImage from '../img/categories/meat_cat.jpg';
import poultryImage from '../img/categories/poultry_cat.jpg';
import fruitVegImage from '../img/categories/fruit & veg_cat.jpg';
import seafoodImage from '../img/categories/seafood_cat.jpg';
import dryGoodsImage from '../img/categories/dry goods_cat.jpg';
import smallGoodsImage from '../img/categories/smallgoods_cat.jpg';
import dairyImage from '../img/categories/dairy_cat.jpg';
import bakeryImage from '../img/categories/bakery_cat.jpg';
import drinksImage from '../img/categories/drinks, coffee & tea_cat.jpg';
import alcoholImage from '../img/categories/alcohol_cat.jpg';
import packagingImage from '../img/categories/packaging_cat.jpg';
import cleaningProductsImage from '../img/categories/cleaning products_cat.jpg';
import readyMadeImage from '../img/categories/readyMade.jpg';
import plantBasedImage from '../img/categories/plantbased_cat.png';
import internationalImage from '../img/categories/international_cat.png';
import tablewareImage from '../img/categories/tableware_cat.jpg';
import appliancesImage from '../img/categories/appliances_cat.jpg';
import kitchenwareImage from '../img/categories/kitchenware_cat.jpg';
import apparelImage from '../img/categories/apparel_cat_tile.jpg';

// Category Disabled Image
import meatDisabledImage from '../img/categories/meat_cat_disabled.png';
import poultryDisabledImage from '../img/categories/poultry_cat_disabled.png';
import fruitVegDisabledImage from '../img/categories/fruit & veg_cat_disabled.png';
import seafoodDisabledImage from '../img/categories/seafood_cat_disabled.png';
import dryGoodsDisabledImage from '../img/categories/dry goods_cat_disabled.png';
import smallGoodsDisabledImage from '../img/categories/smallgoods_cat_disabled.png';
import dairyDisabledImage from '../img/categories/dairy_cat_disabled.png';
import bakeryDisabledImage from '../img/categories/bakery_cat_disabled.png';
import drinksDisabledImage from '../img/categories/drinks, coffee & tea_cat_disabled.png';
import alcoholDisabledImage from '../img/categories/alcohol_cat_disabled.png';
import packagingDisabledImage from '../img/categories/packaging_cat_disabled.png';
import cleaningProductsDisabledImage from '../img/categories/cleaning products_cat_disabled.png';
import readyMadeDisabledImage from '../img/categories/readyMadeDisabled.jpg';
import plantBasedDisabledImage from '../img/categories/plantbased_cat_disabled.png';
import internationalDisabledImage from '../img/categories/international_cat_disabled.png';
import tablewareDisabledImage from '../img/categories/tableware_cat_disabled.jpg';
import appliancesDisabledImage from '../img/categories/appliances_cat_disabled.jpg';
import kitchenwareDisabledImage from '../img/categories/kitchenware_cat_disabled.jpg';
import apparelDisabledImage from '../img/categories/apparel_disabled.jpg';

// Category Hero Image
import meatHeroImage from '../img/categories/meat_hero.jpg';
import poultryHeroImage from '../img/categories/poultry_hero.jpg';
import fruitVegHeroImage from '../img/categories/fruit_veg_hero.jpg';
import seafoodHeroImage from '../img/categories/seafood_hero.jpg';
import dryGoodsHeroImage from '../img/categories/dry_goods_hero.jpg';
import smallGoodsHeroImage from '../img/categories/smallgoods_hero.jpg';
import dairyHeroImage from '../img/categories/dairy_hero.jpg';
import bakeryHeroImage from '../img/categories/bakery_hero.jpg';
import drinksHeroImage from '../img/categories/drinkscoffeetea_hero.jpg';
import alcoholHeroImage from '../img/categories/alcohol_hero.jpg';
import packagingHeroImage from '../img/categories/packaging_hero.jpg';
import cleaningProductsHeroImage from '../img/categories/cleaningproducts_hero.png';
import allProductsHeroImage from '../img/allProducts.jpg';
import readyMadeHeroImage from '../img/categories/readyMadeHero.jpg';
import plantBasedHeroImage from '../img/categories/plantbased_hero.png';
import internationalHeroImage from '../img/categories/international_hero.png';
import tablewareHeroImage from '../img/categories/tableware_hero.jpg';
import appliancesHeroImage from '../img/categories/appliances_hero.jpg';
import kitchenwareHeroImage from '../img/categories/kitchenware_hero.jpg';
import apparelHeroImage from '../img/categories/apparel_hero.jpg';

export const {
  STATUS_CHIP_TYPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_MESSAGE_SIZES,
  TYPOGRAPHY_TYPES,
  ALERT_TYPES,
  FAVOURITE_BUTTON_VARIANTS,
} = FB_UI_CONSTANTS;

export const FB_API_VERSION = 'fb-api-version';
export const MAINTENANCE_FLAG = 'MaintenanceModeEnabled';
export const MAINTENANCE_HEADER = 'fb-maintenance-mode-enabled';

export const ACCOUNTS_ROLE_IDS = {
  OWNER: 1,
  MANAGER: 2,
  OPERATOR: 3,
};

export const SEARCH_PAGE_VARIANTS = {
  SUPPLIER: 'suppliers',
  CATEGORY: 'categories',
  GLOBAL: 'global',
  FAVOURITES: 'favourites',
};

export const PRODUCT_SEARCH_QUERY_STRINGS = {
  SEARCH_QS: 'q',
  FAVOURITES_QS: 'favourites',
  BEST_BUYS_QS: 'best-buys',
  SUPPLIERS_QS: 'suppliers',
  CATEGORIES_QS: 'categories',
  GROUP_BY_QS: 'group-by',
  PRODUCT_SORT_QS: 'product-sort',
  SUPPLIER_SORT_QS: 'supplier-sort',
  // Note: this query string is injected in emails, to change it we will have to handle legacy query strings and update the emails
  LEAVE_SUPPLIER_REVIEW_QS: 'reviewSupplier',
  SUPPLIER_REVIEWS_QS: 'showSupplierDetails',
  PRODUCT_ISSUE_MODAL: 'showProductIssueModal',
  VIEW_TYPE_QS: 'viewType',
};

export const GROUP_BY_QUERY_STRINGS = {
  SUPPLIER: {
    QS: 'supplier',
    NAME: 'By Supplier',
  },
  PRODUCT: {
    QS: 'product',
    NAME: 'By Product',
  },
};

export const PRODUCT_SORT_BY_QUERY_STRINGS = {
  RELEVANCE: {
    QS: 'relevance',
    NAME: 'Most Relevant',
  },
  MOST_POPULAR: {
    QS: 'popular',
    NAME: 'Most Popular',
  },
  NAME_A_TO_Z: {
    QS: 'name-asc',
    NAME: 'Name A - Z',
  },
  NAME_Z_TO_A: {
    QS: 'name-desc',
    NAME: 'Name Z - A',
  },
  PRICE_LOW_TO_HIGH: {
    QS: 'price-asc',
    NAME: 'Lowest Price',
  },
  PRICE_HIGH_TO_LOW: {
    QS: 'price-desc',
    NAME: 'Highest Price',
  },
};

export const SUPPLIER_SORT_BY_QUERY_STRINGS = {
  RELEVANCE: {
    QS: 'relevance',
    NAME: 'Most Relevant',
  },
  MOST_POPULAR: {
    QS: 'popular',
    NAME: 'Most Popular',
  },
  NAME_A_TO_Z: {
    QS: 'name-asc',
    NAME: 'Name A - Z',
  },
  PRICE_LOW_TO_HIGH: {
    QS: 'price-asc',
    NAME: 'Lowest Price',
  },
  RATING: {
    QS: 'rating',
    NAME: 'Highest Rated',
  },
  RECOMMENDED: {
    QS: 'rating',
    NAME: 'Recommended',
  },
  MIN_ORDER: {
    QS: 'min-order',
    NAME: 'Minimum Order',
  },
  DELIVERY_DAYS: {
    QS: 'delivery-days',
    NAME: 'Delivery Days',
  },
  MOST_FREQUENT: {
    QS: 'frequent',
    NAME: 'Your Most Frequent',
  },
  NUMBER_OF_PRODUCTS: {
    QS: 'product-count',
    NAME: 'Number of Results',
  },
};

export const MAX_DAYS_CAN_ORDER_IN_ADVANCE = 12;
export const MAX_DAYS_CAN_PLACE_STANDING_ORDER_IN_ADVANCE = 60;

export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const REGISTRATION_TEXT_COLOUR = '#D0C3C3';

// This is a custom object created for standing orders which is not to be used with date-fns
export const DAYS_OF_WEEK = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};

// This is a custom object created for standing orders which is not to be used with date-fns
export const NUM_TO_DAY = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const STANDING_ORDER_STATUS = {
  ACTIVE: 'active',
  PAUSED: 'paused',
};

export const SUBTRACT_TIME_MINUTES_TO_AMEND_STANDING_ORDER = 30;

// NOTE: Favor enums. See ReduxTypes.ts
export const CART_NOTIFICATION_TARGET_GROUP = 'CART_NOTIFICATION';
export const UNAUTHORIZED_NOTIFICATION_TARGET_GROUP = 'UNAUTHORIZED_NOTIFICATION';
export const NETWORK_STATUS_NOTIFICATION_TARGET_GROUP = 'NETWORK_STATUS';
export const EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP = 'EXPIRED_SESSION';
export const ZIP_NOTIFICATION_TARGET_GROUP = 'ZIP_NOTIFICATION';
export const PRODUCT_UNAVAILABLE_TARGET_GROUP = 'PRODUCT_UNAVAILABLE_TARGET_GROUP';
export const CRITICAL_DATA_ERROR_TARGET_GROUP = 'CRITICAL_DATA_ERROR_TARGET_GROUP';
export const MAINTENANCE_MODE_ERROR_TARGET_GROUP = 'MAINTENANCE_MODE_ERROR_TARGET_GROUP';
export const CONNECTING_INTEGRATION_TARGET_GROUP = 'CONNECTING_INTEGRATION_TARGET_GROUP';
export const API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP = 'API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP';

export const SOCIAL_TYPES = {
  TWITTER: 'TWITTER',
  LINKEDIN: 'LINKEDIN',
  WEBSITE: 'WEBSITE',
};

export const PROMOTED_SUPPLIERS = {
  B_AND_E_FOODS: 441,
  FRUITIQUE: 206,
  POMPEI: 434,
  ADAMOS_PASTA: 476,
  ANTICO_WHOLESALE: 309,
  DICK_STONE_MEATS: 72,
  FISHBOY_SEAFOOD: 174,
  APPENNINE_GOURMET_FOODS: 468,
  CHEFS_PANTRY: 459,
  G_AND_T_CHICKEN: 431,
  OROSO_POULTRY: 453,
  SEALANE_FOOD_SERVICE: 447,
  SHORTYS_LIQUOR: 498,
  KEMENYS: 341,
  COMBINED_WINES_AND_FOOD: 369,
  ATLANTIC_WINES: 355,
  THE_WINE_PROVIDORE: 367,
  BIVIANO_DIRECT: 471,
  CHEFS_CHOICE_MEATS: 427,
  OCEAN_MADE_SEAFOOD: 501,
  PAC_TRADING_VIC: 474,
  THE_MILK_GUYS: 482,
  POSH_FOODS: 504,
  FARMER_JOES_CHICKEN: 119,
  DAIRY_SOLUTIONS: 109,
  SIMPLY_FRESH: 448,
  COLES_LIQUOR: 524,
  RELIABLE_FOOD_DISTRIBUTORS: 445,
  SALUMI: 497,
  DANUBE: 155,
  CAFIA: 228,
  ALPINE_SMALLGOODS: 527,
  IBERIANS: 461,
  M_AND_J_CHICKENS_VIC: 533,
  MASTER_POULTRY_GROUP: 521,
  NISBETS: 528,
  INFINITY_BAKERY: 542,
  BIG_CRUNCH: 543,
  MAMA_KAZ: 534,
};

export const PAYMENT_METHODS = {
  WADDLE: 'waddle',
  CREDIT_CARD: 'credit_card',
  ZIP: 'zip',
  TREVIPAY: 'trevipay',
  FINSTRO: 'finstro',
};

export const PAYMENT_CODES = {
  STRIPE: 'stripe',
  FREE_CHECKOUT: 'free_checkout',
  ZIP: 'zip',
  TREVIPAY: 'trevipay',
};

export const PAYMENT_FEE_TEXT = {
  waddle: 'Service Fee',
  credit_card: 'Payment Fee',
  zip: 'Zip Fee',
  trevipay: 'BombPay Fee',
};

export const PAYMENT_DISPLAY_TEXT = {
  WADDLE: 'Trading Account',
  TREVIPAY: 'BombPay',
  FINSTRO: 'FoodbombTrade',
};

export const PAYMENT_METHOD_QUERY_STRINGS = {
  ZIP: 'zip',
  BOMBPAY: 'bombpay',
};

// TreviPay statuses
export const INACTIVE_TREVIPAY_STATUSES = ['Inactive', 'Declined', 'Cancelled', 'Withdrawn'];
export const ACTIVE_TREVIPAY_STATUSES = ['Active'];
export const ACTION_REQUIRED_TREVIPAY_STATUSES = ['Suspended', 'Pending Setup'];
export const PENDING_APPROVAL_TREVIPAY_STATUSES = ['Pending', 'Pending Direct Debit', 'Pending Recourse'];

// Finstro Statuses
export const INACTIVE_FINSTRO_STATUSES = ['DELETE', 'ARREAR', 'WRITEOFF', 'RECOVERY'];
export const ACTIVE_FINSTRO_STATUSES = ['ACTIVE'];
export const PAYMENT_REQUIRED_FINSTRO_STATUSES = ['SUSPENDED'];
export const PENDING_APPROVAL_FINSTRO_STATUSES = ['PENDING'];

export const MEAT_CATEGORY_ID = 2995;
export const POULTY_CATEGORY_ID = 3022;
export const FRUIT_AND_VEG_CATEGORY_ID = 2909;
export const SEAFOOD_CATEGORY_ID = 3070;
export const DRY_GOODS_CATEGORY_ID = 3028;
export const SMALL_GOODS_CATEGORY_ID = 3133;
export const DAIRY_CATEGORY_ID = 3058;
export const BAKERY_CATEGORY_ID = 3049;
export const DRINKS_COFFEE_AND_TEA_CATEGORY_ID = 10473;
export const ALCOHOL_CATEGORY_ID = 10477;
export const PACKAGING_CATEGORY_ID = 10426;
export const CLEANING_PRODUCTS_CATEGORY_ID = 10424;
export const READY_MADE_CATEGORY_ID = 12059;
export const PLANT_BASED_CATEGORY_ID = 13299;
export const INTERNATIONAL_CATEGORY_ID = 13300;
export const TABLEWARE_CATEGORY_ID = 14321;
export const APPLIANCES_CATEGORY_ID = 14322;
export const KITCHENWARE_CATEGORY_ID = 14323;
export const APPAREL_CATEGORY_ID = 14536;

export const CATEGORY_ICONS = {
  [MEAT_CATEGORY_ID]: meatIcon,
  [POULTY_CATEGORY_ID]: poultryIcon,
  [FRUIT_AND_VEG_CATEGORY_ID]: fruitVegIcon,
  [SEAFOOD_CATEGORY_ID]: seafoodIcon,
  [DRY_GOODS_CATEGORY_ID]: dryGoodsIcon,
  [SMALL_GOODS_CATEGORY_ID]: smallGoodsIcon,
  [DAIRY_CATEGORY_ID]: dairyIcon,
  [BAKERY_CATEGORY_ID]: bakeryIcon,
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: drinksIcon,
  [ALCOHOL_CATEGORY_ID]: alcoholIcon,
  [PACKAGING_CATEGORY_ID]: packagingIcon,
  [CLEANING_PRODUCTS_CATEGORY_ID]: cleaningProductsIcon,
  [READY_MADE_CATEGORY_ID]: readyMadeIcon,
  [PLANT_BASED_CATEGORY_ID]: plantBasedIcon,
  [INTERNATIONAL_CATEGORY_ID]: internationalIcon,
  [TABLEWARE_CATEGORY_ID]: tablewareIcon,
  [APPLIANCES_CATEGORY_ID]: appliancesIcon,
  [KITCHENWARE_CATEGORY_ID]: kitchenwareIcon,
  [APPAREL_CATEGORY_ID]: apparelIcon,
};

export const CATEGORY_IMAGES = {
  [MEAT_CATEGORY_ID]: meatImage,
  [POULTY_CATEGORY_ID]: poultryImage,
  [FRUIT_AND_VEG_CATEGORY_ID]: fruitVegImage,
  [SEAFOOD_CATEGORY_ID]: seafoodImage,
  [DRY_GOODS_CATEGORY_ID]: dryGoodsImage,
  [SMALL_GOODS_CATEGORY_ID]: smallGoodsImage,
  [DAIRY_CATEGORY_ID]: dairyImage,
  [BAKERY_CATEGORY_ID]: bakeryImage,
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: drinksImage,
  [ALCOHOL_CATEGORY_ID]: alcoholImage,
  [PACKAGING_CATEGORY_ID]: packagingImage,
  [CLEANING_PRODUCTS_CATEGORY_ID]: cleaningProductsImage,
  [READY_MADE_CATEGORY_ID]: readyMadeImage,
  [PLANT_BASED_CATEGORY_ID]: plantBasedImage,
  [INTERNATIONAL_CATEGORY_ID]: internationalImage,
  [TABLEWARE_CATEGORY_ID]: tablewareImage,
  [APPLIANCES_CATEGORY_ID]: appliancesImage,
  [KITCHENWARE_CATEGORY_ID]: kitchenwareImage,
  [APPAREL_CATEGORY_ID]: apparelImage,
};

export const CATEGORY_DISABLED_IMAGES = {
  [MEAT_CATEGORY_ID]: meatDisabledImage,
  [POULTY_CATEGORY_ID]: poultryDisabledImage,
  [FRUIT_AND_VEG_CATEGORY_ID]: fruitVegDisabledImage,
  [SEAFOOD_CATEGORY_ID]: seafoodDisabledImage,
  [DRY_GOODS_CATEGORY_ID]: dryGoodsDisabledImage,
  [SMALL_GOODS_CATEGORY_ID]: smallGoodsDisabledImage,
  [DAIRY_CATEGORY_ID]: dairyDisabledImage,
  [BAKERY_CATEGORY_ID]: bakeryDisabledImage,
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: drinksDisabledImage,
  [ALCOHOL_CATEGORY_ID]: alcoholDisabledImage,
  [PACKAGING_CATEGORY_ID]: packagingDisabledImage,
  [CLEANING_PRODUCTS_CATEGORY_ID]: cleaningProductsDisabledImage,
  [READY_MADE_CATEGORY_ID]: readyMadeDisabledImage,
  [PLANT_BASED_CATEGORY_ID]: plantBasedDisabledImage,
  [INTERNATIONAL_CATEGORY_ID]: internationalDisabledImage,
  [TABLEWARE_CATEGORY_ID]: tablewareDisabledImage,
  [APPLIANCES_CATEGORY_ID]: appliancesDisabledImage,
  [KITCHENWARE_CATEGORY_ID]: kitchenwareDisabledImage,
  [APPAREL_CATEGORY_ID]: apparelDisabledImage,
};

export const CATEGORY_BANNER_IMAGES = {
  [MEAT_CATEGORY_ID]: meatHeroImage,
  [POULTY_CATEGORY_ID]: poultryHeroImage,
  [FRUIT_AND_VEG_CATEGORY_ID]: fruitVegHeroImage,
  [SEAFOOD_CATEGORY_ID]: seafoodHeroImage,
  [DRY_GOODS_CATEGORY_ID]: dryGoodsHeroImage,
  [SMALL_GOODS_CATEGORY_ID]: smallGoodsHeroImage,
  [DAIRY_CATEGORY_ID]: dairyHeroImage,
  [BAKERY_CATEGORY_ID]: bakeryHeroImage,
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: drinksHeroImage,
  [ALCOHOL_CATEGORY_ID]: alcoholHeroImage,
  [PACKAGING_CATEGORY_ID]: packagingHeroImage,
  [CLEANING_PRODUCTS_CATEGORY_ID]: cleaningProductsHeroImage,
  [READY_MADE_CATEGORY_ID]: readyMadeHeroImage,
  [PLANT_BASED_CATEGORY_ID]: plantBasedHeroImage,
  [INTERNATIONAL_CATEGORY_ID]: internationalHeroImage,
  [TABLEWARE_CATEGORY_ID]: tablewareHeroImage,
  [APPLIANCES_CATEGORY_ID]: appliancesHeroImage,
  [KITCHENWARE_CATEGORY_ID]: kitchenwareHeroImage,
  [APPAREL_CATEGORY_ID]: apparelHeroImage,
  0: allProductsHeroImage,
};

export const CATEGORY_EMOJIS = {
  [MEAT_CATEGORY_ID]: '🥩',
  [POULTY_CATEGORY_ID]: '🐔',
  [FRUIT_AND_VEG_CATEGORY_ID]: '🍏',
  [SEAFOOD_CATEGORY_ID]: '🍤',
  [DRY_GOODS_CATEGORY_ID]: '🍯',
  [SMALL_GOODS_CATEGORY_ID]: '🍖',
  [DAIRY_CATEGORY_ID]: '🧀',
  [BAKERY_CATEGORY_ID]: '🥖',
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: '☕️',
  [ALCOHOL_CATEGORY_ID]: '🍷',
  [PACKAGING_CATEGORY_ID]: '📦',
  [CLEANING_PRODUCTS_CATEGORY_ID]: '✨',
  [READY_MADE_CATEGORY_ID]: '🍱',
  [PLANT_BASED_CATEGORY_ID]: '🌱',
  [INTERNATIONAL_CATEGORY_ID]: '🌍',
  [TABLEWARE_CATEGORY_ID]: '🍽️',
  [APPLIANCES_CATEGORY_ID]: '🫖',
  [KITCHENWARE_CATEGORY_ID]: '🔪',
  [APPAREL_CATEGORY_ID]: '👕',
  0: '',
};

// NOTE: DEPRICATED. Use NotificationTypes (ReduxTypes.ts) for Typescript files
export const NOTIFICATION_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const PRODUCT_SEARCH_VIEW_TYPES = {
  SUPPLIER: 'SUPPLIER',
  CATEGORY: 'CATEGORY',
};

export const PRODUCT_SEARCH_KEYS = [
  { name: 'searchName', weight: 0.7 },
  { name: 'trimmedSearchName', weight: 0.8 },
  { name: 'category2', weight: 0.9 },
  { name: 'category3', weight: 0.9 },
  { name: 'supplier', weight: 0.4 },
];

export const SUPPLIER_SEARCH_KEYS = [{ name: 'name', weight: 1 }];

export const CATEGORY_SEARCH_KEYS = [{ name: 'name', weight: 1 }];

export const PARENT_CATEGORY_IDS = [
  MEAT_CATEGORY_ID,
  POULTY_CATEGORY_ID,
  FRUIT_AND_VEG_CATEGORY_ID,
  SEAFOOD_CATEGORY_ID,
  DRY_GOODS_CATEGORY_ID,
  SMALL_GOODS_CATEGORY_ID,
  DAIRY_CATEGORY_ID,
  BAKERY_CATEGORY_ID,
  DRINKS_COFFEE_AND_TEA_CATEGORY_ID,
  ALCOHOL_CATEGORY_ID,
  PACKAGING_CATEGORY_ID,
  CLEANING_PRODUCTS_CATEGORY_ID,
  READY_MADE_CATEGORY_ID,
  PLANT_BASED_CATEGORY_ID,
  INTERNATIONAL_CATEGORY_ID,
  TABLEWARE_CATEGORY_ID,
  APPLIANCES_CATEGORY_ID,
  KITCHENWARE_CATEGORY_ID,
  APPAREL_CATEGORY_ID,
];

export const PARENT_CATEGORY_NAMES = {
  [MEAT_CATEGORY_ID]: 'Meat',
  [POULTY_CATEGORY_ID]: 'Poultry',
  [FRUIT_AND_VEG_CATEGORY_ID]: 'Fruit & Veg',
  [SEAFOOD_CATEGORY_ID]: 'Seafood',
  [DRY_GOODS_CATEGORY_ID]: 'Dry Goods',
  [SMALL_GOODS_CATEGORY_ID]: 'Small Goods',
  [DAIRY_CATEGORY_ID]: 'Dairy',
  [BAKERY_CATEGORY_ID]: 'Bakery',
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: 'Drinks, Coffee & Tea',
  [ALCOHOL_CATEGORY_ID]: 'Alcohol',
  [PACKAGING_CATEGORY_ID]: 'Packaging',
  [CLEANING_PRODUCTS_CATEGORY_ID]: 'Cleaning Products',
  [READY_MADE_CATEGORY_ID]: 'Ready Made',
  [PLANT_BASED_CATEGORY_ID]: 'Plant Based',
  [INTERNATIONAL_CATEGORY_ID]: 'International',
  [TABLEWARE_CATEGORY_ID]: 'Tableware',
  [APPLIANCES_CATEGORY_ID]: 'Appliances',
  [KITCHENWARE_CATEGORY_ID]: 'Kitchenware',
  [APPAREL_CATEGORY_ID]: 'Apparel',
};

export const PARENT_CATEGORY_SLUGS = {
  [MEAT_CATEGORY_ID]: 'meat',
  [POULTY_CATEGORY_ID]: 'poultry',
  [FRUIT_AND_VEG_CATEGORY_ID]: 'fruit-and-veg',
  [SEAFOOD_CATEGORY_ID]: 'seafood',
  [DRY_GOODS_CATEGORY_ID]: 'dry-goods',
  [SMALL_GOODS_CATEGORY_ID]: 'small-goods',
  [DAIRY_CATEGORY_ID]: 'dairy',
  [BAKERY_CATEGORY_ID]: 'bakery',
  [DRINKS_COFFEE_AND_TEA_CATEGORY_ID]: 'drinks-coffee-and-tea',
  [ALCOHOL_CATEGORY_ID]: 'alcohol',
  [PACKAGING_CATEGORY_ID]: 'packaging',
  [CLEANING_PRODUCTS_CATEGORY_ID]: 'cleaning-products',
  [READY_MADE_CATEGORY_ID]: 'ready-made',
  [PLANT_BASED_CATEGORY_ID]: 'plant-based',
  [INTERNATIONAL_CATEGORY_ID]: 'international',
  [TABLEWARE_CATEGORY_ID]: 'tableware',
  [APPLIANCES_CATEGORY_ID]: 'appliances',
  [KITCHENWARE_CATEGORY_ID]: 'kitchenware',
  [APPAREL_CATEGORY_ID]: 'apparel',
};

export const SUPPLIER_CHECKOUT_MESSAGES = {
  // EXAMPLE:
  // 413: {
  // supplierName: 'Food & Dairy Co',
  // message:
  // 'Food & Dairy Co delivers between 3am and 7am. Please provide instructions on how to obtain a key if required.',
  // },
};

export const PERSONAL_USE = 'Personal use';
export const TBC = 'TBC';

export const CUSTOMER_TYPES = {
  CONSUMER: 'consumer',
  VENUE: 'venue',
};

export const VIEW_TYPES = {
  TILE: 'tile',
  LIST: 'list',
};

export { STATES, PUBLIC_HOLIDAYS, CUSTOM_CUTOFFS_FOR_DATES, SUPPLIER_HOLIDAY_DATES };

export const ANALYTICS_EVENT_CATEGORIES = {
  ACQUISITION_FUNNEL: 'Acquisition Funnel',
  USER_BLOCKS: 'User Blocks',
  SEARCH: 'Search',
  SEARCH_EXPANSION_ACTIONS: 'Search Expansion Actions',
  SORT: 'Sort Results',
  FILTERS: 'Filters',
  CHECKOUT: 'Checkout',
  HOME: 'Home',
  NAVIGATION: 'Navigation',
};

export const ANALYTICS_DIMENSION_VALUES = {
  LOGGED_IN: 'logged-in',
  LOGGED_OUT: 'logged-out',
};

export const WRITE_A_GOOGLE_REVIEW_LINK =
  'https://www.google.com/search?sxsrf=ALeKk01hvN0qstY4iAR1uyYT7omZqLxlDg%3A1585788598344&ei=tjaFXsbdFLLXz7sPltqV0Ao&q=foodbomb&oq=foodbomb&gs_lcp=CgZwc3ktYWIQAzIECCMQJzIECCMQJzIECCMQJzIHCAAQFBCHAjICCAAyAggAMgIIADIFCAAQzQI6BAgAEEc6BAgAEEM6BwgAEIMBEEM6BQgAEJECOgUIABCDAToGCAAQChBDOgQIABAKUKyNlAJY95WUAmD1mZQCaABwAXgAgAGEAogB3A2SAQUwLjEuN5gBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwjGkN_wwsjoAhWy63MBHRZtBaoQ4dUDCAs&uact=5#lrd=0x6b12ae21aacbd7e1:0x3ffeb5caecf8da2a,3';

export const HELP_CENTRE_LINKS = {
  HOME: `${process.env.REACT_APP_HELP_CENTRE_LINKS_HOME}`,
  FAQ_GENERAL: `${process.env.REACT_APP_HELP_CENTRE_LINKS_FAQ_GENERAL}`,
  SUPPLIERS: `${process.env.REACT_APP_HELP_CENTRE_LINKS_PAYMENTS}`,
  PAYMENTS: `${process.env.REACT_APP_HELP_CENTRE_LINKS_SUPPLIERS}`,
};

export const BOOK_A_MEETING_FOR_COLLECTIVE_LINK = 'https://meetings.hubspot.com/elinor5';
export const BOOK_A_MEETING_WITH_SALES = 'https://meetings.hubspot.com/ted139';

export const SEE_GOOGLE_REVIEWS_LINK =
  'https://www.google.com/search?sxsrf=ALeKk01hvN0qstY4iAR1uyYT7omZqLxlDg%3A1585788598344&ei=tjaFXsbdFLLXz7sPltqV0Ao&q=foodbomb&oq=foodbomb&gs_lcp=CgZwc3ktYWIQAzIECCMQJzIECCMQJzIECCMQJzIHCAAQFBCHAjICCAAyAggAMgIIADIFCAAQzQI6BAgAEEc6BAgAEEM6BwgAEIMBEEM6BQgAEJECOgUIABCDAToGCAAQChBDOgQIABAKUKyNlAJY95WUAmD1mZQCaABwAXgAgAGEAogB3A2SAQUwLjEuN5gBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwjGkN_wwsjoAhWy63MBHRZtBaoQ4dUDCAs&uact=5#lrd=0x6b12ae21aacbd7e1:0x3ffeb5caecf8da2a,1';

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  PROTOTYPE: 'prototype',
  DEVELOPMENT: 'development',
};

export const LOGO_URL_PREFIX =
  process.env.REACT_APP_FB_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? 'images' : 'dev-images';

// Leaving this cursor beta as an example
export const CURSOR_BETA = 'CURSOR_BETA';
export const MAINTENANCE_BETA = 'MAINTENANCE_BETA';
export const PRODUCT_IDS_BETA = 'PRODUCT_IDS_BETA';

export const ACTIVE_BETA_FEATURES = {
  [CURSOR_BETA]: {
    title: 'Cursor Mode',
    options: {
      DEFAULT: {
        label: 'Regular',
        id: undefined,
      },
      DISPLAY: {
        label: 'BombSquad Mode',
        id: 1,
      },
    },
  },
  [MAINTENANCE_BETA]: {
    title: 'Foodbomb API Status',
    options: {
      DEFAULT: {
        label: 'Online',
        id: undefined,
      },
      ON: {
        label: 'Maintenance Mode',
        id: 1,
      },
    },
  },
  [PRODUCT_IDS_BETA]: {
    title: 'Display product IDs',
    options: {
      DEFAULT: {
        label: 'No product IDs',
        id: undefined,
      },
      ON: {
        label: 'Show product IDs',
        id: 1,
      },
    },
  },
};

export const DEFAULT_PRE_AUTH = 0.15;
export const DEFAULT_CC_FEE = 0.015;

export const ORDER_TRANSACTION_ACTIONS = {
  PREAUTH: 'PREAUTH',
  CHARGE: 'CHARGE',
  TOPUP: 'TOPUP',
  REFUND: 'REFUND',
  WAIVE: 'WAIVE',
};

export const JAY_PRODUCTION_ID = 4767;
export const SCOTTS_DEV_ACCOUNT_ID = 5954;

// VENUE ACCOUNTS TO HARDCODE AS INCLUDED IN BETA
export const ALMA_VENUE = 4021;
export const VANILLA_BLUE_VENUE = 687;
export const THE_SHED_AKUNA_BAY = 37510;
export const UNAS = 713;
export const PUBLIC_ESPRESSEO = 5326;
export const CROSS_EATERY = 580;
export const BULGOGI_HUNTERS = 38094;
export const HAVANA_CAFE = 38231;
export const FORLI = 37662;

export const EMPLOYEE_PRANK_LIST = [JAY_PRODUCTION_ID];

export const WEBFLOW_URLS = {
  DEFAULT_SIGN_UP_IFRAME: `${process.env.REACT_APP_WEBFLOW_URL}/collateral/create-a-business-account`,
  VENUE_SIGN_UP_IFRAME: `${process.env.REACT_APP_WEBFLOW_URL}/collateral/create-a-business-account`,
  CONSUMER_SIGN_UP_IFRAME: `${process.env.REACT_APP_WEBFLOW_URL}/collateral/create-a-household-account`,
  LOGIN_IFRAME: `${process.env.REACT_APP_WEBFLOW_URL}/collateral/signin`,
};

export const RATING_ICON_BUBBLE_SIZE = {
  SMALL: 'small',
};

export const REVIEW_SUB_RATING_TYPES = {
  QUALITY: {
    apiField: 'quality',
    name: 'Quality',
  },
  PRICE: {
    apiField: 'price',
    name: 'Range',
  },
  DELIVERY: {
    apiField: 'delivery',
    name: 'Delivery',
  },
};

export const INTEGRATION_QUERY_KEY = 'integration';

export const INTEGRATIONS = {
  amaka: ['client_id', 'response_type', 'redirect_uri', 'code_challenge', 'code_challenge_method', 'state'],
};

export const ADDRESS_ERROR_STATES = {
  DEFAULT_ERROR: 'default error',
  INVALID_ADDRESS: 'invalid address',
  INVALID_SUBURB: 'invalid suburb',
  FAILED_ADDRESS_LOOKUP: 'failed address lookup',
  FAILED_SUBURB_LOOKUP: 'failed suburb lookup',
  DIFFICULT_ADDRESS_FORMAT: 'difficult address format',
  NO_SUPPLIERS_FOR_SUBURB: 'no suppliers for suburb',
  MISSING_STREET_NUMBER: 'missing street number',
  MISSING_STREET_NAME: 'missing street name',
  MISSING_SUBURB: 'missing suburb',
  MISSING_POSTCODE: 'missing postcode',
};

export const FOODLINK_ID = 492;

export const AMAKA_XERO_INTEGRATION_ENDPOINT = `${process.env.REACT_APP_AMAKA_XERO_INTEGRATION_ENDPOINT}`;
export const AMAKA_MYOB_INTEGRATION_ENDPOINT = `${process.env.REACT_APP_AMAKA_MYOB_INTEGRATION_ENDPOINT}`;

export const DASHBOARD_CARD_WIDTH = 296;

export const USE_HARDCODED_CHRISTMAS_HOLIDAY_MESSAGE = new Date().getMonth() + 1 === 12;

export const B2C_TOP_SUPPLIER_IDS = [440, 259, 174, 120, 72, 428, 471, 453, 441, 434, 501, 447, 427];

export const SORT_BY_METHODS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const MAX_MINUTES_FOR_AMENDMENT = 55;

// Buffer between suppliers cut off time and the order amendment time in minutes
export const CUT_OFF_TIME_TO_AMENDMENT_BUFFER = 6;

export const CURRENTLY_SELECTED_VENUE_ID_KEY = 'currentlySelectedVenueId';

export const SUCCESS_TIMEOUT = 3000;

export const REPORTS_ENDPOINTS = {
  SPEND_BY_CATEGORY_TIME_SERIES: 'spend-by-category-time-series',
  SPEND_BY_SUPPLIER_TIME_SERIES: 'spend-by-supplier-time-series',
  SPEND_BY_CATEGORY: 'spend-by-category-chart',
  SPEND_BY_SUPPLIER: 'spend-by-supplier-chart',
  SPEND_BY_PRODUCT: 'spend-by-product',
  TOTAL_SPEND_TIME_SERIES: 'total-spend-time-series',
};

export const DATE_RANGE_SELECTION_LIST = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  THIS_YEAR: 'This year',
  CUSTOM: 'Custom',
};

export const UNVERIFIED_EMAIL_CODE = 'unverified_email';

export const WADDLE_MIGRATION_DEADLINE = new Date(2023, 4, 1);
