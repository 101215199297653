import { resetHeapVenueDetails, setVenueProperties } from '../../hooks/useEventTracking/useEventTracking';
import { CURRENTLY_SELECTED_VENUE_ID_KEY } from '../../utils/Constants';
import postcodeToState from '../../utils/postcodeToState';
import { ApiVenueResponseType, PresentedVenue } from '../../utils/Presenters/PresentVenue/PresentVenue';
import { sessionStorageRemoveItem } from '../../utils/StorageUtils';
import * as actionTypes from './ActionTypes';
// eslint-disable-next-line

export const getVenueSuccess = (venue: PresentedVenue) => {
  // TODO: is company the right name ?
  const {
    venueType,
    collective,
    wallet,
    company,
    isEzymart,
    paymentMethod,
    preAuth,
    defaultCard,
    addresses,
    venueGroup,
  } = venue;

  const { suburb, attendedFrom, attendedTo, postcode, deliveryInstructions, outOfHoursInstructions } = addresses[0];
  const venueDataForSendingToTools = {
    registeredDate: venue.dateAdded.split(' ')[0],
    company,
    venueType,
    collective: Boolean(collective),
    wallet,
    isEzymart,
    paymentMethod,
    hasDefautCard: Boolean(defaultCard?.length),
    hasPreAuth: preAuth !== null,
    suburb,
    attendedFrom,
    attendedTo,
    deliveryInstructions,
    outOfHoursInstructions,
    postcode,
    state: postcodeToState(postcode),
    venueGroup,
  };

  setVenueProperties({
    ...venueDataForSendingToTools,
  });

  return {
    type: actionTypes.GET_VENUE_SUCCESS,
    payload: { venue },
  };
};

export const setVenue = (venue: ApiVenueResponseType) => ({
  type: actionTypes.SET_VENUE,
  payload: { venue },
});

export const clearVenue = () => {
  sessionStorageRemoveItem(CURRENTLY_SELECTED_VENUE_ID_KEY);
  resetHeapVenueDetails();
  return { type: actionTypes.CLEAR_VENUE };
};

export const reinitialiseDefaultVenueState = () => {
  sessionStorageRemoveItem(CURRENTLY_SELECTED_VENUE_ID_KEY);
  resetHeapVenueDetails();
  return { type: actionTypes.REINITIALISE_VENUE_STATE };
};

export const updateVenue = (venue: PresentedVenue) => ({
  type: actionTypes.UPDATE_VENUE,
  payload: { venue },
});

export const setDefaultBetaFeaturesForVenueId = (venueId: number) => ({
  type: actionTypes.SET_DEFAULT_BETA_FEATURES,
  payload: { venueId },
});
