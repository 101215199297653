import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { useResizeDetector } from 'react-resize-detector';

import Typography from '../Typography/Typography';
import ChevronButton from '../ChevronButton/ChevronButton';
import { TYPOGRAPHY_TYPES, BUTTON_VARIANTS, BUTTON_SIZES } from '../constants';

import styles from './SliderContainer.module.scss';

const SliderContainer = ({
  children,
  title,
  description,
  cardWidth,
  cardHeight,
  isLoading,
  contentToggle,
  removePadding,
}) => {
  const containerRef = useRef(null);
  const [flipArrow, setFlipArrow] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [showContent, setShowContent] = useState(true);

  const toggleShowContent = () => setShowContent(!showContent);

  const titleBuffer = title ? 35 : 0;
  const paddingBetweenCards = 16;
  const CardSliderLoader = (
    <ContentLoader
      speed={2}
      width={1200}
      height={cardHeight ? cardHeight + titleBuffer : 209}
      viewBox={`0 0 1200 ${cardHeight ? cardHeight + titleBuffer : 209}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {title ? <rect x="12" y="0" rx="0" ry="0" width={cardWidth / 2} height="25" /> : null}
      {[0, 1, 2, 3, 4, 5, 6].map((index) => (
        <rect
          key={`card-loader-card-${index}`}
          x={index * (cardWidth + paddingBetweenCards) + 12}
          y={titleBuffer}
          rx="3"
          ry="3"
          width={cardWidth}
          height={cardHeight || 168}
        />
      ))}
    </ContentLoader>
  );

  const scrollRight = () => {
    const currentScrollPosition = containerRef.current.scrollLeft;
    containerRef.current.scroll({
      left: currentScrollPosition + cardWidth,
      behavior: 'smooth',
    });
  };

  const scrollToStart = () => {
    containerRef.current.scroll({ left: 0, behavior: 'smooth' });
  };

  const handleScrollableContainer = () => {
    const currentScrollPosition = containerRef.current.scrollLeft;
    const elemWidth = containerRef.current.scrollWidth;
    const { offsetWidth } = containerRef.current;

    const percentageScrolled = currentScrollPosition / (elemWidth - offsetWidth);
    setFlipArrow(percentageScrolled > 0.9);
  };

  const handleScrollArrowClick = () => {
    if (flipArrow) {
      scrollToStart();
    } else {
      scrollRight();
    }
  };

  const potentiallyShowArrow = useCallback(() => {
    const ref = containerRef.current;
    if (!isLoading && ref) {
      const shouldShowArrow = showContent && ref?.scrollWidth > ref?.offsetWidth && children.length !== 1;
      setShowArrow(shouldShowArrow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrowserResize = useCallback(() => {
    potentiallyShowArrow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    potentiallyShowArrow();
    // Only putting this here because we dont want to pass the ref into the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent, isLoading, children.length]);

  useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 600,
    onResize: handleBrowserResize,
    targetRef: containerRef,
  });

  return (
    <React.Fragment>
      {isLoading ? (
        <div className={styles.ContentLoader}>{CardSliderLoader}</div>
      ) : (
        <div className={removePadding ? [styles.ContainerBox, styles.RemovePadding].join(' ') : styles.ContainerBox}>
          <div className={styles.ContainerBox__header}>
            <div className={styles.Header__titleRow}>
              {title ? (
                <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Header__textContainer}>
                  {title}
                </Typography>
              ) : null}
              {contentToggle ? (
                <ChevronButton
                  onClick={toggleShowContent}
                  className={styles.HideButton}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZES.EXTRA_SMALL}
                  flipChevron={showContent}
                >
                  {showContent ? 'Hide' : 'Show'}
                </ChevronButton>
              ) : null}
            </div>
            {description ? (
              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.Header__subheading}>
                {description}
              </Typography>
            ) : null}
          </div>
          <div
            className={showContent ? styles.MainSliderContainer : [styles.MainSliderContainer, styles.Hide].join(' ')}
          >
            <div
              className={styles.SliderContainer}
              ref={containerRef}
              onScroll={() => handleScrollableContainer(containerRef)}
            >
              {showArrow ? (
                <button className={styles.ScrollArrowContainer} onClick={handleScrollArrowClick}>
                  <div
                    className={flipArrow ? styles.ReversibleArrow : [styles.ReversibleArrow, styles.reverse].join(' ')}
                  >
                    <span className={styles.TopBar}></span>
                    <span className={styles.BottomBar}></span>
                  </div>
                </button>
              ) : null}
              {children}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

SliderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  description: PropTypes.string,
  cardWidth: PropTypes.number.isRequired,
  cardHeight: PropTypes.number,
  isLoading: PropTypes.bool,
  contentToggle: PropTypes.bool,
  removePadding: PropTypes.bool,
};

export default SliderContainer;
