import { Helmet } from 'react-helmet';
import MultiStepSignUpForm from '../../components/SignUpForms/MultiStepSignUpForm';

import styles from './SignUp.module.scss';

const SignUp = () => (
  <>
    <Helmet>
      <title>Foodbomb - Sign Up</title>
      <meta name="description" content="Sign up to Foodbomb today!" />
      <link rel="canonical" href="https://app.foodbomb.com.au/login/" />
    </Helmet>
    <div className={styles.SignUpPageContainer}>
      <MultiStepSignUpForm />
    </div>
  </>
);

export default SignUp;
