import useNotifications from '../../hooks/useNotifications/useNotifications.tsx';

const WithNotifications = (WrappedComponent) => {
  const WithNotificationsWrapper = (props) => {
    const notificationFunctions = useNotifications();

    return <WrappedComponent {...notificationFunctions} {...props} />;
  };

  return WithNotificationsWrapper;
};

export default WithNotifications;
