import PropTypes from 'prop-types';
import { FC } from 'react';
import { FoodbombAPI } from '../../../AxiosInstances';
import useNotification from '../../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Button, Typography } from '../../UI/FB';
import styles from './CheckEmail.module.scss';

type CheckEmailTypes = {
  email: string;
  accountId: number;
};

const CheckEmail: FC<CheckEmailTypes> = ({ email, accountId }) => {
  const { createNotification } = useNotification();
  const { sendDatadogError } = useDDErrorReporting();

  const sendEmailAgain = () => {
    FoodbombAPI.post(`account/resend-verification-mail/${accountId}`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Verification email on its way!',
          closable: true,
          timeout: 6000,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to resend email', { error, location: 'Registration Page' });
      });
  };
  return (
    <div className={styles.CheckEmailContainer}>
      <Typography className={styles.CheckEmailHeader} type={TYPOGRAPHY_TYPES.HEADING_XL}>
        Almost there...!
      </Typography>
      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.CheckEmailText}>
        We have sent an email to {email} to verify your email address.
      </Typography>
      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.CheckEmailText}>
        To complete your registration click on the link we&apos;ve sent.
      </Typography>
      <hr className={styles.CheckEmailDivider} />
      <Typography type={TYPOGRAPHY_TYPES.BODY} className={[styles.CheckEmailText, styles.small].join(' ')}>
        Didn&apos;t receive the email? Check your spam folder or click on the button below.
      </Typography>
      <div className={styles.CheckEmailButtonContainer}>
        {/* @ts-expect-error */}
        <Button variant={BUTTON_VARIANTS.SECONDARY} className={styles.SendEmail__button} onClick={sendEmailAgain}>
          Send Email Again
        </Button>
      </div>
    </div>
  );
};

CheckEmail.propTypes = {
  email: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
};
export default CheckEmail;
