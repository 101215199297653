import { createMachine } from 'xstate';
import { CUSTOMER_TYPES } from '../../utils/Constants';

export const REGISTRATION_STATES = {
  CUSTOMER_TYPE_SELECT: 'customerTypeSelect',
  SUBURB_CHECK: 'suburbCheck',
  SUBURB_CHECK_SUCCESS: 'suburbCheckSuccess',
  SUBURB_CHECK_FAILURE: 'suburbCheckFailure',
  VENUE_DETAILS_FORM: 'venueDetailsForm',
  CHECK_YOUR_EMAIL: 'checkYourEmail',
};

export const REGISTRATION_EVENTS = {
  TYPE_SELECTED: 'TYPE_SELECTED',
  SUBURB_CHECK_SUCCESS: 'SUBURB_CHECK_SUCCESS',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SUBURB_CHECK_FAILURE: 'SUBURB_CHECK_FAILURE',
  START_VENUE_DETAILS_FORM: 'START_VENUE_DETAILS_FORM',
  SKIP_TO_STEP_1: 'SKIP_TO_STEP_1',
  SKIP_TO_STEP_2: 'SKIP_TO_STEP_2',
  SKIP_TO_STEP_3: 'SKIP_TO_STEP_3',
  BACK: 'BACK',
  RESET: 'RESET',
};

export const STATE_TO_STEP_MAPPING = {
  [REGISTRATION_STATES.CUSTOMER_TYPE_SELECT]: 0,
  [REGISTRATION_STATES.SUBURB_CHECK]: 1,
  [REGISTRATION_STATES.SUBURB_CHECK_SUCCESS]: 1,
  [REGISTRATION_STATES.SUBURB_CHECK_FAILURE]: 1,
  [REGISTRATION_STATES.VENUE_DETAILS_FORM]: 2,
  [REGISTRATION_STATES.CHECK_YOUR_EMAIL]: 2,
};

type CustomerType = typeof CUSTOMER_TYPES.VENUE | typeof CUSTOMER_TYPES.CONSUMER | undefined;

type RegistrationContext = {};

type RegistrationEvent =
  | { type: typeof REGISTRATION_EVENTS.TYPE_SELECTED }
  | { type: typeof REGISTRATION_EVENTS.SUBURB_CHECK_SUCCESS }
  | { type: typeof REGISTRATION_EVENTS.SIGNUP_SUCCESS }
  | { type: typeof REGISTRATION_EVENTS.SUBURB_CHECK_FAILURE }
  | { type: typeof REGISTRATION_EVENTS.START_VENUE_DETAILS_FORM }
  | { type: typeof REGISTRATION_EVENTS.BACK }
  | { type: typeof REGISTRATION_EVENTS.RESET };

export const createRegistrationMachine = (initialCustomerType: CustomerType) =>
  createMachine<RegistrationContext, RegistrationEvent>({
    id: 'registrationMachine',
    initial: initialCustomerType ? REGISTRATION_STATES.SUBURB_CHECK : REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
    states: {
      [REGISTRATION_STATES.CUSTOMER_TYPE_SELECT]: {
        on: {
          [REGISTRATION_EVENTS.TYPE_SELECTED]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_2]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_3]: REGISTRATION_STATES.VENUE_DETAILS_FORM,
        },
      },
      [REGISTRATION_STATES.SUBURB_CHECK]: {
        on: {
          [REGISTRATION_EVENTS.SUBURB_CHECK_SUCCESS]: REGISTRATION_STATES.SUBURB_CHECK_SUCCESS,
          [REGISTRATION_EVENTS.SUBURB_CHECK_FAILURE]: REGISTRATION_STATES.SUBURB_CHECK_FAILURE,
          [REGISTRATION_EVENTS.BACK]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_1]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_3]: REGISTRATION_STATES.VENUE_DETAILS_FORM,
        },
      },
      [REGISTRATION_STATES.SUBURB_CHECK_SUCCESS]: {
        on: {
          [REGISTRATION_EVENTS.START_VENUE_DETAILS_FORM]: REGISTRATION_STATES.VENUE_DETAILS_FORM,
          [REGISTRATION_EVENTS.BACK]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_1]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_2]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_3]: REGISTRATION_STATES.VENUE_DETAILS_FORM,
        },
      },
      [REGISTRATION_STATES.SUBURB_CHECK_FAILURE]: {
        on: {
          [REGISTRATION_EVENTS.BACK]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_1]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_2]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_3]: REGISTRATION_STATES.VENUE_DETAILS_FORM,
        },
      },
      [REGISTRATION_STATES.VENUE_DETAILS_FORM]: {
        on: {
          [REGISTRATION_EVENTS.SIGNUP_SUCCESS]: REGISTRATION_STATES.CHECK_YOUR_EMAIL,
          [REGISTRATION_EVENTS.BACK]: REGISTRATION_STATES.SUBURB_CHECK,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_1]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
          [REGISTRATION_EVENTS.SKIP_TO_STEP_2]: REGISTRATION_STATES.SUBURB_CHECK,
        },
      },
      [REGISTRATION_STATES.CHECK_YOUR_EMAIL]: {
        on: {
          [REGISTRATION_EVENTS.RESET]: REGISTRATION_STATES.CUSTOMER_TYPE_SELECT,
        },
      },
    },
  });
