import React from 'react';
import PropTypes from 'prop-types';

// This HOC is a hack to focrce Foodbomb CSS to always take precedence over other imported librarys - this should stop us having to write !important everywhere

// NOTE: There is a bug in the current version of react scripts that shows different CSS injector order in built and non built (Development mode) applications

const FBStyleSheetRelocator = ({ children }) => {
  const stylesheets = document.head.querySelectorAll('[rel="stylesheet"]');
  stylesheets.forEach((sheet) => {
    document.head.appendChild(sheet);
  });

  return <React.Fragment>{children}</React.Fragment>;
};

FBStyleSheetRelocator.propTypes = {
  children: PropTypes.node,
};

export default FBStyleSheetRelocator;
