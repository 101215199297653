enum AccountRoleIds {
  owner = 1,
  manager = 2,
  operator = 3,
}

// TODO: refactor this endpoint, we have data we don't need
export interface ApiVenueResponseType {
  id: number;
  company: string; // TODO: does this need to be renamed ?
  emailBusiness: string;
  orderConfirmationEmail: string;
  accountStatementEmail: string;
  phone: string;
  status: number; // we shouldn't return this...
  dateAdded: string;
  abn: string; // Apparently this is an empty string if nothing... Also its a dead field. Its on the address too
  accountManager: null | string; // Dead field
  defaultCard?: string;
  paymentMethod: string;
  venueType?: string;
  wallet?: string;
  zipEnabled: number;
  ccFee?: string; // Why are these strings?
  zipFee?: string;
  waddleFee?: string;
  preAuth?: string | null;
  lastTokenInvalidationAt: string; // Do we need this here?
  referenceId: string;
  collective: number; // 0 or 1
  isEzymart: number; // 0 or 1
  addresses: ApiVenueAddressResponseType[];
  orderCount: number;
  poNumberEnabled?: boolean;
  token: string;
  venueGroup?: VenueGroup;
  venueAccountRoleId: AccountRoleIds;
  lossSupplierIds: number[];
}

export interface ApiVenueAddressResponseType {
  id: number;
  streetAddress: string;
  suburb: string;
  postcode: string;
  venueName: string; // TODO: understand this vs name
  firstName: string;
  lastName: string;
  phone: string;
  unit?: string;
  liquorLicence?: string; // Apparently returning an empty string
  deliveryInstructions?: string; // Apparently returning an empty string
  outOfHoursInstructions?: string; // Apparently returning an empty string
  deliveryContact: string;
  deliveryContactConfirmedAt: string | null;
  attendedFrom: string | null;
  attendedTo: string | null;
  abn: string | null;
  attendedDays: number[];
}

export interface Images {
  id: number;
  description?: string;
  order?: number;
  url: string;
}

export interface ShippingAddress extends ApiVenueAddressResponseType {
  images: Images[];
}

export type AccountVenueInformation = {
  venueId: number;
  company: string;
  suburb: string;
  roleId: number;
  venueGroup?: VenueGroup;
  venueAccountRoleId: AccountRoleIds;
};

export type SimpleAddressFromType = {
  id: number;
  venueName: string;
  streetAddress: string;
  suburb: string;
  postcode: string;
};

export type VenueGroup = {
  id: number;
  isOwner: boolean;
  name: string;
};

export enum CustomerType {
  consumer = 'consumer',
  venue = 'venue',
}

export type VenueDetailsType = {
  businessName: string;
  group?: string | number;
};

export interface PresentedVenue
  extends Omit<ApiVenueResponseType, 'zipEnabled' | 'isEzymart' | 'poNumberEnabled' | 'token'> {
  isInCollective: boolean;
  zipEnabled: boolean;
  isEzymart: boolean;
  poNumberEnabled: boolean;
}

const PresentVenue = (venue: ApiVenueResponseType): PresentedVenue => ({
  isInCollective: Boolean(venue.collective),
  ...venue,
  zipEnabled: Boolean(venue.zipEnabled),
  isEzymart: Boolean(venue.isEzymart),
  poNumberEnabled: Boolean(venue.poNumberEnabled),
});

export default PresentVenue;
