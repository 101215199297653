import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';

import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import styles from './FiltersButton.module.scss';

/**
 * # Filters Button
 * Simple Foodbomb Filter Button Component with Count
 *
 * ## Modifiers
 * ```js
 * numberOfFilters
 * ```
 *
 * ## PropTypes:
 * ```js
 *  FBButton.propTypes = {
 *    numberOfFilters: PropTypes.number.isRequired,
 *    onClick: PropTypes.func.isRequired,
 *    className: PropTypes.string,
 *  };
 * ```
 *
 */

const FiltersButton = ({ onClick, numberOfFilters, className, ...props }) => (
  <Button
    className={[styles.FilterButton, className].join(' ')}
    variant={numberOfFilters ? 'success' : 'secondary'}
    onClick={onClick}
    {...props}
  >
    <React.Fragment>
      {numberOfFilters ? null : <FilterListIcon className={styles.FilterButton__filterIcon} />}
      {numberOfFilters ? (
        <Badge standalone badgeContent={numberOfFilters} className={styles.FilterButton__badge}>
          <span></span>
        </Badge>
      ) : null}
      Filters
    </React.Fragment>
  </Button>
);

FiltersButton.propTypes = {
  numberOfFilters: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FiltersButton;
