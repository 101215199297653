import ValidationError from './ValidationError';

class PropertyRequiredError extends ValidationError {
  constructor(property) {
    super(`No Property: ${property}`);
    this.property = property;
  }
}

export default PropertyRequiredError;
