import { Field, ErrorMessage } from 'formik';
import { InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './FormikFormField.module.scss';

const FormikFormField = ({
  fieldName,
  touched,
  errors,
  placeholder,
  label,
  hideErrors,
  fieldProps,
  endAdornment,
  className,
  disabled,
}) => {
  const showFieldErrors = touched[fieldName] && errors[fieldName] && !hideErrors;

  const fieldStyles = [styles.InputField];
  if (disabled) {
    fieldStyles.push(styles.disabled);
  }
  if (showFieldErrors) {
    fieldStyles.push(styles.errored);
  }

  return (
    <div className={[styles.InputContainer, className].join(' ')}>
      <InputLabel className={showFieldErrors ? [styles.InputLabel, styles.error].join(' ') : styles.InputLabel}>
        {label}
      </InputLabel>
      <div className={styles.FieldWrapper}>
        <Field
          name={fieldName}
          placeholder={placeholder}
          className={fieldStyles.join(' ')}
          {...fieldProps}
          disabled={disabled}
        />
        <div className={styles.InputField__endAdornment}>
          {endAdornment}
          {showFieldErrors ? (
            <div className={styles.ErrorIconContainer}>
              <ErrorIcon className={styles.ErrorIcon} />
            </div>
          ) : null}
        </div>
      </div>
      {hideErrors ? null : (
        <div className={styles.ErrorMessageContainer}>
          <ErrorMessage name={fieldName} />
        </div>
      )}
    </div>
  );
};

FormikFormField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  hideErrors: PropTypes.bool,
  fieldProps: PropTypes.object,
  endAdornment: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormikFormField;
