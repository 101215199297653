import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';
import { CURSOR_BETA, EMPLOYEE_PRANK_LIST, PRODUCT_IDS_BETA } from '../../utils/Constants';
import { CurrentVenueObject } from '../../utils/Presenters/ReduxTypes';

const initialState = {
  loading: false,
  betaFeatures: {},
  forceVenueSelector: false,
};

const getVenueStart = (state: CurrentVenueObject) =>
  updateObject(state, { error: undefined, loading: true, venue: undefined });

const getVenueSuccess = (state: CurrentVenueObject, action: any) =>
  updateObject(state, {
    venue: action.payload.venue,
    error: undefined,
    loading: false,
    betaFeatures: {
      ...state.betaFeatures,
      [CURSOR_BETA]: EMPLOYEE_PRANK_LIST.includes(action.payload.venue.id) ? 1 : undefined,
      [PRODUCT_IDS_BETA]:
        process.env.REACT_APP_PRODUCT_IDS_BETA_VENUE_IDS &&
        process.env.REACT_APP_PRODUCT_IDS_BETA_VENUE_IDS.split(',').includes(`${action.payload.venue.id}`)
          ? 1
          : undefined,
      // Note: Leaving this example here as a way of describing how we can optionally enable client side features for certain venues
      // [MYOB_BETA]:
      // (process.env.REACT_APP_MYOB_BETA_VENUE_IDS &&
      // process.env.REACT_APP_MYOB_BETA_VENUE_IDS.split(',').includes(`${action.payload.venue.id}`))
      // ? 1
      // : undefined,
    },
    whitelistedSupplierId: action.payload.venue.whitelabel_seller_id,
    forceVenueSelector: false,
  });

/*
 * These initial beta features are set before the customer loads, as soon as the JWT is stored, hence only have access to the venue ID
 */
// eslint-disable-next-line
const buildDefaultBetaFeaturesForVenueId = (venueId: number) => ({
  // eg. [CURSOR_BETA]: 1,
  // Enabling setting a beta feature through an environment variable so we dont have to hack things
});

const getVenueFail = (state: CurrentVenueObject, action: any) =>
  updateObject(state, {
    error: action.payload.error,
    loading: false,
    venue: undefined,
  });

const setVenue = (state: CurrentVenueObject, action: any) =>
  updateObject(state, {
    error: undefined,
    loading: false,
    venue: action.payload.venue,
    betaFeatures: {
      [CURSOR_BETA]: EMPLOYEE_PRANK_LIST.includes(action.payload.venue.id) ? 1 : undefined,
      [PRODUCT_IDS_BETA]:
        process.env.REACT_APP_PRODUCT_IDS_BETA_VENUE_IDS &&
        process.env.REACT_APP_PRODUCT_IDS_BETA_VENUE_IDS.split(',').includes(`${action.payload.venue.id}`)
          ? 1
          : undefined,
      // Note: Leaving this example here as a way of describing how we can optionally enable client side features for certain venues
      // [MYOB_BETA]:
      // (process.env.REACT_APP_MYOB_BETA_VENUE_IDS &&
      // process.env.REACT_APP_MYOB_BETA_VENUE_IDS.split(',').includes(`${action.payload.venue.id}`))
      // ? 1
      // : undefined,
    },
    whitelistedSupplierId: action.payload.venue.whitelabel_seller_id,
    forceVenueSelector: false,
  });

const updateVenue = (state: CurrentVenueObject, action: any) => updateObject(state, action.payload.venue);

const updateBetaFeatures = (state: CurrentVenueObject, action: any) =>
  updateObject(state, { betaFeatures: buildDefaultBetaFeaturesForVenueId(action.payload.venue.id) });

const clearVenue = () => ({
  ...initialState,
  forceVenueSelector: true,
});

const reinitialiseDefaultVenueState = () => ({
  ...initialState,
});

const reducer = (state: CurrentVenueObject, action: any) => {
  switch (action.type) {
    case actionTypes.GET_VENUE_START:
      return getVenueStart(state);
    case actionTypes.GET_VENUE_SUCCESS:
      return getVenueSuccess(state, action);
    case actionTypes.GET_VENUE_FAIL:
      return getVenueFail(state, action);
    case actionTypes.SET_VENUE:
      return setVenue(state, action);
    case actionTypes.CLEAR_VENUE:
      return clearVenue();
    case actionTypes.REINITIALISE_VENUE_STATE:
      return reinitialiseDefaultVenueState();
    case actionTypes.UPDATE_VENUE:
      return updateVenue(state, action);
    case actionTypes.SET_DEFAULT_BETA_FEATURES:
      return updateBetaFeatures(state, action);
    default:
      return state || initialState;
  }
};

export default reducer;
