import { Action, createContainer, createHook, createStore, createSubscriber } from 'react-sweet-state';
import { v4 as uuidv4 } from 'uuid';

type Actions = typeof actions;

type State = {
  confettiCount: number;
  confettiInstances: string[];
};

const DEFAULT_CONFETTI_COUNT = 500;
const initialState = {
  confettiCount: DEFAULT_CONFETTI_COUNT,
  confettiInstances: [],
};

const addConfettiInstance =
  (): Action<State> =>
  ({ setState, getState }) => {
    setState({ confettiInstances: [...getState().confettiInstances, uuidv4()] });
  };

const removeConfettiInstance =
  (uuid: string): Action<State> =>
  ({ setState, getState }) => {
    setState({ confettiInstances: getState().confettiInstances.filter((instance) => instance !== uuid) });
  };

const actions = {
  triggerConfetti: addConfettiInstance,
  addConfettiInstance,
  removeConfettiInstance,
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'Confetti Store',
});

export const useConfetti = createHook(Store);
export const ConfettiSubscriber = createSubscriber(Store);
export const ConfettiContainer = createContainer(Store, {});
