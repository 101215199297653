import FBLogger from '../FBLogger';
import { ENVIRONMENT } from '../Constants';

import { FoodbombAPI } from '../../AxiosInstances';

/**
 * Sends errors to Raygun
 * @function sendError
 * @param {Error}   error The actual error object
 * @param {String}  errorMessage The title of the error
 * @param {Object}  data  Additional data to be sent to raygun to help identify the error including the errorObject itself
 * @param {Array}   tags  Optional tags to send to raygun to assist in grouping or filtering errors
 * @return {void}
 *
 *
 * Sends an error message to raygun in production mode
 * Logs a warning to the console in dev mode
 * Appends user data and API version headers if present
 *
 *
 */
export const sendError = (error, errorMessage, data = {}, tags = []) => {
  if (!(error instanceof Error)) {
    const message = `Expected error to be of type Error, got ${typeof error}`;
    if (process.env.NODE_ENV === ENVIRONMENT.PRODUCTION) {
      sendError(new TypeError(message), 'Attempting to log invalid error');
    } else {
      throw new TypeError(message);
    }
  }

  const errorAsString = JSON.stringify(error, Object.getOwnPropertyNames(error));
  const clientVersionLabel = process.env.REACT_APP_VERSION_LABEL;

  // DB
  if (data?.userDetails?.venueId) {
    const analytics = window?.analytics;
    analytics.track(`Client Error`, {
      errorMessage,
      error: errorAsString,
      customData: { ...data },
      clientVersion: clientVersionLabel,
    });
    FoodbombAPI.post(`/venues/${data?.userDetails?.venueId}/events`, {
      type: 'Client Error',
      value: JSON.stringify({
        errorMessage,
        error: errorAsString,
        customData: { ...data },
        clientVersion: clientVersionLabel,
      }),
    }).catch(() => {
      // do nothing
    });
  }

  // DATADOG
  FBLogger.error(`${errorMessage} ⛔️`, {
    error: {
      kind: 'Raygun Error',
      origin: 'custom',
      stack: errorAsString,
      message: error.message,
    },
    ...data,
    clientVersion: clientVersionLabel,
    tags,
  });
};

export const trackPageView = (pathName) => {
  FBLogger.info(`Navigated to ${pathName}`);
};

// This is set when first loading the API versions
export const logAPIVersions = (versions) => {
  const versionsToLog = {
    ...versions,
    venuePortalClientVersion: process.env.REACT_APP_VERSION_LABEL,
  };

  FBLogger.info('Set API Versions', versionsToLog);
};
