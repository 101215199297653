import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ExternalRoute from '../../components/ExternalRoute/ExternalRoute';
import Footer from '../../components/Footer/Footer';
import Login from '../../components/Login/Login';
import Logout from '../../components/Logout/Logout';
import WebsiteNavigation from '../../components/Navigation/WebsiteNavigation/WebsiteNavigation';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import RouteRedirector from '../../components/RouteRedirector/RouteRedirector';
import VerifyInvitation from '../../components/VerifyInvitation/VerifyInvitation';
import { HELP_CENTRE_LINKS } from '../../utils/Constants';
import AcceptInvitationSignUp from '../AcceptInvitationSignUp/AcceptInvitationSignUp';
import SignUp from '../SignUp/SignUp';
import styles from './PublicWebsite.module.scss';

const PublicWebsite: FC = () => (
  <>
    <WebsiteNavigation />
    <div className={styles.PublicWebsiteContainer}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/verify-invitation">
          <VerifyInvitation />
        </Route>
        <Route exact path="/register-and-accept-invite">
          <AcceptInvitationSignUp />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/reset-password/:code">
          <ResetPassword />
        </Route>
        <Route exact path="/signup">
          <SignUp />
        </Route>
        <Route exact path="/signup/:referredCustomerType">
          <SignUp />
        </Route>
        <Route exact path="/faq">
          <ExternalRoute route={HELP_CENTRE_LINKS.HOME} />
        </Route>
        <Route path="/" exact>
          <Login />
        </Route>
        <RouteRedirector />
      </Switch>
    </div>
    <Footer />
  </>
);

export default PublicWebsite;
