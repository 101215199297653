import { InputLabel } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { SelectFieldStyles } from '../FormikSingleSelectField/FormikSingleSelectField';
import styles from '../FormikSingleSelectField/FormikSingleSelectField.module.scss';

const defaultLoadingFunc = () => 'Loading...';

const handleDefaultOnChange = (
  newOrSelectedValue,
  actionMeta,
  selectedValueField,
  setTouched,
  touched,
  fieldName,
  setFieldValue,
  defaultOnChangeCreateCallback,
) => {
  setTouched({ ...touched, [fieldName]: true });
  if (actionMeta === 'create-option' && defaultOnChangeCreateCallback) {
    defaultOnChangeCreateCallback(newOrSelectedValue);
  } else if (newOrSelectedValue === null) {
    setFieldValue(fieldName, '');
  } else {
    setFieldValue(fieldName, selectedValueField ? newOrSelectedValue[selectedValueField] : newOrSelectedValue.id);
  }
};

const FormikCreatableSelect = ({
  value,
  isLoading,
  loadingMessageFunc,
  options,
  placeholder,
  className,
  fieldName,
  setTouched,
  setFieldValue,
  customOnChange,
  defaultOnChangeCreateCallback,
  label,
  touched,
  errors,
  hideErrors,
  isDisabled,
  selectedValueField,
  defaultOptions,
  isClearable,
  escapeClearsValues,
  ...props
}) => (
  <div
    className={
      isDisabled ? [styles.FormikSingleSelectField, styles.disabled].join(' ') : styles.FormikSingleSelectField
    }
  >
    {label ? (
      <InputLabel
        className={
          touched && errors && touched[fieldName] && errors[fieldName]
            ? [styles.InputLabel, styles.error].join(' ')
            : styles.InputLabel
        }
      >
        {label}
      </InputLabel>
    ) : null}
    <React.Fragment>
      <CreatableSelect
        isClearable={isClearable}
        value={value}
        isLoading={isLoading}
        isDisabled={isDisabled}
        loadingMessage={loadingMessageFunc || defaultLoadingFunc}
        placeholder={placeholder || 'Select one'}
        className={[className, styles.Input].join(' ')}
        styles={SelectFieldStyles}
        options={options}
        escapeClearsValues={escapeClearsValues}
        onChange={
          customOnChange
            ? (newValue, actionMeta) => customOnChange(newValue, actionMeta)
            : (newOrSelectedValue, actionMeta) =>
                handleDefaultOnChange(
                  newOrSelectedValue,
                  actionMeta,
                  selectedValueField,
                  setTouched,
                  touched,
                  fieldName,
                  setFieldValue,
                  defaultOnChangeCreateCallback,
                )
        }
        {...props}
      />
    </React.Fragment>
    {errors && !hideErrors ? (
      <div className={styles.ErrorMessageContainer}>
        <ErrorMessage name={fieldName} />
      </div>
    ) : null}
  </div>
);

FormikCreatableSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array, PropTypes.number]),
  isAsync: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessageFunc: PropTypes.func,
  options: PropTypes.array,
  cacheOptions: PropTypes.bool,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  fieldName: PropTypes.string.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  hideErrors: PropTypes.bool,
  setTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customOnChange: PropTypes.func,
  defaultOnChangeCreateCallback: PropTypes.func,
  isDisabled: PropTypes.bool,
  selectedValueField: PropTypes.string,
  isClearable: PropTypes.bool,
  escapeClearsValues: PropTypes.bool,
};

export default FormikCreatableSelect;
