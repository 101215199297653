import jwtDecode from 'jwt-decode';
import {
  FoodbombAPI,
  VenueIntegrationsAPI,
  DeliveryPreferencesAPI,
  UIStoreAPI,
  ReportingAPI,
} from '../../AxiosInstances';
import { localStorageRemoveItem, localStorageSetItem } from '../StorageUtils';
import { deleteCookie } from '../CookieUtils';

export const TOKEN = 'jwtToken';

const axiosInstancesToAuthenticate = [
  FoodbombAPI,
  VenueIntegrationsAPI,
  DeliveryPreferencesAPI,
  UIStoreAPI,
  ReportingAPI,
];

export const potentiallySetAxiosAuthorizationToken = (token) => {
  if (token) {
    axiosInstancesToAuthenticate.forEach((instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    });
  } else {
    // eslint-disable-next-line no-param-reassign
    axiosInstancesToAuthenticate.forEach((instance) => delete instance.defaults.headers.common.Authorization);
  }
};

export const removeAxiosAuthorizationToken = () => {
  // eslint-disable-next-line no-param-reassign
  axiosInstancesToAuthenticate.forEach((instance) => delete instance.defaults.headers.common.Authorization);
};

export const saveAuthorizationTokenToLocalStorage = (token) => {
  if (token) {
    localStorageSetItem(TOKEN, token);
  }
};

export const clearAuthorizationTokenFromLocalStorage = () => {
  localStorageRemoveItem(TOKEN);
};

export const clearAuthorizationTokenFromCookies = () => {
  deleteCookie(TOKEN);
};

export const getDetailsFromToken = (token) => {
  const decodedToken = jwtDecode(token);
  return {
    sub: decodedToken.sub,
    isStaff: decodedToken.staff,
    username: decodedToken.staff ? decodedToken.username : `${decodedToken.first_name} ${decodedToken.last_name}`,
    email: decodedToken?.email || '',
  };
};

export const tokenHasValidHeader = (token) => {
  try {
    const header = jwtDecode(token, { header: true });
    return header.typ === 'JWT' && header.alg === 'HS256';
  } catch (e) {
    return false;
  }
};

export const getTokenExpirationDate = (token) => new Date(jwtDecode(token).exp * 1000);

export const checkIfTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  return !(getTokenExpirationDate(token) > new Date());
};
