/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationAdd, notificationRemove } from '../../reduxStore/actions';
import {
  API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP,
  CART_NOTIFICATION_TARGET_GROUP,
  CONNECTING_INTEGRATION_TARGET_GROUP,
  CRITICAL_DATA_ERROR_TARGET_GROUP,
  EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
  MAINTENANCE_MODE_ERROR_TARGET_GROUP,
  NETWORK_STATUS_NOTIFICATION_TARGET_GROUP,
  NOTIFICATION_TYPES,
  PRODUCT_UNAVAILABLE_TARGET_GROUP,
  UNAUTHORIZED_NOTIFICATION_TARGET_GROUP,
} from '../../utils/Constants';
import { NotificationConfig, ReduxState, Notification, TargetGroup } from '../../utils/Presenters/ReduxTypes';
import { titlise } from '../../utils/StringHelpers';
import styles from './useNotifications.module.scss';

const useNotifications = (): {
  createNotification: (notificationConfig: NotificationConfig) => void;
  updateAddedToCartNotification: (text: string) => void;
  updateNetworkNotification: (isUserOffline: boolean) => void;
  createExpiredSessionNotification: () => void;
  updateUnavailableProductRemovedFromCartNotification: (text: string) => void;
  updateCriticalDataErrorNotification: (text: string, showContactInfo: boolean, additionalJSX: React.ReactNode) => void;
  removeCriticalDataErrorNotification: () => void;
  updateMaintenanceRetryError: (countdown: number) => void;
  updateConnectToIntegrationNotification: (integrationType: string) => void;
  removeConnectToIntegrationNotification: () => void;
  updateAPIRetryNotification: () => void;
  createUnauthorizedNotification: () => void;
} => {
  const currentNotifications: Notification[] = useSelector((state: ReduxState) => state.notifications.notifications);
  const dispatch = useDispatch();

  // Callback Mapped from Redux Method
  const addNotification = useCallback((notification: NotificationConfig): void => {
    dispatch(notificationAdd(notification));
  }, []);

  // Callback mapped from Redux Method
  const removeNotification = useCallback((notificationId: number): void => {
    dispatch(notificationRemove(notificationId));
  }, []);

  const removeNotificationOfTargetGroup = useCallback((targetGroup?: TargetGroup): void => {
    const notificationToRemove = currentNotifications.find(
      (notification: Notification) => notification.uniqueTargetGroup === targetGroup,
    );
    if (targetGroup && notificationToRemove) {
      removeNotification(notificationToRemove.id);
    }
  }, []);

  const createNotification = useCallback((notificationConfig: NotificationConfig): void => {
    if (notificationConfig.uniqueTargetGroup) {
      removeNotificationOfTargetGroup(notificationConfig.uniqueTargetGroup);
    }
    addNotification(notificationConfig);
  }, []);

  const updateAddedToCartNotification = useCallback(
    (text: string): void => {
      createNotification({
        timeout: 3000,
        closable: true,
        type: NOTIFICATION_TYPES.SUCCESS,
        uniqueTargetGroup: CART_NOTIFICATION_TARGET_GROUP,
        content: text,
      });
    },
    [createNotification],
  );

  const createUnauthorizedNotification = (): void => {
    if (
      !currentNotifications.find(
        (notification) => notification.uniqueTargetGroup === UNAUTHORIZED_NOTIFICATION_TARGET_GROUP,
      )
    ) {
      createNotification({
        timeout: 5000,
        closable: true,
        type: NOTIFICATION_TYPES.WARNING,
        uniqueTargetGroup: UNAUTHORIZED_NOTIFICATION_TARGET_GROUP,
        content: 'You are not authorised to access that page.',
      });
    }
  };

  const updateConnectToIntegrationNotification = useCallback(
    (integrationType: string): void => {
      createNotification({
        closable: false,
        type: NOTIFICATION_TYPES.INFO,
        content: `Attempting to integrate with ${titlise(integrationType)}...`,
        showPermanentSpinner: true,
        uniqueTargetGroup: CONNECTING_INTEGRATION_TARGET_GROUP,
      });
    },
    [createNotification],
  );

  const removeConnectToIntegrationNotification = useCallback((): void => {
    removeNotificationOfTargetGroup(CONNECTING_INTEGRATION_TARGET_GROUP);
  }, [removeNotificationOfTargetGroup]);

  const updateUnavailableProductRemovedFromCartNotification = useCallback(
    (text: string): void => {
      createNotification({
        closable: true,
        type: NOTIFICATION_TYPES.WARNING,
        uniqueTargetGroup: PRODUCT_UNAVAILABLE_TARGET_GROUP,
        content: text,
      });
    },
    [createNotification],
  );

  const updateAPIRetryNotification = useCallback(() => {
    createNotification({
      timeout: 5000,
      type: NOTIFICATION_TYPES.WARNING,
      uniqueTargetGroup: API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP,
      content: 'This is taking longer than usual, we are still trying to load... Thanks for your patience!',
    });
  }, [createNotification]);

  const updateNetworkNotification = useCallback(
    (isUserOffline: boolean): void => {
      createNotification({
        timeout: isUserOffline ? undefined : 2000,
        closable: !isUserOffline,
        type: isUserOffline ? NOTIFICATION_TYPES.ERROR : NOTIFICATION_TYPES.SUCCESS,
        uniqueTargetGroup: NETWORK_STATUS_NOTIFICATION_TARGET_GROUP,
        content: isUserOffline ? 'You are currently offline' : 'Connected',
      });
    },
    [createNotification],
  );

  const updateCriticalDataErrorNotification = useCallback(
    (text: string, showContactInfo: boolean = false, additionalJSX: React.ReactNode = undefined): void => {
      createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        uniqueTargetGroup: CRITICAL_DATA_ERROR_TARGET_GROUP,
        content: (
          <div>
            Something went wrong. {text} Our engineers have been notified.
            {showContactInfo ? (
              <p>
                Contact us on&nbsp;
                <a className={styles.OrangeLink} href="tel:1300309055">
                  1300 309 055
                </a>
                &nbsp;or&nbsp;
                <a className={styles.OrangeLink} href="mailto:support@foodbomb.com.au">
                  support@foodbomb.com.au
                </a>
                &nbsp;for assistance.
              </p>
            ) : null}
          </div>
        ),
        actionElement: additionalJSX,
      });
    },
    [createNotification],
  );

  const removeCriticalDataErrorNotification = useCallback((): void => {
    removeNotificationOfTargetGroup(CRITICAL_DATA_ERROR_TARGET_GROUP);
  }, [removeNotificationOfTargetGroup]);

  const updateMaintenanceRetryError = useCallback(
    (countdown: number): void => {
      if (
        !currentNotifications.find(
          (notification) => notification.uniqueTargetGroup === MAINTENANCE_MODE_ERROR_TARGET_GROUP,
        )
      ) {
        createNotification({
          timeout: countdown,
          closable: true,
          type: NOTIFICATION_TYPES.WARNING,
          content: 'Attempting to reconnect shortly...',
          uniqueTargetGroup: MAINTENANCE_MODE_ERROR_TARGET_GROUP,
        });
      }
    },
    [createNotification],
  );

  const createExpiredSessionNotification = useCallback((): void => {
    if (
      !currentNotifications.find(
        (notification) => notification.uniqueTargetGroup === EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
      )
    ) {
      createNotification({
        timeout: 6000,
        closable: true,
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Your session has expired, please log in again',
        uniqueTargetGroup: EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
      });
    }
  }, [createNotification]);

  return {
    createNotification,
    updateAddedToCartNotification,
    updateNetworkNotification,
    createExpiredSessionNotification,
    updateUnavailableProductRemovedFromCartNotification,
    updateCriticalDataErrorNotification,
    removeCriticalDataErrorNotification,
    updateMaintenanceRetryError,
    updateConnectToIntegrationNotification,
    removeConnectToIntegrationNotification,
    updateAPIRetryNotification,
    createUnauthorizedNotification,
  };
};

export default useNotifications;
