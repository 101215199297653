import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Button from '../Button/Button';
import styles from './ChevronButton.module.scss';

/**
 * # ChevronButton
 * Simple Foodbomb ChevronButton Component
 *
 * ## Modifiers
 * ```
 * flipChevron (bool)
 * ```
 *
 * ## PropTypes:
 * ```js
 *  ChevronButton.propTypes = {
 *    children: PropTypes.node,
 *    flipChevron: PropTypes.bool,
 *    className: PropTypes.string,
 *  };
 * ```
 */
const ChevronButton = ({ flipChevron, children, className, ...props }) => (
  <Button className={className} {...props}>
    {children}

    <ArrowDropDownIcon
      className={flipChevron ? [styles.ChevronButton__icon, styles.flipped].join(' ') : styles.ChevronButton__icon}
    />
  </Button>
);

ChevronButton.propTypes = {
  children: PropTypes.node,
  flipChevron: PropTypes.bool,
  className: PropTypes.string,
};

export default ChevronButton;
