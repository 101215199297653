import { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '@material-ui/core';
import styles from './NotificationMessageSection.module.scss';
import { NOTIFICATION_MESSAGE_TYPES, NOTIFICATION_MESSAGE_SIZES, BUTTON_VARIANTS } from '../constants';
import NotificationIcon from '../NotificationIcon/NotificationIcon';
import Button from '../Button/Button';

/**
 * # Notification Message Section
 * Foodbomb Notification Wrapper Component
 *
 * ## Modifiers
 * ### Types
 * ```
 * ['warning', 'error', 'success', 'info']
 * ```
 *
 * ### Sizes
 * ```
 * ['small', 'regular', 'large']
 * ```
 *
 * ## PropTypes:
 * ```js
 *  NotificationMessageSection.propTypes = {
 *    type: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_TYPES)).isRequired,
 *    className: PropTypes.string,
 *    children: PropTypes.node.isRequired,
 *    size: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_SIZES)),
 *    title: PropTypes.string,
 *    actionHandler: (props, propName) => {
 *      if (props.actionLabel === true && (props[propName] === undefined || typeof props[propName] !== 'function')) {
 *        return new Error('Please provide a actionHandler function!');
 *      }
 *      return 0;
 *    },
 *    actionLabel: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
 *    dismissable: PropTypes.bool,
 *
 *  };
 * ```
 */

const NotificationMessageSection = ({
  type,
  size,
  className,
  children,
  title,
  actionHandler,
  actionLabel,
  dismissable,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const notificationSectionStyles = [styles.NotificationSection];
  const colorStripStyles = [styles.ColorStrip];
  if (className) {
    notificationSectionStyles.push(className);
  }
  if (size) {
    notificationSectionStyles.push(styles[size]);
  }
  if (type) {
    notificationSectionStyles.push(styles[type.toLowerCase()]);
    colorStripStyles.push(styles[type.toLowerCase()]);
  }

  return !isDismissed ? (
    <div className={notificationSectionStyles.join(' ')}>
      <div className={colorStripStyles.join(' ')}></div>
      <NotificationIcon type={type} className={styles.Icon} />
      <div className={styles.ContentAndActionsContainer}>
        <div className={styles.ContentContainer}>
          {title ? <div className={styles.Title}>{title}</div> : null}
          {children}
        </div>
        {actionHandler && actionLabel ? (
          <Button
            variant={type === NOTIFICATION_MESSAGE_TYPES.ERROR ? BUTTON_VARIANTS.DANGER : BUTTON_VARIANTS.SECONDARY}
            onClick={actionHandler}
            className={styles.ActionButton}
          >
            {actionLabel}
          </Button>
        ) : null}
      </div>
      {dismissable ? (
        <IconButton onClick={() => setIsDismissed(true)} size="small" className={styles.IconButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : (
        <div className={styles.MobileCloseButtonPlaceholder}></div>
      )}
    </div>
  ) : null;
};

NotificationMessageSection.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_TYPES)).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_SIZES)),
  title: PropTypes.string,
  actionHandler: (props, propName) => {
    if (props.actionLabel === true && (props[propName] === undefined || typeof props[propName] !== 'function')) {
      return new Error('Please provide a actionHandler function!');
    }
    return 0;
  },
  actionLabel: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  dismissable: PropTypes.bool,
};

export default NotificationMessageSection;
