import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as Yup from 'yup';
import { FoodbombAPI } from '../../../AxiosInstances';
import withRedirectHelper from '../../../hoc/withRedirectHelper/withRedirectHelper';
import { PropertyRequiredError } from '../../../exceptions';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withRaygun from '../../../hoc/withRaygun/withRaygun';

import { checkSessionForReferrer, checkSessionForUtm } from '../../../utils/ReferrerUtils/ReferrerUtils';
import { FormikFormField, Button, Typography, ErrorNotification, FormikSingleSelectField } from '../../UI/FB';
import { TYPOGRAPHY_TYPES, BUTTON_VARIANTS, PERSONAL_USE } from '../../../utils/Constants';
import styles from './VenueDetailsForm.module.scss';
import { validateAbnUsingLogic } from '../../../utils/ABNUtils/ABNUtils.tsx';

const DUPLICATE_ERROR_MESSAGE = 'This email has already been registered';

const VenueDetailsForm = ({
  isAuthLoading,
  hasToken,
  onRegistrationSuccess,
  isConsumer = false,
  address,
  redirectToTermsPage,
  redirectToPrivacyPage,
  handleBackClick,
  sendDatadogError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formatDataForAPI = (values) => ({
    firstname: values.firstName,
    lastname: values.lastName,
    email: values.email,
    telephone: String(values.phone.replaceAll(' ', '')),
    company: isConsumer ? `${values.firstName} ${values.lastName}` : values.businessName,
    postcode: address.postcode,
    suburb: address.suburb,
    password: values.password,
    venueGroupName: values.groupCheckbox ? values.groupName : values.businessName,
    type: 'customer',
    abn: values.abn.replaceAll(' ', ''),
    venueType: values.venueType,
    referralSource: values.referralSourceFreeText ? values.referralSourceFreeText : values.referralSource,
  });

  const mapAPIParamToFormParam = (param) => {
    switch (param) {
      case 'firstname':
        return 'firstName';
      case 'lastname':
        return 'lastName';
      case 'telephone':
        return 'phone';
      default:
        return param;
    }
  };

  const otherOptionValue = 'Other';

  const REFERRAL_SOURCE_OPTIONS = [
    {
      label: 'Sales Rep',
      value: 'Sales Rep',
    },
    {
      label: 'Google/Internet',
      value: 'Google/Internet',
    },
    {
      label: 'From another chef/business owner',
      value: 'From another chef/business owner',
    },
    {
      label: 'From a supplier',
      value: 'From a supplier',
    },
    {
      label: 'Social Media',
      value: 'Social Media',
    },
    {
      label: 'Trade Show/Expo',
      value: 'Trade Show/Expo',
    },
    {
      label: otherOptionValue,
      value: otherOptionValue,
    },
  ];

  const VENUE_TYPES_OPTIONS = [
    {
      label: 'Restaurant',
      value: 'Restaurant',
    },
    {
      label: 'QSR/Takeaway',
      value: 'QSR/Takeaway',
    },
    {
      label: 'Club/Pub/RSL',
      value: 'Hotel/Pub/RSL',
    },

    {
      label: 'Catering Company',
      value: 'Catering',
    },
    {
      label: 'Café',
      value: 'Café',
    },
    {
      label: otherOptionValue,
      value: otherOptionValue,
    },
  ];

  const mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[mapAPIParamToFormParam(e.param)] = e.message;
    });
    return formErrors;
  };

  const attemptRegistration = (values, actions) => {
    setEmailInUse(false);
    const payload = formatDataForAPI(values);
    const referrer = checkSessionForReferrer();
    const utm = checkSessionForUtm();
    payload.ref = referrer;
    payload.utm = utm;
    actions.setSubmitting(true);
    FoodbombAPI.post('/venues/register', payload)
      .then((registerResponse) => {
        if (!Object.keys(registerResponse.data).includes('email')) {
          throw new PropertyRequiredError('email');
        }
        if (!Object.keys(registerResponse.data).includes('venueId')) {
          throw new PropertyRequiredError('venueId');
        }
        if (!Object.keys(registerResponse.data).includes('accountId')) {
          throw new PropertyRequiredError('accountId');
        }
        onRegistrationSuccess(
          registerResponse.data.email,
          registerResponse.data.accountId,
          registerResponse.data.venueId,
        );
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          const isEmailInUse = error?.response?.data?.errors.find(
            (err) => err.param === 'email' && err.message === DUPLICATE_ERROR_MESSAGE,
          );
          if (isEmailInUse) {
            setEmailInUse(true);
          }
          actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          actions.setStatus({
            APIError: <ErrorNotification fullWidth />,
          });
          sendDatadogError('Failed to register venue', { error, location: 'Register Venue Form' });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const signUpValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(32, 'Please enter a first name less than 32 characters')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .max(32, 'Please enter a last name less than 32 characters')
      .required('Last name is required'),
    email: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
    phone: Yup.string().trim().required('Phone number is required'),
    businessName: isConsumer ? Yup.string().trim() : Yup.string().required('Business name is required').trim(),
    abn: isConsumer
      ? Yup.string().trim()
      : Yup.string()
          .trim()
          .matches(/^[1-9]/, 'ABN must be a valid 11 digit number')
          .matches(/^(\s?\d){11}$/, 'ABN must be a valid 11 digit number')
          .required('You must provide an ABN')
          .test('Validate ABN number', 'The ABN entered must be valid', (abn) => validateAbnUsingLogic(abn || '')),
    password: Yup.string().min(8, 'Must be at least 8 characters').required('Password is required'),
    groupName: Yup.string().test('group-present', 'Venue group is required', function testVenueGroupPresent(value) {
      return this.parent.groupCheckbox ? value && value.length > 0 : true;
    }),
    legalCheckbox: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions and privacy policy to sign up to Foodbomb',
    ),
    venueType: isConsumer
      ? Yup.string().trim()
      : Yup.string()
          .required('Venue type field is required.')
          .oneOf(VENUE_TYPES_OPTIONS.map((type) => type.value)),

    referralSource: isConsumer
      ? Yup.string().trim()
      : Yup.string()
          .required('Referral field is required.')
          .oneOf(REFERRAL_SOURCE_OPTIONS.map((referral) => referral.value)),
    referralSourceFreeText: Yup.string().trim(),
  });

  if (hasToken) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={styles.VenueDetailsFormContainer}>
      <div className={styles.VenueDetailsForm__header}>
        <Typography className={styles.VenueDetailsForm__title} type={TYPOGRAPHY_TYPES.HEADING_XXL}>
          Enter your details
        </Typography>
      </div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          businessName: '',
          password: '',
          groupName: '',
          legalCheckbox: false,
          groupCheckbox: false,
          abn: '',
          venueType: isConsumer ? PERSONAL_USE : '',
          referralSource: '',
          referralSourceFreeText: '',
        }}
        validationSchema={signUpValidationSchema}
        onSubmit={attemptRegistration}
        initialStatus={{
          APIError: undefined,
        }}
      >
        {({ errors, touched, status, isSubmitting, values, setTouched, setFieldValue }) => (
          <Form className={styles.VenueDetailsForm}>
            <div className={styles.InputRow}>
              <FormikFormField
                fieldName="firstName"
                touched={touched}
                errors={errors}
                placeholder="Gordon"
                label={
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                    First Name*
                  </Typography>
                }
                className={styles.Input__fullWidth}
              />
              <FormikFormField
                fieldName="lastName"
                touched={touched}
                errors={errors}
                placeholder="Ramsay"
                label={
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                    Last Name*
                  </Typography>
                }
                className={styles.Input__fullWidth}
              />
            </div>
            <div className={styles.InputRow}>
              <FormikFormField
                fieldName="email"
                touched={touched}
                errors={errors}
                placeholder="boss@hellskitchen.com"
                label={
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                    Email*
                  </Typography>
                }
                className={styles.Input__fullWidth}
                fieldProps={{
                  type: 'email',
                }}
              />
              {emailInUse ? (
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.EmailError}>
                  Click&nbsp;
                  <Link className={styles.ResetPassword__link} to="/reset-password">
                    here
                  </Link>
                  &nbsp;to reset password.
                </Typography>
              ) : null}
              <FormikFormField
                fieldName="phone"
                touched={touched}
                errors={errors}
                placeholder="0400666666"
                label={
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                    Phone Number*
                  </Typography>
                }
                className={styles.Input__fullWidth}
                fieldProps={{
                  type: 'tel',
                  min: 0,
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </div>
            {!isConsumer ? (
              <div className={styles.InputRow}>
                <FormikFormField
                  fieldName="businessName"
                  touched={touched}
                  errors={errors}
                  placeholder={"Hell's Kitchen"}
                  label={
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                      Venue/Company Name*
                    </Typography>
                  }
                  className={styles.Input__fullWidth}
                  fieldProps={{ autoComplete: 'business-name' }}
                />
              </div>
            ) : null}
            {!isConsumer ? (
              <div className={styles.InputRow}>
                <FormikFormField
                  fieldName="abn"
                  touched={touched}
                  errors={errors}
                  placeholder="Eg 11 222 333 444"
                  label={
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                      ABN*
                    </Typography>
                  }
                  className={styles.Input__fullWidth}
                />
              </div>
            ) : null}
            {!isConsumer ? (
              <div className={styles.SingleSelectWrapper}>
                <FormikSingleSelectField
                  label={
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                      What is your venue type?*
                    </Typography>
                  }
                  fieldName="venueType"
                  errors={errors}
                  touched={touched}
                  value={VENUE_TYPES_OPTIONS.find((reason) => reason.value === values.venueType) || ''}
                  options={VENUE_TYPES_OPTIONS}
                  placeholder="Select from options"
                  setTouched={setTouched}
                  setFieldValue={setFieldValue}
                  maxMenuHeight={200}
                  customOnChange={(selectedValue) => {
                    setFieldValue('venueType', selectedValue.value);
                  }}
                  className={styles.Input__singleSelect}
                />
              </div>
            ) : null}
            {!isConsumer ? (
              <div className={styles.SingleSelectWrapper}>
                <FormikSingleSelectField
                  label={
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                      How did you hear about Foodbomb?*
                    </Typography>
                  }
                  fieldName="referralSource"
                  errors={errors}
                  touched={touched}
                  value={REFERRAL_SOURCE_OPTIONS.find((reason) => reason.value === values.referralSource) || ''}
                  options={REFERRAL_SOURCE_OPTIONS}
                  placeholder="Select from options"
                  setTouched={setTouched}
                  setFieldValue={setFieldValue}
                  maxMenuHeight={200}
                  customOnChange={(selectedValue) => {
                    setFieldValue('referralSource', selectedValue.value);
                  }}
                  className={styles.Input__singleSelect}
                />
                {values.referralSource === otherOptionValue ? (
                  <FormikFormField
                    fieldName="referralSourceFreeText"
                    touched={touched}
                    errors={errors}
                    placeholder="E.g. I saw an advertisement on Facebook."
                    label={''}
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    className={styles.Input__fullWidth}
                  />
                ) : null}
              </div>
            ) : null}
            {!isConsumer ? (
              <>
                <div className={styles.GroupCheckbox__container}>
                  <Field
                    id="groupCheckbox"
                    name="groupCheckbox"
                    component={Checkbox}
                    type="checkbox"
                    classes={{ root: styles.Checkbox__root, checked: styles.Checkbox__checked }}
                  />
                  <label htmlFor="groupCheckbox" className={styles.Checkbox__Label}>
                    This venue belongs to a group
                  </label>
                </div>

                {values.groupCheckbox ? (
                  <div className={styles.InputRow}>
                    <FormikFormField
                      fieldName="groupName"
                      touched={touched}
                      errors={errors}
                      placeholder={'Gordon Ramsay & Co'}
                      label={'Group Name'}
                      className={styles.Input__fullWidth}
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            <div className={styles.VenueDetailsFormSection}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.VenueDetailsFormSection__heading}>
                Create Password
              </Typography>
              <div className={styles.InputRow}>
                <FormikFormField
                  fieldName="password"
                  touched={touched}
                  errors={errors}
                  placeholder="Create a password"
                  label={
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.InputLabel}>
                      Password (8+ Characters)*
                    </Typography>
                  }
                  className={styles.Input__fullWidth}
                  fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'new-password' }}
                  endAdornment={
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      className={styles.PasswordField__endAdornment}
                      onClick={handleToggleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                />
                <div className={styles.LegalCheckbox__container}>
                  <Field
                    id="legalCheckbox"
                    name="legalCheckbox"
                    component={Checkbox}
                    type="checkbox"
                    classes={{ root: styles.Checkbox__root, checked: styles.Checkbox__checked }}
                  />
                  <label htmlFor="legalCheckbox" className={styles.Checkbox__Label}>
                    I agree to the&nbsp;
                    <button onClick={redirectToTermsPage} className={styles.ButtonLink} type="button">
                      Terms and Conditions
                    </button>
                    &nbsp;and&nbsp;
                    <button onClick={redirectToPrivacyPage} className={styles.ButtonLink} type="button">
                      Privacy Policy
                    </button>
                  </label>
                </div>
                <div className={styles.LegalCheckbox__errors}>
                  <ErrorMessage name={'legalCheckbox'} />
                </div>
                <div className={styles.SubmitBtnContainer}>
                  <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    type="submit"
                    className={styles.SubmitBtn}
                    disabled={isSubmitting || isAuthLoading}
                  >
                    {isSubmitting || isAuthLoading ? (
                      <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                    ) : (
                      <React.Fragment>Join Today</React.Fragment>
                    )}
                  </Button>
                </div>
              </div>
              {status.APIError}
            </div>
            <div className={styles.VenueDetailsFormSection}>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                Not in&nbsp;<strong>{address.displayAddress}</strong>?&nbsp;
                <button type="button" onClick={handleBackClick} className={styles.ButtonLink}>
                  Change suburb.
                </button>
              </Typography>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthLoading: state.auth.loading,
  hasToken: Boolean(state.auth.token),
});

VenueDetailsForm.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthLoading: PropTypes.bool,
  hasToken: PropTypes.bool.isRequired,
  onRegistrationSuccess: PropTypes.func.isRequired,
  isConsumer: PropTypes.bool,
  address: PropTypes.shape({
    displayAddress: PropTypes.string.isRequired,
    suburb: PropTypes.string.isRequired,
    postcode: PropTypes.number.isRequired,
  }),
  handleResetForm: PropTypes.func.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  redirectToForSuppliersPage: PropTypes.func.isRequired,
  redirectToTermsPage: PropTypes.func.isRequired,
  redirectToPrivacyPage: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withRaygun(withNotifications(withRedirectHelper(connect(mapStateToProps, null)(VenueDetailsForm))));
