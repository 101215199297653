import { ACTIVE_FINSTRO_STATUSES, ACTIVE_TREVIPAY_STATUSES, PAYMENT_METHODS } from '../Constants';

export type PaymentMethodType = {
  method: string;
  enabled: boolean;
  configured: boolean;
  fee: number;
  preAuth: number | null;
  trevipayStatus?: string | null;
  finstroStatus?: string | null;
};

export type CardAPIResponseType = {
  address_city?: string;
  address_country?: string;
  address_line1?: string;
  address_line1_check?: boolean;
  address_line2?: string;
  address_state?: string;
  address_zip?: string;
  address_zip_check?: boolean;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string; // TODO: Omit
  funding: string;
  id: string;
  last4: string;
  metadata: any;
  name?: string;
  object: string;
  tokenization_method?: string;
};

export const potentiallyGetPaymentMethod = (
  paymentMethods: PaymentMethodType[],
  methodName: string,
): PaymentMethodType | undefined => paymentMethods.find((pm) => pm.method === methodName);

export const checkPaymentMethodEnabled = (paymentMethods: PaymentMethodType[], methodName: string): boolean =>
  Boolean(potentiallyGetPaymentMethod(paymentMethods, methodName)?.enabled);

export const checkPaymentMethodConfigured = (paymentMethods: PaymentMethodType[], methodName: string): boolean =>
  Boolean(potentiallyGetPaymentMethod(paymentMethods, methodName)?.configured);

export const isPaymentMethodEnabledAndConfigured = (paymentMethods: PaymentMethodType[], methodName: string): boolean =>
  Boolean(
    checkPaymentMethodEnabled(paymentMethods, methodName) && checkPaymentMethodConfigured(paymentMethods, methodName),
  );

export const isBombPayConfiguredAndActive = (paymentMethods: PaymentMethodType[]): boolean =>
  checkPaymentMethodEnabled(paymentMethods, PAYMENT_METHODS.TREVIPAY) &&
  checkPaymentMethodConfigured(paymentMethods, PAYMENT_METHODS.TREVIPAY) &&
  ACTIVE_TREVIPAY_STATUSES.includes(
    potentiallyGetPaymentMethod(paymentMethods, PAYMENT_METHODS.TREVIPAY)?.trevipayStatus as string,
  );

// TODO: remove backwards compatibility when status backfil is complete
export const isFinstroConfiguredAndActive = (paymentMethods: PaymentMethodType[]): boolean => {
  const isConfiguredAndActive =
    checkPaymentMethodEnabled(paymentMethods, PAYMENT_METHODS.FINSTRO) &&
    checkPaymentMethodConfigured(paymentMethods, PAYMENT_METHODS.FINSTRO);

  // Note: this is for backwards compatibility.
  // Currently, in production, none of our records have the JSON column stored for status, so we might have to ignore it.
  // On next sign in, it should be getting populated.
  const finstroRecordStatus = potentiallyGetPaymentMethod(paymentMethods, PAYMENT_METHODS.FINSTRO)?.finstroStatus;
  if (finstroRecordStatus) {
    return isConfiguredAndActive && ACTIVE_FINSTRO_STATUSES.includes(finstroRecordStatus);
  }

  return isConfiguredAndActive;
};

export const isPaymentMethodEnabledAndConfiguredAndActive = (
  paymentMethods: PaymentMethodType[],
  methodName: string,
): Boolean => {
  if (methodName === PAYMENT_METHODS.TREVIPAY) {
    return isBombPayConfiguredAndActive(paymentMethods);
  }

  if (methodName === PAYMENT_METHODS.FINSTRO) {
    return isFinstroConfiguredAndActive(paymentMethods);
  }

  return isPaymentMethodEnabledAndConfigured(paymentMethods, methodName);
};
