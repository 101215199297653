import { CircularProgress, InputLabel } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { DataMismatchError } from '../../../../exceptions';
import withRaygun from '../../../../hoc/withRaygun/withRaygun';
import useEventTracking from '../../../../hooks/useEventTracking/useEventTracking.ts';
import { ADDRESS_ERROR_STATES, ANALYTICS_EVENT_CATEGORIES } from '../../../../utils/Constants';
import poweredByGoogleLogoSrc from '../powered_by_google_on_white.png';
import styles from './SuburbSearchField.module.scss';

const ADDRESS_COMPONENTS = 'address_components';

const ADDRESS_COMPONENT_TYPES = {
  POSTCODE: 'postal_code',
  LOCALITY: 'locality',
};

const SuburbSearchField = ({
  handleSelectSuburb,
  sendDatadogError,
  handleAddressError,
  touched,
  errors,
  initialSuburb,
  handleClearSuburb,
  dropDownOverride,
  onSuburbSelected,
}) => {
  const { trackGAEvent } = useEventTracking();
  const [suburb, setSuburb] = useState(initialSuburb || '');
  const [lockSuburb, setLockSuburb] = useState(Boolean(initialSuburb));
  const [loadingPlaceDetails, setLoadingPlaceDetails] = useState(false);
  const sessionToken = useRef(new window.google.maps.places.AutocompleteSessionToken());
  const placesService = useRef(new window.google.maps.places.PlacesService(document.createElement('div')));
  const showFieldErrors = touched.suburb && touched.postcode && (errors.suburb || errors.postcode);

  const handleChange = (updatedSuburb) => {
    setSuburb(updatedSuburb);
  };

  const searchOptions = {
    componentRestrictions: { country: 'au' },
    types: ['(cities)'],
    sessionToken: sessionToken.current,
    strictBounds: true,
  };

  const handleClear = () => {
    setSuburb('');
    setLockSuburb(false);
    handleClearSuburb();
  };

  const getShortNameFromAddressComponents = (results, keyName) =>
    results.address_components.find((addressComponent) => addressComponent.types.includes(keyName))?.short_name;

  const getPostcodeAndLocalityFromAddressDetails = (results, selectedSuburb) => {
    let postcode = getShortNameFromAddressComponents(results, ADDRESS_COMPONENT_TYPES.POSTCODE);
    let locality = getShortNameFromAddressComponents(results, ADDRESS_COMPONENT_TYPES.LOCALITY);

    // This list of results are results that arent viewed as suburbs as they are more general like sydney is a wider area.
    // We map these to the CBD postcodes of the same name as this is the most likely case that the user will expect.
    const MAPPING_LOCALITY_RESULTS_TO_POSTCODES = {
      Sydney: 2000,
      Melbourne: 3000,
      'Melbourne City': 3000,
      Adelaide: 5000,
      Brisbane: 4000,
      Newcastle: 2300,
      Wollongong: 2500,
      Ballarat: 3350,
      Geelong: 3220,
      Bendigo: 3550,
      Canberra: 2601,
      'Coffs Harbour': 2450,
      Nambour: 4560,
      Cairns: 4870,
      Queanbeyan: 2620,
      Toowoomba: 4350,
      'Tweed Heads': 2485,
      Albury: 2640,
    };

    if (!postcode && MAPPING_LOCALITY_RESULTS_TO_POSTCODES[locality]) {
      postcode = MAPPING_LOCALITY_RESULTS_TO_POSTCODES[locality];
    }

    // Because the details result for Sydney actually puts Haymarket in the suburb field.
    if (selectedSuburb === 'Sydney NSW 2000, Australia') {
      locality = 'Sydney';
    }
    if (locality === 'Brisbane') {
      locality = 'Brisbane City';
    }
    return { postcode, locality };
  };

  const onSelectSuburb = (selectedSuburb, placeId) => {
    setLockSuburb(true);
    setLoadingPlaceDetails(true);

    placesService.current.getDetails({ placeId, fields: [ADDRESS_COMPONENTS] }, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { postcode, locality } = getPostcodeAndLocalityFromAddressDetails(results, selectedSuburb);
        if (postcode && locality) {
          const trimmedSuburbName = selectedSuburb.split(',')[0];
          const formattedSuburb = {
            suburb: locality,
            postcode: parseInt(postcode, 10),
          };
          const displayString = trimmedSuburbName.includes(postcode)
            ? trimmedSuburbName
            : `${trimmedSuburbName}, ${postcode}`;
          handleSelectSuburb(formattedSuburb);
          setSuburb(displayString);
          if (onSuburbSelected) {
            onSuburbSelected(formattedSuburb);
          }
        } else {
          handleAddressError(ADDRESS_ERROR_STATES.INVALID_SUBURB);
          sendDatadogError('User Selected unhandleable address from suggestions', {
            error: new DataMismatchError(
              'Unhandleable suggestion',
              'address with locality and postcode information',
              results.address_components,
            ),
          });
          trackGAEvent({
            category: ANALYTICS_EVENT_CATEGORIES.USER_BLOCKS,
            action: 'Suburb selected with insufficient data',
            label: selectedSuburb,
          });
        }
      } else {
        handleAddressError(ADDRESS_ERROR_STATES.FAILED_SUBURB_LOOKUP);
        sendDatadogError('Unable to complete the get details request to google places service', {
          error: new DataMismatchError('Google places results', 'Status of OK', { results, status }),
          location: 'Location Search Field',
        });
      }
      setLoadingPlaceDetails(false);
    });
  };

  return (
    <>
      <PlacesAutocomplete
        value={suburb}
        onChange={handleChange}
        onSelect={onSelectSuburb}
        debounce={200}
        highlightFirstSuggestion
        shouldFetchSuggestions
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <div className={styles.SuburbSearchField}>
              <InputLabel className={showFieldErrors ? [styles.InputLabel, styles.error].join(' ') : styles.InputLabel}>
                Suburb
              </InputLabel>
              <div className={styles.SuburbSearch__inputContainer}>
                <input
                  {...getInputProps({
                    placeholder: 'Search your suburb...',
                    className: lockSuburb
                      ? [styles.SuburbSearch__inputField, styles.locked].join(' ')
                      : styles.SuburbSearch__inputField,
                    disabled: lockSuburb,
                  })}
                />
                <CircularProgress
                  size={24}
                  className={
                    loading || loadingPlaceDetails
                      ? [styles.SuburbSearch__LoadingSpinner, styles.show].join(' ')
                      : styles.SuburbSearch__LoadingSpinner
                  }
                />
                <HighlightOff
                  className={
                    suburb.length
                      ? [styles.SuburbSearch__ClearIcon, styles.show].join(' ')
                      : styles.SuburbSearch__ClearIcon
                  }
                  onClick={handleClear}
                />
              </div>
              {suggestions?.length ? (
                <div
                  className={dropDownOverride ? styles.SuburbSearch__dropdownOverride : styles.SuburbSearch__dropdown}
                >
                  {suggestions.map((suggestion) => (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: suggestion.active
                          ? [styles.SuburbSearch__dropdownElement, styles.active].join(' ')
                          : styles.SuburbSearch__dropdownElement,
                      })}
                      key={suggestion.description.replace(/ /g, '_')}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                  <div className={styles.SuburbSearch__redirectElements}>
                    <div className={styles.PoweredByGoogleLogo__container}>
                      <img
                        src={poweredByGoogleLogoSrc}
                        alt="powered by google logo"
                        className={styles.PoweredByGoogleLogo}
                      ></img>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={styles.ErrorMessageContainer}>
                <ErrorMessage name={errors?.suburb ? 'suburb' : 'postcode'} />
              </div>
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </>
  );
};

SuburbSearchField.propTypes = {
  handleSelectSuburb: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  handleAddressError: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  initialSuburb: PropTypes.string,
  handleClearSuburb: PropTypes.func.isRequired,
  dropDownOverride: PropTypes.bool,
  onSuburbSelected: PropTypes.func,
};

export default withRaygun(SuburbSearchField);
