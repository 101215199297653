import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';
import * as ErrorReportHelper from '../../utils/ErrorReportHelper/ErrorReportHelper';

const SUPPLIERS_API_VERSION = 'suppliersAPIVersion';
const FOODBOMB_API_VERSION = 'foodbombAPIVersion';
const DELIVERY_PREFERENCES_API_VERSION = 'deliveryPreferencesAPIVersion';

const initialState = {
  [SUPPLIERS_API_VERSION]: undefined,
  [FOODBOMB_API_VERSION]: undefined,
  [DELIVERY_PREFERENCES_API_VERSION]: undefined,
};

// Common Functions
const setExternalAPIVersion = (state, action, key) => {
  ErrorReportHelper.logAPIVersions(state);
  return updateObject(state, { [key]: action.payload[key] });
};

const resetExternalAPIVersion = (state, key) => {
  ErrorReportHelper.logAPIVersions(state);
  return updateObject(state, { [key]: undefined });
};

// Reducer Helper Functions for APIs
const setSuppliersAPIVersion = (state, action) => setExternalAPIVersion(state, action, SUPPLIERS_API_VERSION);

const resetSuppliersAPIVersion = (state) => resetExternalAPIVersion(state, SUPPLIERS_API_VERSION);

const setFoodbombAPIVersion = (state, action) => setExternalAPIVersion(state, action, FOODBOMB_API_VERSION);

const resetFoodbombAPIVersion = (state) => resetExternalAPIVersion(state, FOODBOMB_API_VERSION);

const setDeliveryPreferencesAPIVersion = (state, action) =>
  setExternalAPIVersion(state, action, DELIVERY_PREFERENCES_API_VERSION);

const resetDeliveryPreferencesAPIVersion = (state) => resetExternalAPIVersion(state, DELIVERY_PREFERENCES_API_VERSION);

// Reducer Function
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPLIERS_API_VERSION:
      return setSuppliersAPIVersion(state, action);
    case actionTypes.SET_FOODBOMB_API_VERSION:
      return setFoodbombAPIVersion(state, action);
    case actionTypes.SET_DELIVERY_PREFERENCES_API_VERSION:
      return setDeliveryPreferencesAPIVersion(state, action);
    case actionTypes.RESET_SUPPLIERS_API_VERSION:
      return resetSuppliersAPIVersion(state, action);
    case actionTypes.RESET_FOODBOMB_API_VERSION:
      return resetFoodbombAPIVersion(state, action);
    case actionTypes.RESET_DELIVERY_PREFERENCES_API_VERSION:
      return resetDeliveryPreferencesAPIVersion(state, action);
    default:
      return state || initialState;
  }
};

export default reducer;
