export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_OVERRIDE = 'AUTH_OVERRIDE';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_ACCOUNT_SUCCESS = 'AUTH_ACCOUNT_SUCCESS';

export const GET_VENUE_START = 'GET_VENUE_START';
export const GET_VENUE_SUCCESS = 'GET_VENUE_SUCCESS';
export const GET_VENUE_FAIL = 'GET_VENUE_FAIL';
export const SET_VENUE = 'SET_VENUE';
export const UPDATE_VENUE = 'UPDATE_VENUE';
export const CLEAR_VENUE = 'CLEAR_VENUE';
export const SET_DEFAULT_BETA_FEATURES = 'SET_DEFAULT_BETA_FEATURES';
export const REINITIALISE_VENUE_STATE = 'REINITIALISE_VENUE_STATE';

export const SET_SUPPLIERS_API_VERSION = 'SET_SUPPLIERS_API_VERSION';
export const RESET_SUPPLIERS_API_VERSION = 'RESET_SUPPLIERS_API_VERSION';
export const SET_FOODBOMB_API_VERSION = 'SET_FOODBOMB_API_VERSION';
export const RESET_FOODBOMB_API_VERSION = 'RESET_FOODBOMB_API_VERSION';
export const SET_DELIVERY_PREFERENCES_API_VERSION = 'SET_DELIVERY_PREFERENCES_API_VERSION';
export const RESET_DELIVERY_PREFERENCES_API_VERSION = 'RESET_DELIVERY_PREFERENCES_API_VERSION';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const GET_PAYMENT_METHODS_START = 'GET_PAYMENT_METHODS_START';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const CLEAR_PAYMENT_METHODS = 'CLEAR_PAYMENT_METHODS';
