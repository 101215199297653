import { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import FullWidthCSSLoadingSpinner from '../UI/FullWidthCSSLoadingSpinner/FullWidthCSSLoadingSpinner';
import { getFoodbombLogo } from '../../utils/Logos';
import { FoodbombAPI } from '../../AxiosInstances';
import useQuery from '../../hooks/useQuery/useQuery';
import useNotifications from '../../hooks/useNotifications/useNotifications';
import useRedirect from '../../hooks/useRedirect/useRedirect';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting';
import { BUTTON_SIZES, BUTTON_VARIANTS, NOTIFICATION_TYPES } from '../../utils/Constants';
import { DataMismatchError, PropertyRequiredError } from '../../exceptions';
import { Button, ErrorNotification } from '../UI/FB';
import styles from './VerifyInvitation.module.scss';
import { logout } from '../../reduxStore/actions/AuthActions';
import { ReduxState } from '../../utils/Presenters/ReduxTypes';

const VerifyInvitation: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const hasToken = useSelector((state: ReduxState) => state?.auth?.token);
  const [validationError, setValidationError] = useState<string | null>(null);
  const { redirectToExistingAccountInvite, redirectToRegisterAndAcceptInvite, redirectToHome, redirectToLogin } =
    useRedirect();
  const query = useQuery();
  const venueId = query.get('venueId');
  const uuid = query.get('uuid');

  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();

  const dispatch = useDispatch();

  const setDefaultInvalidInviteError = () => {
    setValidationError('This invitation is not valid.');
  };

  const setValidationErrorForResponseParam = useCallback(
    (param: string, defaultMessage: string) => {
      switch (param) {
        case 'account':
          setValidationError('Your account is no longer available.');
          break;
        case 'email':
          createNotification({
            type: NOTIFICATION_TYPES.INFO,
            content: `This invitation is for a different email address. We're logging you out...`,
            timeout: 5000,
            closable: true,
          });
          dispatch(logout());
          break;
        case 'expiresAt':
          setValidationError('This invitation has expired.');
          break;
        case 'uuid':
          setDefaultInvalidInviteError();
          break;
        case 'isAccepted':
          setValidationError('This invite has already been accepted.');
          break;
        case 'venue':
          setValidationError('Your account is no longer available.');
          break;
        default:
          // Default fallback to API error.
          setValidationError(defaultMessage);
          break;
      }
    },
    [createNotification, dispatch],
  );

  const validateInvite = useCallback(async () => {
    setLoading(true);
    setValidationError(null);

    try {
      if (!venueId) throw new PropertyRequiredError('venueId');
      if (!uuid) throw new PropertyRequiredError('uuid');

      const response = await FoodbombAPI.get(`account/invitations/${venueId}/${uuid}/validate`);
      const { isValid, isExistingAccount, email } = response.data;

      if (!isValid) throw new DataMismatchError('isValid', true, isValid);

      if (isExistingAccount && uuid && venueId) {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Invitation accepted. Please sign in to continue.`,
          timeout: 6000,
          closable: true,
        });
        redirectToExistingAccountInvite(uuid, parseInt(venueId, 10));
      } else if (uuid && venueId && email) {
        redirectToRegisterAndAcceptInvite(uuid, parseInt(venueId, 10), email);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof PropertyRequiredError) {
        setDefaultInvalidInviteError();
        sendDatadogError('Somehow arrived on verify invite page without required properties', {
          error,
          location: 'Verify Invitation',
        });
      } else if (error instanceof DataMismatchError) {
        setDefaultInvalidInviteError();
      } else if (
        axios.isAxiosError(error) &&
        error?.response?.status === 400 &&
        error?.response?.data?.errors?.length
      ) {
        const firstError = error.response.data.errors[0];
        const { param } = firstError;
        setValidationErrorForResponseParam(param, firstError.message || 'Unable to accept invitation.');
      } else {
        setValidationError('Something went wrong');
        sendDatadogError('Failed to validate the invite for user', { error, location: 'Verify Invitation' });
      }
    }
  }, [
    venueId,
    uuid,
    createNotification,
    redirectToExistingAccountInvite,
    redirectToRegisterAndAcceptInvite,
    sendDatadogError,
    setValidationErrorForResponseParam,
  ]);

  useEffect(() => {
    validateInvite();
  }, [validateInvite]);

  return (
    <div className={styles.GradientContainer}>
      <div className={styles.SolidContainer}>
        <div className={styles.ContentContainer}>
          <div className={styles.HeaderContainer}>
            <img src={getFoodbombLogo()} className={styles.FoodbombLogo} alt="foodbomb" />
          </div>
          {loading ? (
            <FullWidthCSSLoadingSpinner loadingMessage="Connecting to your venues..." />
          ) : (
            <>
              {validationError ? (
                <ErrorNotification
                  title="We couldn't verify your invitation"
                  body={validationError}
                  className={styles.NoAddressNotification}
                  actions={
                    <div className={styles.ActionsContainer}>
                      {hasToken ? (
                        // @ts-expect-error
                        <Button size={BUTTON_SIZES.SMALL} variant={BUTTON_VARIANTS.SECONDARY} onClick={redirectToHome}>
                          Return Home
                        </Button>
                      ) : (
                        // @ts-expect-error
                        <Button size={BUTTON_SIZES.SMALL} variant={BUTTON_VARIANTS.SECONDARY} onClick={redirectToLogin}>
                          Sign In
                        </Button>
                      )}
                    </div>
                  }
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyInvitation;
