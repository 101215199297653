import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';

const initialState = {
  loading: false,
};

const authStart = (state) => updateObject(state, { error: undefined, loading: true });

const authAccountSuccess = (state, action) =>
  updateObject(state, {
    ...state,
    account: action.payload.account,
  });

const authSuccess = (state, action) =>
  updateObject(state, {
    token: action.payload.token,
    tokenExpirationDate: action.payload.tokenExpirationDate,
    tokenSub: action.payload.tokenSub,
    isStaff: action.payload.isStaff,
    account: action.payload.account,
    loading: false,
  });

const authAccountOverride = (state, action) =>
  updateObject(state, {
    ...state,
    account: { ...action.payload.account, isStaff: state.isStaff },
  });

const authFail = (state, action) => updateObject(state, { error: action.payload.error, loading: false });

const authReset = () => ({ error: initialState.error, loading: initialState.loading });

const authLogout = () => initialState;

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_OVERRIDE:
      return authAccountOverride(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_RESET:
      return authReset(state, action);
    case actionTypes.AUTH_ACCOUNT_SUCCESS:
      return authAccountSuccess(state, action);
    default:
      return state || initialState;
  }
};

export default reducer;
