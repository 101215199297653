import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

import Button from '../Button/Button';
import styles from './ToggleButton.module.scss';

/**
 * # Toggle Button
 * Simple Foodbomb ToggleButton Component
 *
 * ## PropTypes:
 * ```js
 *  ToggleButton.propTypes = {
 *    children: PropTypes.node,
 *    onClick: PropTypes.func.isRequired,
 *    isActive: PropTypes.bool,
 *    className: PropTypes.string,
 *  };
 * ```
 */

const ToggleButton = ({ isActive, onClick, className, children, ...props }) => (
  <Button
    onClick={onClick}
    variant={isActive ? 'success' : 'secondary'}
    className={[styles.ToggleButton, className].join(' ')}
    {...props}
  >
    {children}
    <Switch
      checked={isActive}
      classes={{
        root: styles.Switch__root,
        switchBase: isActive ? [styles.Switch__switchBase, styles.checked].join(' ') : styles.Switch__switchBase,
        thumb: isActive ? [styles.Switch__thumb, styles.checked].join(' ') : styles.Switch__thumb,
        track: isActive ? [styles.Switch__track, styles.checked].join(' ') : styles.Switch__track,
      }}
    />
  </Button>
);

ToggleButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default ToggleButton;
