import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import VenueReducer from './VenueReducer.ts';
import PaymentMethodsReducer from './PaymentMethodReducer';
import VersionsReducer from './VersionsReducer';
import NotificationsReducer from './NotificationsReducer';

export default combineReducers({
  auth: AuthReducer,
  currentVenue: VenueReducer,
  paymentMethods: PaymentMethodsReducer,
  versions: VersionsReducer,
  notifications: NotificationsReducer,
});
