import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../reduxStore/actions/index';

const Logout = ({ onLogout }) => {
  useEffect(() => {
    onLogout();
  });

  return <Redirect to="/" />;
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout()),
});

Logout.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Logout);
