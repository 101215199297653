import { MobileStepper, Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
// FORMS
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useMachine } from '@xstate/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import useEventTracking from '../../hooks/useEventTracking/useEventTracking';
import useNotification from '../../hooks/useNotifications/useNotifications';
import { useConfetti } from '../../stores/ConfettiStore/ConfettiStore';
import {
  BUTTON_VARIANTS,
  CUSTOMER_TYPES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import { Button, NotificationMessageSection, Typography } from '../UI/FB';
import CheckEmail from './CheckEmail/CheckEmail';
import CustomerTypeForm from './CustomerTypeForm/CustomerTypeForm';
import styles from './MultiStepSignUpForm.module.scss';
import {
  createRegistrationMachine,
  REGISTRATION_EVENTS,
  REGISTRATION_STATES,
  STATE_TO_STEP_MAPPING,
} from './RegistrationMachine';
import SuburbCheckForm from './SuburbCheckForm/SuburbCheckForm';
import VenueDetailsForm from './VenueDetailsForm/VenueDetailsForm';

type AddressType = {
  deliveryAddress: string;
  postcode: number;
  suburb: string;
};

const MultiStepSignUpForm = () => {
  const { referredCustomerType } = useParams<{ referredCustomerType: string }>();
  const {
    trackEnterRegistrationFlow,
    trackSelectCustomerTypeForRegistration,
    trackSuccessfulSuburbCheck,
    trackSuburbCheckButNoSuppliers,
    trackStartRegistrationDetailsForm,
    trackCompletedRegistration,
  } = useEventTracking();
  const { createNotification } = useNotification();
  const [, { triggerConfetti }] = useConfetti();

  const isValidCustomerTypeAlreadyInURLParams = useCallback(
    () => Boolean(referredCustomerType && Object.values(CUSTOMER_TYPES).includes(referredCustomerType)),
    [referredCustomerType],
  );

  const potentiallyGetCustomerTypeFromURLParams = useCallback(() => {
    if (isValidCustomerTypeAlreadyInURLParams()) {
      return referredCustomerType;
    }
    return '';
  }, [isValidCustomerTypeAlreadyInURLParams, referredCustomerType]);

  const [accountEmail, setAccountEmail] = useState<string>('');
  const [accountId, setAccountId] = useState<number | null>(null);
  const [address, setAddress] = useState<any>({});
  const [customerType, setCustomerType] = useState<string>(potentiallyGetCustomerTypeFromURLParams());

  const [state, send] = useMachine(createRegistrationMachine(potentiallyGetCustomerTypeFromURLParams()));

  const onRegistrationSuccess = (newAccountEmail: string, newAccountId: number, newVenueId: number) => {
    setAccountEmail(newAccountEmail);
    setAccountId(newAccountId);
    window.heap.identify(newVenueId);
    send(REGISTRATION_EVENTS.SIGNUP_SUCCESS);
    trackCompletedRegistration(customerType, newAccountEmail, newVenueId, newAccountId);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (accountId && accountEmail) {
      triggerConfetti();
    }
  }, [accountEmail, accountId, triggerConfetti]);

  const handleBackStep = (): void => {
    send(REGISTRATION_EVENTS.BACK);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSelectCustomerType = (type: string): void => {
    send(REGISTRATION_EVENTS.TYPE_SELECTED);
    setCustomerType(type);
    trackSelectCustomerTypeForRegistration(type);
  };

  const handleSuburbCheckSuccess = (submittedAddress: AddressType, numberOfSuppliers: number): void => {
    window.scrollTo({ top: -100, behavior: 'smooth' });
    send(REGISTRATION_EVENTS.SUBURB_CHECK_SUCCESS);
    setAddress(submittedAddress);
    trackSuccessfulSuburbCheck(submittedAddress, numberOfSuppliers);
  };

  const handleSuburbCheckFailure = (): void => {
    send(REGISTRATION_EVENTS.SUBURB_CHECK_FAILURE);
    trackSuburbCheckButNoSuppliers(address);
  };

  const handleBeginRegistrationForm = (): void => {
    send(REGISTRATION_EVENTS.START_VENUE_DETAILS_FORM);
    trackStartRegistrationDetailsForm();
  };

  const steps = [
    {
      label: 'Select address type',
      disableNext: !customerType,
      skipEvent: REGISTRATION_EVENTS.SKIP_TO_STEP_1,
    },
    {
      label: 'Check if we deliver to you',
      disableSkip: !customerType,
      disableNext: !customerType || !Object.values(address).length,
      skipEvent: REGISTRATION_EVENTS.SKIP_TO_STEP_2,
    },
    {
      label: 'Add contact details',
      disableSkip: !customerType || !Object.values(address).length,
      skipEvent: REGISTRATION_EVENTS.SKIP_TO_STEP_3,
    },
  ];

  const nextActions = {
    [REGISTRATION_STATES.CUSTOMER_TYPE_SELECT]: () =>
      customerType
        ? send(REGISTRATION_EVENTS.SKIP_TO_STEP_2)
        : createNotification({
            timeout: 6000,
            closable: true,
            type: NOTIFICATION_TYPES.WARNING,
            content: 'Please select your address type before continuing',
          }),
    [REGISTRATION_STATES.SUBURB_CHECK]: () =>
      Object.values(address).length
        ? send(REGISTRATION_EVENTS.SKIP_TO_STEP_3)
        : createNotification({
            timeout: 6000,
            closable: true,
            type: NOTIFICATION_TYPES.WARNING,
            content: 'Please enter an address and click "Check Address" before continuing',
          }),
    [REGISTRATION_STATES.SUBURB_CHECK_SUCCESS]: handleBeginRegistrationForm,
    [REGISTRATION_STATES.SUBURB_CHECK_FAILURE]: () =>
      createNotification({
        closable: true,
        type: NOTIFICATION_TYPES.WARNING,
        content: 'If you have another address you see if we deliver there but you cant continue with this address',
      }),
    [REGISTRATION_STATES.VENUE_DETAILS_FORM]: () =>
      createNotification({
        timeout: 4000,
        closable: true,
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Complete all form fields and click "Submit" to continue',
      }),
  };

  const stateValue = state.value as any as string;
  const activeStep = STATE_TO_STEP_MAPPING[stateValue];

  useEffect(() => {
    trackEnterRegistrationFlow();
    const initialCustomerType = potentiallyGetCustomerTypeFromURLParams();
    if (initialCustomerType) {
      trackSelectCustomerTypeForRegistration(initialCustomerType);
    }
  }, [potentiallyGetCustomerTypeFromURLParams, trackEnterRegistrationFlow, trackSelectCustomerTypeForRegistration]);

  return (
    <>
      {state.matches(REGISTRATION_STATES.CHECK_YOUR_EMAIL) ? null : (
        <>
          <Stepper alternativeLabel activeStep={activeStep} className={styles.SignUp__Stepper}>
            {steps.map((step, idx) => (
              <Step key={`step_${step.label}`}>
                <StepButton disableRipple onClick={() => send(step.skipEvent)} disabled={step.disableSkip}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: styles.Step__GreenOverride,
                        active: styles.Step__GreenOverride,
                      },
                    }}
                  >
                    <Typography
                      type={TYPOGRAPHY_TYPES.BODY_S}
                      className={idx !== activeStep ? styles.StepLabel__Inactive : ''}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <MobileStepper
            position="top"
            variant="dots"
            steps={3}
            activeStep={activeStep}
            classes={{ dotActive: styles.MobileStepper__DotActive }}
            className={styles.MobileStepper}
            nextButton={
              !state.matches(REGISTRATION_STATES.VENUE_DETAILS_FORM) ? (
                <button onClick={nextActions[stateValue]} className={styles.MobileStepperButton}>
                  <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.MobileStepperButton__Text}>
                    Next
                  </Typography>
                  <ChevronRight className={styles.MobileStepperButton__Icon} />
                </button>
              ) : (
                <div className={styles.ButtonPlaceholder}></div>
              )
            }
            backButton={
              !state.matches(REGISTRATION_STATES.CUSTOMER_TYPE_SELECT) ? (
                <button onClick={handleBackStep} className={styles.MobileStepperButton}>
                  <ChevronLeft className={styles.MobileStepperButton__Icon} />
                  <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.MobileStepperButton__Text}>
                    Back
                  </Typography>
                </button>
              ) : (
                <div className={styles.ButtonPlaceholder}></div>
              )
            }
          />
        </>
      )}
      <div className={styles.SignUpFormPaper}>
        <div className={styles.SignUpFormContainer}>
          {state.matches(REGISTRATION_STATES.CUSTOMER_TYPE_SELECT) ? (
            <>
              <Typography className={styles.CustomerTypeHeading} type={TYPOGRAPHY_TYPES.HEADING_XL}>
                Select your address type
              </Typography>
              <CustomerTypeForm handleSelectCustomerType={handleSelectCustomerType} />
            </>
          ) : null}
          {state.matches(REGISTRATION_STATES.SUBURB_CHECK) ? (
            <div className={styles.SectionContainer}>
              <Typography className={styles.SupplierCheck__heading} type={TYPOGRAPHY_TYPES.HEADING_XL}>
                LET&apos;S FIND THE BEST SUPPLIERS FOR YOU
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SupplierCheck__subheading}>
                To give you the best service, we need to know your suburb so we can match you with local suppliers that
                deliver to your area.
              </Typography>
              <SuburbCheckForm
                customerType={customerType}
                onSuccess={handleSuburbCheckSuccess}
                onFailure={handleSuburbCheckFailure}
                onBack={handleBackStep}
                hideBackButton={isValidCustomerTypeAlreadyInURLParams()}
              />
            </div>
          ) : null}
          {state.matches(REGISTRATION_STATES.SUBURB_CHECK_SUCCESS) ? (
            <div className={styles.SectionContainer}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.SuccessTitle}>
                Hurrah!
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.PostcodeSuccess__Subheading}>
                We&apos;ve got a bunch of great suppliers that deliver to your area.
              </Typography>

              <div className={styles.PostcodeSuccess__ButtonContainer}>
                {/** @ts-expect-error */}
                <Button
                  type={BUTTON_VARIANTS.SECONDARY}
                  onClick={handleBeginRegistrationForm}
                  className={styles.PostcodeSuccess__SignUpButton}
                >
                  Enter your details
                </Button>
                {/** @ts-expect-error */}
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={handleBackStep}
                  className={styles.PostcodeSuccess__BackButton}
                >
                  Back
                </Button>
              </div>
            </div>
          ) : null}
          {state.matches(REGISTRATION_STATES.SUBURB_CHECK_FAILURE) ? (
            <div className={styles.SectionContainer}>
              <Typography className={styles.CustomerTypeHeading} type={TYPOGRAPHY_TYPES.HEADING_XL}>
                Check if we deliver to your suburb
              </Typography>
              <NotificationMessageSection type={NOTIFICATION_MESSAGE_TYPES.WARNING} title="Sorry">
                We don&apos;t currently have suppliers that deliver to your suburb.
              </NotificationMessageSection>
              {/** @ts-expect-error */}
              <Button onClick={handleBackStep} className={styles.CenteredButton}>
                Try another suburb
              </Button>
            </div>
          ) : null}
          {state.matches(REGISTRATION_STATES.VENUE_DETAILS_FORM) ? (
            <VenueDetailsForm
              onRegistrationSuccess={onRegistrationSuccess}
              isConsumer={customerType === CUSTOMER_TYPES.CONSUMER}
              address={address}
              handleResetForm={() => {
                send(REGISTRATION_EVENTS.RESET);
              }}
              handleBackClick={handleBackStep}
            />
          ) : null}
          {state.matches(REGISTRATION_STATES.CHECK_YOUR_EMAIL) && accountId ? (
            <div className={styles.CheckEmailContainer}>
              <CheckEmail email={accountEmail} accountId={accountId} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default withRouter(MultiStepSignUpForm);
