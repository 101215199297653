/* eslint-disable no-console */

import { datadogLogs } from '@datadog/browser-logs';
import { ENVIRONMENT } from './Constants';

const isProduction = () => process.env.NODE_ENV === ENVIRONMENT.PRODUCTION;

const LOG_LEVEL_CODES = {
  DEBUG: {
    name: 'DEBUG',
    value: 0,
  },
  INFO: {
    name: 'INFO',
    value: 1,
  },
  WARN: {
    name: 'WARN',
    value: 2,
  },
  ERROR: {
    name: 'ERROR',
    value: 3,
  },
};

const DEFAULT_LOG_LEVEL = LOG_LEVEL_CODES.WARN.name;

const logLevelValue = () => LOG_LEVEL_CODES[process.env.REACT_APP_LOG_LEVEL || DEFAULT_LOG_LEVEL].value;

const executeIfProduction = (prodOpt, fallback) => (isProduction() ? prodOpt() : fallback());

// DATADOG LOGGER
const FBLogger = {
  debug: (...rest) => {
    if (logLevelValue() <= LOG_LEVEL_CODES.DEBUG.value) {
      executeIfProduction(
        () => datadogLogs.logger.debug(...rest),
        () => console.log(...rest),
      );
    }
  },
  info: (...rest) => {
    if (logLevelValue() <= LOG_LEVEL_CODES.INFO.value) {
      executeIfProduction(
        () => datadogLogs.logger.info(...rest),
        () => console.log(...rest),
      );
    }
  },
  warn: (...rest) => {
    if (logLevelValue() <= LOG_LEVEL_CODES.WARN.value) {
      executeIfProduction(
        () => datadogLogs.logger.warn(...rest),
        () => console.warn(...rest),
      );
    }
  },
  error: (...rest) => {
    executeIfProduction(
      () => datadogLogs.logger.error(...rest),
      () => console.error(...rest),
    );
  },
};

export default FBLogger;
