import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button/Button';
import { ALERT_TYPES } from '../constants';
import styles from './AlertDialog.module.scss';

/**
 * # Alert Dialog
 * Foodbomb Alert Dialog
 *
 * ### Variants
 * ```
 * ['warning', 'error', 'confirm']
 * ```
 *
 * ## PropTypes:
 * ```js
 * JoinedToggleButton.propTypes = {
 *  variant: PropTypes.oneOf(Object.values(ALERT_TYPES)),
 *  emoji: PropTypes.string,
 *  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
 *  alertTitle: PropTypes.string,
 *  confirmMessage: PropTypes.string,
 *  cancelMessage: PropTypes.string,
 *  isOpen: PropTypes.bool.isRequired,
 *  handleClose: PropTypes.func.isRequired,
 *  handleConfirm: PropTypes.func.isRequired,
 *  disableConfirm: PropTypes.bool,
 *  hideButtons: PropTypes.bool,
 *  hideEmoji: PropTypes.bool,
 * };
 * ```
 *
 * ## Example
 * ```js
 *  <AlertDialog
 *    variant={'warning'}
 *    alertTitle={'Delete card'}
 *    isOpen={confirmDeleteDialogOpen}
 *    handleClose={this.closeConfirmDeleteDialog}
 *    handleConfirm={this.removeCardToRemove}
 *    confirmMessage={'Delete Card'}
 *  />
 *  <AlertDialog
 *    variant={'warning'}
 *    alertTitle={'Disconnect Zip'}
 *    isOpen={confirmDisconnectZipDialogOpen}
 *    handleClose={this.closeConfirmDisconnectZipDialog}
 *    handleConfirm={this.disconnectZip}
 *    confirmMessage={'Disconnect Zip'}
 *  />
 * ```
 */

const AlertDialog = ({
  variant,
  emoji,
  alertTitle,
  alertMessage,
  confirmMessage,
  cancelMessage,
  isOpen,
  handleClose,
  handleConfirm,
  disableConfirm,
  hideButtons,
  hideEmoji,
  customContent,
}) => {
  const onHandleConfirm = () => {
    handleConfirm();
    handleClose();
  };

  const getAlertIcon = () => {
    if (hideEmoji) return undefined;
    if (emoji) {
      return emoji;
    }
    switch (variant) {
      case ALERT_TYPES.WARNING:
        return '😱';
      case ALERT_TYPES.ERROR:
        return '🥺';
      case ALERT_TYPES.CONFIRM:
        return '🤔';
      default:
        return undefined;
    }
  };

  const alertIcon = getAlertIcon();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.AlertDialogRoot, paper: styles.AlertDialogPaper }}
    >
      <DialogTitle className={styles.AlertDialog__header}>
        {alertIcon ? <div className={styles.AlertDialog__icon}>{alertIcon}</div> : null}
        <div className={styles.AlertDialog__heading}>{alertTitle || 'Are you sure?'}</div>
        <IconButton className={styles.AlertDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {customContent ? (
          <React.Fragment>{customContent}</React.Fragment>
        ) : (
          <div className={styles.AlertDialog__message}>
            {variant === ALERT_TYPES.WARNING ? (
              <React.Fragment>
                <span>Warning:&nbsp;</span>
                {alertMessage || 'this cannot be undone'}
              </React.Fragment>
            ) : null}

            {variant === ALERT_TYPES.ERROR ? (
              <React.Fragment>
                <span>Error:&nbsp;</span>
                {alertMessage || 'Oops, something went wrong'}
              </React.Fragment>
            ) : null}
            {variant === ALERT_TYPES.CONFIRM ? (
              <React.Fragment>{alertMessage || 'Are you sure?'}</React.Fragment>
            ) : null}
          </div>
        )}

        <div className={styles.AlertDialog__actions}>
          {hideButtons ? null : (
            <React.Fragment>
              {variant === ALERT_TYPES.CONFIRM ? (
                <React.Fragment>
                  <Button variant={'secondary'} onClick={handleClose}>
                    {cancelMessage || 'Cancel'}
                  </Button>
                  <Button variant={'primary'} onClick={onHandleConfirm} disabled={disableConfirm}>
                    {confirmMessage || 'Ok'}
                  </Button>
                </React.Fragment>
              ) : (
                <Button variant={'danger'} onClick={onHandleConfirm} disabled={disableConfirm}>
                  {confirmMessage || 'Ok'}
                </Button>
              )}
            </React.Fragment>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  variant: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  emoji: PropTypes.string,
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  alertTitle: PropTypes.string,
  confirmMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  hideButtons: PropTypes.bool,
  hideEmoji: PropTypes.bool,
  customContent: PropTypes.node,
};

export default AlertDialog;
