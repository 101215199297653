import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import styles from './Tooltip.module.scss';

/**
 * # Tooltip
 * Simple Foodbomb Tooltip Component
 *
 * ## PropTypes:
 * ```js
 *  Tooltip.propTypes = {
 *    title: PropTypes.string.isRequired,
 *    children: PropTypes.node,
 *    className: PropTypes.string,
 *    arrowClassName: PropTypes.string,
 *  };
 * ```
 */
const FBTooltip = ({ title, children, className, arrowClassName, ...props }) => {
  const classes = [styles.Tooltip__tooltip];
  const arrowClasses = [styles.Tooltip__arrow];
  classes.push(className);
  arrowClasses.push(arrowClassName);

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={title}
      classes={{ tooltip: classes.join(' '), arrow: arrowClasses.join(' '), popper: styles.Tooltip__popper }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

FBTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
  arrowClassName: PropTypes.string,
};

export default FBTooltip;
