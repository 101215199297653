import * as actionTypes from '../actions/ActionTypes';

import { updateObject } from '../ReduxUtilities';

const initialState = {
  notifications: [],
};

const notificationAdd = (state, action) => {
  const notifications = [...state.notifications];
  const newNotification = action.payload.notification;
  notifications.push(newNotification);
  return updateObject(state, { notifications });
};

const notificationRemove = (state, action) => {
  const notifications = state.notifications.filter((notification) => notification.id !== action.payload.notificationId);
  return updateObject(state, { notifications });
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_ADD:
      return notificationAdd(state, action);
    case actionTypes.NOTIFICATION_REMOVE:
      return notificationRemove(state, action);
    default:
      return state || initialState;
  }
};

export default reducer;
