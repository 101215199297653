import React from 'react';
import PropTypes from 'prop-types';
import { ErrorNotification, NotificationMessageSection } from '../../FB/index';

import styles from './AutoCompleteError.module.scss';
import { NOTIFICATION_MESSAGE_TYPES, ADDRESS_ERROR_STATES } from '../../../../utils/Constants';

const AutoCompleteError = ({ errorState, openManualAddressEntry, noLink }) => {
  const MissingAddressComponentError = (componentName) => (
    <NotificationMessageSection
      type={NOTIFICATION_MESSAGE_TYPES.WARNING}
      title={`Your selection is missing a ${componentName}`}
      className={noLink ? styles.NegativeMargin : null}
    >
      We require a {componentName}. Try searching again or
      {noLink ? (
        <>&nbsp;entering your address manually</>
      ) : (
        <button type="button" className={styles.ButtonLink} onClick={openManualAddressEntry}>
          entering your address manually
        </button>
      )}
    </NotificationMessageSection>
  );
  const ErrorNotifications = {
    [ADDRESS_ERROR_STATES.DEFAULT_ERROR]: (
      <ErrorNotification body="We were unable to check your address" className={styles.AddressErrorNotification} />
    ),
    [ADDRESS_ERROR_STATES.INVALID_SUBURB]: (
      <NotificationMessageSection type={NOTIFICATION_MESSAGE_TYPES.WARNING} title="Your selection is not a Suburb">
        Please enter a valid suburb.
      </NotificationMessageSection>
    ),
    [ADDRESS_ERROR_STATES.MISSING_STREET_NUMBER]: MissingAddressComponentError('street number'),
    [ADDRESS_ERROR_STATES.MISSING_STREET_NAME]: MissingAddressComponentError('street name'),
    [ADDRESS_ERROR_STATES.MISSING_SUBURB]: MissingAddressComponentError('suburb'),
    [ADDRESS_ERROR_STATES.MISSING_POSTCODE]: MissingAddressComponentError('postcode'),
    [ADDRESS_ERROR_STATES.INVALID_ADDRESS]: (
      <NotificationMessageSection type={NOTIFICATION_MESSAGE_TYPES.WARNING} title="We need a more specific address.">
        Try adding a street number and check the address is correct or
        {noLink ? (
          <>&nbsp;enter your address manually</>
        ) : (
          <button type="button" className={styles.ButtonLink} onClick={openManualAddressEntry}>
            enter your address manually
          </button>
        )}
        <button type="button" className={styles.ButtonLink} onClick={openManualAddressEntry}>
          enter your address manually
        </button>
      </NotificationMessageSection>
    ),
    [ADDRESS_ERROR_STATES.FAILED_ADDRESS_LOOKUP]: (
      <ErrorNotification
        title="We are having trouble looking up that address"
        className={styles.AddressErrorNotification}
        hideContactInfo
        body={
          <div>
            Try searching again or
            <button type="button" className={styles.ButtonLink} onClick={openManualAddressEntry}>
              enter your address manually
            </button>
          </div>
        }
      />
    ),
    [ADDRESS_ERROR_STATES.FAILED_SUBURB_LOOKUP]: (
      <ErrorNotification
        body="We are having trouble looking up that suburb"
        className={styles.AddressErrorNotification}
        hideContactInfo
      />
    ),
    [ADDRESS_ERROR_STATES.DIFFICULT_ADDRESS_FORMAT]: (
      <NotificationMessageSection
        type={NOTIFICATION_MESSAGE_TYPES.WARNING}
        title="We might need some help with finding your address"
      >
        Some address formats are a little tricky for us to search. If you can&apos;t find your address in the dropdown
        you can
        <button type="button" className={styles.ButtonLink} onClick={openManualAddressEntry}>
          enter your address manually.
        </button>
      </NotificationMessageSection>
    ),
    [ADDRESS_ERROR_STATES.NO_SUPPLIERS_FOR_SUBURB]: (
      <NotificationMessageSection
        type={NOTIFICATION_MESSAGE_TYPES.WARNING}
        title="We don't have any suppliers that service your address"
      >
        If you have other locations you can check if we deliver there. We are expanding fast though so check again soon.
      </NotificationMessageSection>
    ),
  };

  return <React.Fragment>{ErrorNotifications[errorState] || null}</React.Fragment>;
};

AutoCompleteError.propTypes = {
  errorState: PropTypes.oneOf(Object.values(ADDRESS_ERROR_STATES)),
  openManualAddressEntry: PropTypes.func,
  noLink: PropTypes.bool,
};

export default AutoCompleteError;
