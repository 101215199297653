import FBError from './FBError';

class UnknownItemInCartException extends FBError {
  productId: number;

  portionSize: number;

  constructor(productId: number, portionSize: number) {
    super(`Unknown product: ${productId}} with portion size ${portionSize} found in cart`);
    this.productId = productId;
    this.portionSize = portionSize;
  }
}

export default UnknownItemInCartException;
