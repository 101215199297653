import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting.ts';

const WithRaygun = (WrappedComponent) => {
  const WithRaygunWrapper = (props) => {
    const { sendDatadogError, trackPageView } = useDDErrorReporting();
    return <WrappedComponent sendDatadogError={sendDatadogError} trackPageView={trackPageView} {...props} />;
  };

  return WithRaygunWrapper;
};

export default WithRaygun;
