import { useRef, useLayoutEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow ? { x: window.pageXOffset, y: window.pageYOffset } : { x: position.left, y: position.top };
};

const useScrollPosition = (effect, deps, element, useWindow, wait) => {
  const position = useRef(getScrollPosition({ useWindow }));
  const throttleTimeoutRef = useRef(null);

  const callBack = () => {
    const currentPosition = getScrollPosition({ element, useWindow });
    effect({ previousPosition: position.current, currentPosition });
    position.current = currentPosition;
    throttleTimeoutRef.current = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeoutRef.current === null) {
          throttleTimeoutRef.current = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });
};

export default useScrollPosition;
