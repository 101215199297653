import { AxiosError } from 'axios';
import { useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import LinkedInTag from 'react-linkedin-insight';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { FoodbombAPI } from '../../AxiosInstances';
import { useConfetti } from '../../stores/ConfettiStore/ConfettiStore';
import { ANALYTICS_EVENT_CATEGORIES, CUSTOMER_TYPES, PERSONAL_USE, SEARCH_PAGE_VARIANTS } from '../../utils/Constants';
import { ReduxState } from '../../utils/Presenters/ReduxTypes';
import { titlise } from '../../utils/StringHelpers';
import { Category } from '../ProductSearch/ProductSearchTypes';
import useProductSearchQuery from '../ProductSearch/useProductSearchQuery';
import useDDErrorReporting from '../useDDErrorReporting/useDDErrorReporting';

const LINKEDIN_REGISTRATION_EVENT_ID = '5754369';

type CustomerType = typeof CUSTOMER_TYPES.VENUE | typeof CUSTOMER_TYPES.CONSUMER;
type SuburbType = {
  suburb: string;
  postcode: number | string;
};

interface AttemptedOrder {
  supplierId: number;
  paymentMethod: string | undefined;
  totalPrice: number;
  couponAmount?: number;
  couponCode?: string;
  orderId: number;
}

interface SuccessfulOrder extends AttemptedOrder {
  orderId: number;
}

declare global {
  interface Window {
    heap: any;
  }
}

export const setVenueProperties = (properties: object) => {
  window.heap.addUserProperties(properties);
};

export const trackAnalyticsEvent = (eventName: string, eventProperties?: object): void => {
  window.heap.track(eventName, eventProperties);
};

export const trackSegmentAnalytics = (type: string, properties?: object | undefined) => {
  const analytics = window?.analytics;
  analytics.track(`type: ${type}`, {
    properties,
  });
};

export const identifyAccount = (accountId: number, resetIdentity: Boolean): void => {
  if (resetIdentity) {
    window.heap.resetIdentity();
  }
  window.heap.identify(accountId);
};

export const resetHeapVenueDetails = (): void => {
  window.heap.clearEventProperties();
};

export const clearAnalyticsIdentity = (): void => {
  window.heap.clearEventProperties();
  window.heap.resetIdentity();
};

const useEventTracking = (): {
  trackEvent: (type: string, value?: string | number) => void;
  trackGAEvent: (optionsOrName: UaEventOptions | string, params?: any) => void;
  trackEnterRegistrationFlow: () => void;
  trackSelectCustomerTypeForRegistration: (type: CustomerType) => void;
  trackAttemptSuburbCheck: (selectedSuburb: SuburbType) => void;
  trackSuccessfulSuburbCheck: (selectedSuburb: SuburbType, numberOfSuppliers: number) => void;
  trackSuburbCheckButNoSuppliers: (selectedSuburb: SuburbType) => void;
  trackStartRegistrationDetailsForm: () => void;
  trackCompletedRegistration: (
    type: CustomerType,
    newAccountEmail: string,
    newVenueId: number,
    newAccountId: number,
  ) => void;
  trackVerifyEmail: (accountId: number) => void;
  trackAccountSetupClick: (accountSetupStep: string) => void;
  trackAddAccountDetail: (userReadableKey: string, selectedValue: string) => void;
  trackSearchEvent: (action: string, additionalProperties?: object) => void;
  trackPerformSearch: (searchTerm: string, totalResultsCount: number) => void;
  trackFocusSearch: () => void;
  trackBrowseCategoryToggle: (category: Category, isAdding: Boolean) => void;
  trackRefineCategoryToggle: (category: Category, isAdding: Boolean) => void;
  trackSuccessfulOrder: (supplierOrder: SuccessfulOrder, numberOfOrdersInCheckout: number) => void;
  trackCheckoutEvent: (numberOfSuccessfulOrders: number, numberOfUnsuccessfulOrders: number, revenue: number) => void;
  trackCategoryTile: (categoryName: string, position: number) => void;
  trackSaveAddress: (originalAddress: any, updatedAddress: any) => void;
  trackRequestAMeetingButton: () => void;
  trackMySupplierClicked: () => void;
  trackReorderClicked: () => void;
  trackIncrementCartQuantity: (cartProduct: object, updatedAddress: object) => void;
  trackDecrementCartQuantity: (cartProduct: object, updatedAddress: object) => void;
  trackAddToCartFromProductList: (product: object, additionalProperties: object) => void;
  trackToggleFavouriteInProductList: (
    product: object,
    isCurrentlyFavourited: boolean,
    additionalProperties: object,
  ) => void;
  trackRemoveCartItem: (cartProduct: object, additionalProperties: object) => void;
  trackToggleFavouriteProductInCart: (cartProduct: object, isCurrentlyFavourited: boolean) => void;
  trackNavigationLinkClick: (pageName: string, context: string) => void;
} => {
  const { sendDatadogError } = useDDErrorReporting();
  const location = useLocation();
  const isB2C = useSelector((state: ReduxState) => Boolean(state.currentVenue?.venue?.venueType === PERSONAL_USE));
  const isStaff = useSelector((state: ReduxState) => state.auth.account?.isStaff);
  const venueId = useSelector((state: ReduxState) => state.currentVenue?.venue?.id);
  const [, { triggerConfetti }] = useConfetti();
  const {
    pageVariant,
    isFilteringAgainstFavs,
    isFilteringAgainstBestBuys,
    supplierIdsToFilterAgainstAsJoinedString,
    categoryIdsToFilterAgainstAsJoinedString,
    groupBy,
    productSortBy,
    supplierSortBy,
    targetCategoryId,
    targetSupplierId,
  } = useProductSearchQuery();

  const trackGAEvent = useCallback((optionsOrName: UaEventOptions | string, params?: any): void => {
    ReactGA.event(optionsOrName, params);
  }, []);

  const trackEvent = useCallback(
    (type: string, value?: string | number): void => {
      FoodbombAPI.post(`/venues/${venueId}/events`, {
        type,
        value,
      }).catch((error: Error | AxiosError) => {
        sendDatadogError('Unable to add event', {
          error,
          type,
          value,
          location: 'withEventTracking Hook',
        });
      });
    },
    [sendDatadogError, venueId],
  );

  const getStandardEventProperties = useCallback(
    () => ({
      pagePath: location.pathname,
      queryString: location.search,
      isStaff,
    }),
    [isStaff, location.pathname, location.search],
  );

  const handleTrackAnalyticsEvent = useCallback(
    (eventName: string, eventProperties?: object): void => {
      trackSegmentAnalytics(eventName, eventProperties);
      trackAnalyticsEvent(eventName, eventProperties);
      Userpilot.track(eventName, { ...eventProperties, ...getStandardEventProperties() });
    },
    [getStandardEventProperties],
  );

  const trackEnterRegistrationFlow = useCallback((): void => {
    handleTrackAnalyticsEvent('Registration - Customer Type Select - Enter Registration Flow');
    trackGAEvent({
      category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
      action: 'Clicked Registration CTA',
    });
  }, [handleTrackAnalyticsEvent, trackGAEvent]);

  const trackSelectCustomerTypeForRegistration = useCallback(
    (type: CustomerType): void => {
      handleTrackAnalyticsEvent('Registration - Customer Type Select - Select Customer Type', { customerType: type });
      setVenueProperties({ customerType: type });
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
        action: 'Selected Customer Type',
        label: type,
      });
    },
    [handleTrackAnalyticsEvent, trackGAEvent],
  );

  const trackAttemptSuburbCheck = useCallback(
    (submittedAddress: SuburbType): void => {
      handleTrackAnalyticsEvent('Registration - Address Check - Click - Check Suburb', {
        suburb: submittedAddress.suburb,
        postcode: submittedAddress.postcode,
      });
    },
    [handleTrackAnalyticsEvent],
  );

  const trackSuccessfulSuburbCheck = useCallback(
    (submittedAddress: SuburbType, numberOfSuppliers: number): void => {
      handleTrackAnalyticsEvent('Registration - Address Check - Suburb check returned available suppliers', {
        suburb: submittedAddress.suburb,
        postcode: submittedAddress.postcode,
        numberOfAvailableSuppliers: numberOfSuppliers,
      });
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
        action: 'Postcode Check Success',
      });
    },
    [handleTrackAnalyticsEvent, trackGAEvent],
  );

  const trackSuburbCheckButNoSuppliers = useCallback(
    (address: SuburbType): void => {
      handleTrackAnalyticsEvent('Registration - Address Check - Suburb check returned no available suppliers', {
        suburb: address.suburb,
        postcode: address.postcode,
        numberOfAvailableSuppliers: 0,
      });

      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.USER_BLOCKS,
        action: 'No suppliers for postcode',
        label: `${address?.suburb} - ${address?.postcode}`,
      });
    },
    [handleTrackAnalyticsEvent, trackGAEvent],
  );

  const trackStartRegistrationDetailsForm = useCallback((): void => {
    trackGAEvent({
      category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
      action: 'Registration Form Started',
    });
    ReactPixel.track('StartedRegistration', {});
    handleTrackAnalyticsEvent('Registration - Add Contact Details - Started Registration Form');
  }, [handleTrackAnalyticsEvent, trackGAEvent]);

  const trackCompletedRegistration = useCallback(
    (type: CustomerType, newAccountEmail: string, newVenueId: number, newAccountId: number): void => {
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
        action: 'Successful Registration Completed',
        label: type,
      });
      LinkedInTag.track(LINKEDIN_REGISTRATION_EVENT_ID);
      ReactPixel.track('CompleteRegistration', {});
      ReactPixel.trackCustom(
        type === CUSTOMER_TYPES.CONSUMER ? 'CompleteConsumerRegistration' : 'CompleteVenueRegistration',
        {},
      );
      identifyAccount(newAccountId, false);
      setVenueProperties({ venueId: newVenueId, email: newAccountEmail });
      handleTrackAnalyticsEvent('Registration - Add Contact Details - Completed Registration Form');
    },
    [handleTrackAnalyticsEvent, trackGAEvent],
  );

  const trackVerifyEmail = useCallback(
    (accountId: number): void => {
      // TODO: handle this
      identifyAccount(accountId, false);
      handleTrackAnalyticsEvent('Registration - Login Form - Verified Email');
      trackGAEvent({
        category: ANALYTICS_EVENT_CATEGORIES.ACQUISITION_FUNNEL,
        action: 'Verified Email',
      });
    },
    [handleTrackAnalyticsEvent, trackGAEvent],
  );

  const trackAccountSetupClick = useCallback(
    (accountSetupStep: string): void => {
      handleTrackAnalyticsEvent(`Home - Account Setup - Click - ${accountSetupStep}`, { step: accountSetupStep });
    },
    [handleTrackAnalyticsEvent],
  );

  const trackAddAccountDetail = useCallback(
    (userReadableKey: string, selectedValue: string): void => {
      setVenueProperties({ [userReadableKey]: selectedValue });
      handleTrackAnalyticsEvent(`Home - Account Setup - Added account detail - ${userReadableKey}`, {
        value: selectedValue,
      });
    },
    [handleTrackAnalyticsEvent],
  );

  const trackSearchEvent = useCallback(
    (action: string, additionalProperties?: object): void => {
      trackSegmentAnalytics('Search', {
        categoryIdsToFilterAgainstAsJoinedString,
        groupBy,
        handleTrackAnalyticsEvent,
        isFilteringAgainstFavs,
        isFilteringAgainstBestBuys,
        productSortBy,
        supplierIdsToFilterAgainstAsJoinedString,
        supplierSortBy,
        targetCategoryId,
        targetSupplierId,
        ...additionalProperties,
      });

      handleTrackAnalyticsEvent(`${titlise(pageVariant)} - Search - ${action}`, {
        categoryIdsToFilterAgainstAsJoinedString,
        groupBy,
        handleTrackAnalyticsEvent,
        isFilteringAgainstFavs,
        isFilteringAgainstBestBuys,
        productSortBy,
        supplierIdsToFilterAgainstAsJoinedString,
        supplierSortBy,
        targetCategoryId,
        targetSupplierId,
        ...additionalProperties,
      });
    },
    [
      categoryIdsToFilterAgainstAsJoinedString,
      groupBy,
      handleTrackAnalyticsEvent,
      isFilteringAgainstFavs,
      isFilteringAgainstBestBuys,
      pageVariant,
      productSortBy,
      supplierIdsToFilterAgainstAsJoinedString,
      supplierSortBy,
      targetCategoryId,
      targetSupplierId,
    ],
  );

  const trackPerformSearch = useCallback(
    (searchTerm: string, totalResultsCount: number): void => {
      ReactPixel.track('Search', { search_string: searchTerm });
      trackSearchEvent('Perform Search', { totalResultsCount, searchTerm });
    },
    [trackSearchEvent],
  );

  const trackFocusSearch = useCallback((): void => {
    trackGAEvent({
      category: ANALYTICS_EVENT_CATEGORIES.SEARCH,
      action: pageVariant === SEARCH_PAGE_VARIANTS.GLOBAL ? SEARCH_PAGE_VARIANTS.GLOBAL : 'contextual',
      label: pageVariant,
    });
    trackSearchEvent('Focus Search Bar');
  }, [pageVariant, trackGAEvent, trackSearchEvent]);

  const trackCategoryFilterToggle = useCallback(
    (categoryFilterType: string, category: Category, isAdding: Boolean): void => {
      handleTrackAnalyticsEvent(
        `${titlise(pageVariant)} - ${categoryFilterType} - ${isAdding ? 'Add Filter' : 'Remove Filter'}`,
        {
          category: category.name,
          categoryId: category.id,
          numberOfProductsInCategory: category.numberOfProducts,
          parentCategoryId: category.parentCategoryId || undefined,
          isParentCategory: category.parentCategoryId === 0,
        },
      );
      trackGAEvent({
        category: isAdding ? 'add_facet' : 'remove_facet',
        action: 'category',
        label: `${category.name} - ${category.id}`,
      });
    },
    [handleTrackAnalyticsEvent, pageVariant, trackGAEvent],
  );

  const trackBrowseCategoryToggle = useCallback(
    (category: Category, isAdding: Boolean): void => {
      trackEvent(isAdding ? 'Add Filter (Category)' : 'Remove Filter (Category)', category.name);
      trackCategoryFilterToggle('Browse Category Tree', category, isAdding);
    },
    [trackCategoryFilterToggle, trackEvent],
  );

  const trackRefineCategoryToggle = useCallback(
    (category: Category, isAdding: Boolean): void => {
      trackEvent(
        isAdding ? 'Add Filter (Category)' : 'Remove Filter (Category)',
        category.name ? category.name : category.id,
      );
      trackCategoryFilterToggle('Refine Category Tree', category, isAdding);
    },
    [trackCategoryFilterToggle, trackEvent],
  );

  // Util only used temporarily in trackSuccessfulOrder function
  // TODO: Remove this function when we can deprecate GA
  const getNumberOfPurchasesGroupingForAnalytics = (numberOfOrders: number): string => {
    switch (numberOfOrders) {
      case 1:
        return 'First Purchase';
      case 10:
        return 'Tenth Purchase';
      default:
        return 'Repeat Purchase';
    }
  };

  const trackSuccessfulOrder = useCallback(
    (supplierOrder: SuccessfulOrder, numberOfOrdersInCheckout: number) => {
      trackEvent('Checkout', `Order was successful - order ID #${supplierOrder.orderId}`);
      ReactGA.ga('ec:setAction', 'purchase', {
        id: supplierOrder.orderId,
        affiliation: 'Foodbomb Online',
        revenue: supplierOrder.totalPrice / 100,
        coupon: supplierOrder.couponCode ? 'COUPON_USED' : null,
      });

      FoodbombAPI.get(`venues/${venueId}/orders-paginated?page=1&pageSize=1&orderBy=orderId&sortedBy=desc`)
        .then((response) => {
          const numberOfOrders = response.data.total;
          if (!numberOfOrders) {
            throw new TypeError(`Expected order count to be returned, got ${JSON.stringify(response.data)}`);
          }
          const numberOfPurchasesGroup = getNumberOfPurchasesGroupingForAnalytics(numberOfOrders);
          if (numberOfOrders === 1) {
            triggerConfetti();
          }
          trackGAEvent({
            category: ANALYTICS_EVENT_CATEGORIES.CHECKOUT,
            action: numberOfPurchasesGroup,
            label: isB2C ? CUSTOMER_TYPES.CONSUMER : CUSTOMER_TYPES.VENUE,
            value: supplierOrder.totalPrice / 100,
          });
          ReactPixel.track(numberOfPurchasesGroup, {});
        })
        .catch((error) => {
          sendDatadogError('Unable to get count of orders for tracking Successful order', {
            error,
            location: 'useEventTracking hook',
          });
        })
        .finally(() => {
          ReactPixel.track('Checkout', {});

          handleTrackAnalyticsEvent('Checkout Page - Order Process Modal - Order Successful', {
            orderId: supplierOrder.orderId,
            orderTotalInCents: supplierOrder.totalPrice,
            couponUsed: supplierOrder.couponCode,
            couponAmount: supplierOrder.couponAmount,
            supplierId: supplierOrder.supplierId,
            paymentMethod: supplierOrder.paymentMethod,
            numberOfOrdersInCheckout,
          });
        });
    },
    [trackEvent, venueId, trackGAEvent, isB2C, triggerConfetti, sendDatadogError, handleTrackAnalyticsEvent],
  );

  const trackCheckoutEvent = useCallback(
    (numberOfSuccessfulOrders: number, numberOfUnsuccessfulOrders: number, revenue: number) => {
      trackSegmentAnalytics('Completed Transaction', {
        totalCapturedRevenue: revenue,
        numberOfSuccessfulOrders,
        numberOfUnsuccessfulOrders,
      });

      trackEvent(
        'Completed Transation',
        `Checkout complete with ${numberOfSuccessfulOrders} successful orders and ${numberOfUnsuccessfulOrders} failed orders`,
      );
      handleTrackAnalyticsEvent('Checkout Page - Order Process Modal - Checkout Complete', {
        totalCapturedRevenue: revenue,
        numberOfOrdersInCheckout: numberOfSuccessfulOrders + numberOfUnsuccessfulOrders,
        numberOfSuccessfulOrders,
        numberOfUnsuccessfulOrders,
      });
      trackGAEvent({
        category: 'Checkout Batch Completed',
        action: `${numberOfSuccessfulOrders} successful orders`,
      });
    },
    [handleTrackAnalyticsEvent, trackEvent, trackGAEvent],
  );

  const trackCategoryTile = useCallback(
    (categoryName: string, position: number): void => {
      trackSegmentAnalytics('Categories', {
        categoryName,
        position,
        targetCategoryId,
      });
      handleTrackAnalyticsEvent(`Home - Categories Widget - Click Category Tile`, { categoryName, position });
    },
    [handleTrackAnalyticsEvent, targetCategoryId],
  );
  const trackSaveAddress = useCallback(
    (originalAddress: any, updatedAddress: any) => {
      const updatedStatusOfAddressValues: { [key: string]: any } = {};

      Object.keys(updatedAddress).forEach((key: string) => {
        if (!originalAddress[key] && updatedAddress[key]) {
          updatedStatusOfAddressValues[key] = 'Added';
        } else if (originalAddress[key] && !updatedAddress[key]) {
          updatedStatusOfAddressValues[key] = 'Deleted';
        } else if (originalAddress[key] !== updatedAddress[key]) {
          updatedStatusOfAddressValues[key] = 'Updated';
        }
      });

      handleTrackAnalyticsEvent('Account - Venue Details - Click - Save', { ...updatedStatusOfAddressValues });
    },
    [handleTrackAnalyticsEvent],
  );

  const trackRequestAMeetingButton = useCallback((): void => {
    handleTrackAnalyticsEvent(`Home - Request a Meeting - Button - Click`);
  }, [handleTrackAnalyticsEvent]);

  const trackMySupplierClicked = useCallback((): void => {
    handleTrackAnalyticsEvent('Home - My Supplier - Button - Click');
  }, [handleTrackAnalyticsEvent]);

  const trackReorderClicked = useCallback((): void => {
    handleTrackAnalyticsEvent('Home - Your Recent Orders - Reorder Button - Click');
  }, [handleTrackAnalyticsEvent]);

  const trackProductInProductListAction = useCallback(
    (action: string, cartProduct: object, additionalProperties: object) => {
      trackSegmentAnalytics(action, cartProduct);
      handleTrackAnalyticsEvent(`${titlise(pageVariant)} - Product List - ${action}`, {
        ...cartProduct,
        categoryIdsToFilterAgainstAsJoinedString,
        groupBy,
        isFilteringAgainstFavs,
        isFilteringAgainstBestBuys,
        productSortBy,
        supplierIdsToFilterAgainstAsJoinedString,
        supplierSortBy,
        targetCategoryId,
        targetSupplierId,
        ...additionalProperties,
      });
    },
    [
      handleTrackAnalyticsEvent,
      pageVariant,
      categoryIdsToFilterAgainstAsJoinedString,
      groupBy,
      isFilteringAgainstFavs,
      isFilteringAgainstBestBuys,
      productSortBy,
      supplierIdsToFilterAgainstAsJoinedString,
      supplierSortBy,
      targetCategoryId,
      targetSupplierId,
    ],
  );

  const trackAddToCartFromProductList = useCallback(
    (product: object, additionalProperties: object) => {
      trackSegmentAnalytics('Add To Cart', { ...product, ...additionalProperties });
      trackProductInProductListAction('Add To Cart', product, additionalProperties);
    },
    [trackProductInProductListAction],
  );

  const trackToggleFavouriteInProductList = useCallback(
    (product: object, isCurrentlyFavourited: boolean, additionalProperties: object) => {
      trackProductInProductListAction(
        `${isCurrentlyFavourited ? 'Remove' : 'Add'} Product ${isCurrentlyFavourited ? 'from' : 'to'} Favourites`,
        product,
        additionalProperties,
      );
    },
    [trackProductInProductListAction],
  );

  const trackCartProductAction = useCallback(
    (action: string, cartProduct: any, additionalProperties: object) => {
      handleTrackAnalyticsEvent(`Cart - Product Row - ${action}`, { ...cartProduct, ...additionalProperties });
    },
    [handleTrackAnalyticsEvent],
  );

  const trackIncrementCartQuantity = useCallback(
    (cartProduct: object, additionalProperties: object) => {
      trackSegmentAnalytics('Increment Cart Quantity', { ...cartProduct, ...additionalProperties });
      trackCartProductAction('Increment Cart Quantity', cartProduct, additionalProperties);
    },
    [trackCartProductAction],
  );

  const trackDecrementCartQuantity = useCallback(
    (cartProduct: object, additionalProperties: object) => {
      trackSegmentAnalytics('Decrement Cart Quantity', { ...cartProduct, ...additionalProperties });
      trackCartProductAction('Decrement Cart Quantity', cartProduct, additionalProperties);
    },
    [trackCartProductAction],
  );

  const trackRemoveCartItem = useCallback(
    (cartProduct: object, additionalProperties: object) => {
      trackSegmentAnalytics('Remove Item From Cart', { ...cartProduct, ...additionalProperties });
      trackCartProductAction('Remove Item From Cart', cartProduct, additionalProperties);
    },
    [trackCartProductAction],
  );

  const trackToggleFavouriteProductInCart = useCallback(
    (cartProduct: object, isCurrentlyFavourited) => {
      trackSegmentAnalytics(
        `${isCurrentlyFavourited ? 'Remove' : 'Add'} Product ${isCurrentlyFavourited ? 'from' : 'to'} Favourites`,
        cartProduct,
      );
      trackCartProductAction(
        `${isCurrentlyFavourited ? 'Remove' : 'Add'} Product ${isCurrentlyFavourited ? 'from' : 'to'} Favourites`,
        cartProduct,
        {},
      );
    },
    [trackCartProductAction],
  );

  const trackNavigationLinkClick = useCallback(
    (pageName: string, context: string) => {
      handleTrackAnalyticsEvent(`Navigation - ${context} - ${pageName}`, {});
    },
    [handleTrackAnalyticsEvent],
  );

  return {
    trackGAEvent,
    trackEvent,
    trackEnterRegistrationFlow,
    trackSelectCustomerTypeForRegistration,
    trackAttemptSuburbCheck,
    trackSuccessfulSuburbCheck,
    trackSuburbCheckButNoSuppliers,
    trackStartRegistrationDetailsForm,
    trackCompletedRegistration,
    trackVerifyEmail,
    trackAccountSetupClick,
    trackAddAccountDetail,
    trackSearchEvent,
    trackPerformSearch,
    trackFocusSearch,
    trackRefineCategoryToggle,
    trackBrowseCategoryToggle,
    trackSuccessfulOrder,
    trackCheckoutEvent,
    trackCategoryTile,
    trackSaveAddress,
    trackRequestAMeetingButton,
    trackMySupplierClicked,
    trackReorderClicked,
    trackIncrementCartQuantity,
    trackDecrementCartQuantity,
    trackAddToCartFromProductList,
    trackToggleFavouriteInProductList,
    trackRemoveCartItem,
    trackToggleFavouriteProductInCart,
    trackNavigationLinkClick,
  };
};

export default useEventTracking;
