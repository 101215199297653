import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import React, { createRef, lazy, Suspense } from 'react';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { DeliveryPreferencesAPI, FoodbombAPI, ReportingAPI, UIStoreAPI, VenueIntegrationsAPI } from '../AxiosInstances';
import {
  DELIVERY_PREFERENCES_API_BASE_URL,
  FOODBOMB_API_BASE_URL,
  SUPPLIERS_API_BASE_URL,
  VENUE_INTEGRATIONS_API_BASE_URL,
} from '../BaseURLs';
import Logout from '../components/Logout/Logout';
import FullWidthCSSLoadingSpinner from '../components/UI/FullWidthCSSLoadingSpinner/FullWidthCSSLoadingSpinner.tsx';
import AcceptExistingAccountInvite from '../components/VerifyExistingAccountInvite/AcceptExistingAccountInvite.tsx';
import VerifyInvitation from '../components/VerifyInvitation/VerifyInvitation.tsx';
import { FBError } from '../exceptions';
import withNotifications from '../hoc/withNotifications/withNotifications';
import withRaygun from '../hoc/withRaygun/withRaygun';
import withRedirectHelper from '../hoc/withRedirectHelper/withRedirectHelper';
import * as actions from '../reduxStore/actions';
import buildLogDetails from '../utils/DatadogHelper.ts';
import {
  ACTIVE_BETA_FEATURES,
  ENVIRONMENT,
  FB_API_VERSION,
  INTEGRATION_QUERY_KEY,
  MAINTENANCE_BETA,
  MAINTENANCE_HEADER,
  MAINTENANCE_FLAG,
  PERSONAL_USE,
} from '../utils/Constants';
import { trackSegmentAnalytics } from '../hooks/useEventTracking/useEventTracking.ts';
import FBLogger from '../utils/FBLogger';
import { dynamicallyUpdateFavicon } from '../utils/Logos.ts';
import QueryStringParser from '../utils/queryStringParser';
import { saveIntegrationValuesToSessionStorage, sessionStorageSetItem } from '../utils/StorageUtils';
import { checkIfTokenExpired } from '../utils/Tokens/TokenHelper';
import Maintenance from './Maintenance/Maintenance';
import Notifications from './Notifications/Notifications.tsx';
import PublicWebsite from './PublicWebsite/PublicWebsite.tsx';

const LINKEDIN_PARTNER_ID = '456628';

const App = lazy(() => import('./App'));
const VenueSelection = lazy(() => import('./VenueSelection/VenueSelection.tsx'));

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unmountGARouterListener: undefined,
      isMaintenanceMode: false,
      loadingInitialHealthCheck: true,
      maintenanceRefreshRate: 180000,
      showNewVenueDialog: false,
    };
    this.props.attemptAutoSignIn();

    this.initialiseAxiosInstances();
    this.initialiseRaygunListener();
    this.initialiseDatadogListener();
    this.initialiseSegmentTracking();

    this.initialHealthCheck();
    this.appRouterRef = createRef();
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    const { redirectToSignUp, location, sendDatadogError } = this.props;

    const isProduction = process.env.REACT_APP_FB_ENVIRONMENT === ENVIRONMENT.PRODUCTION;
    const GAKey = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (GAKey) {
      FBLogger.info(`Initialising GA. Test mode: ${!isProduction}`);
    } else {
      FBLogger.warn('No GA Measurement ID found.');
    }

    LinkedInTag.init(LINKEDIN_PARTNER_ID);

    ReactPixel.init('149993769007648');
    ReactPixel.pageView();

    Array.from(document.querySelectorAll('[data-jss-pre-render]')).forEach((elem) => elem.parentNode.removeChild(elem));

    const values = QueryStringParser(location.search);

    dynamicallyUpdateFavicon();

    if (values.referrer) {
      sessionStorageSetItem('referrer', values.referrer);
    }

    if (values.whitelabel) {
      sessionStorageSetItem('whitelabel', values.whitelabel);
    }

    if (values.utm_source) {
      sessionStorageSetItem('utm', `${values.utm_source} - ${values.utm_medium} - ${values.utm_campaign}`);
    }

    if (values[INTEGRATION_QUERY_KEY]) {
      saveIntegrationValuesToSessionStorage(values[INTEGRATION_QUERY_KEY].toLowerCase(), values, sendDatadogError);
    }

    // TODO: do we still need this ? We are handling the legacy query param
    if (values.signup) {
      redirectToSignUp();
    }
  }

  componentWillUnmount() {
    const { unmountGARouterListener } = this.state;
    if (unmountGARouterListener) {
      unmountGARouterListener();
    }
    window.heap.clearEventProperties();
  }

  componentDidUpdate() {
    this.potentiallyRedirectToAcceptInvitePage();
  }

  potentiallyRedirectToAcceptInvitePage = () => {
    const { hasToken, pageToRedirectTo, clearPageToRedirectTo, history } = this.props;

    if (pageToRedirectTo?.includes('accept-existing-invite') && hasToken) {
      const page = pageToRedirectTo;
      history.push(page);
      clearPageToRedirectTo();
    }
  };

  handleBrowserRefreshForAPIVersionMismatch = (axiosInstance, currentAPIVersion) => {
    let storedAPIVersion;
    let updateAPIVersion;

    switch (axiosInstance.defaults.baseURL) {
      case SUPPLIERS_API_BASE_URL:
        storedAPIVersion = this.props.suppliersAPIVersion;
        updateAPIVersion = this.props.onSetSuppliersAPIVersion;
        break;
      case FOODBOMB_API_BASE_URL:
        storedAPIVersion = this.props.foodbombAPIVersion;
        updateAPIVersion = this.props.onSetFoodbombAPIVersion;
        break;
      case DELIVERY_PREFERENCES_API_BASE_URL:
        storedAPIVersion = this.props.deliveryPreferencesAPIVersion;
        updateAPIVersion = this.props.onSetDeliveryPreferencesAPIVersion;
        break;
      default:
        throw new Error(`Unexpected base URL: ${axiosInstance.defaults.baseURL}`);
    }

    if (storedAPIVersion && currentAPIVersion) {
      if (currentAPIVersion !== storedAPIVersion) {
        window.location.reload();
      }
    } else if (currentAPIVersion) {
      // eslint-disable-next-line no-param-reassign
      axiosInstance.defaults.headers.common[FB_API_VERSION] = currentAPIVersion;
      updateAPIVersion(currentAPIVersion);
    }
  };

  // Create interceptor for network interrupt
  applyNetworkInterruptInterceptorFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        FBLogger.info(`${response?.config?.method?.toUpperCase()} ${response?.config?.url} => ${response?.status} 👌`);
        return response;
      },
      (error) => {
        const RETRY_CODES = ['ECONNABORTED']; // For Server timeouts
        const RETRY_MESSAGES = ['Network Error']; // For lost connection
        if (
          Object.keys(error?.config).length &&
          (RETRY_CODES.includes(error?.code) || RETRY_MESSAGES.includes(error.message))
        ) {
          if (error?.message === RETRY_MESSAGES[0]) {
            FBLogger.warn(
              `client lost connection while attempting to ${error?.config?.method?.toUpperCase()} to ${
                error?.config?.url
              } 😥`,
              { errorContent: JSON.stringify(error, Object.getOwnPropertyNames(error)) },
            );
          }
          if (error?.codes === RETRY_CODES[0]) {
            FBLogger.warn(
              `timeout of ${error?.config?.timeout} ms exceeded for ${error?.config?.method?.toUpperCase()} to ${
                error?.config?.url
              } 😥`,
              { errorContent: JSON.stringify(error, Object.getOwnPropertyNames(error)) },
            );
          }
          let retryCount = error.config.retryCount || 0;
          if (retryCount === 1) {
            this.props.updateAPIRetryNotification();
          }
          if (retryCount < 2) {
            retryCount += 1;
            const chillout = new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 2000);
            });
            return chillout.then(() => {
              if (error?.config?.data) {
                return axiosInstance.request({ ...error.config, retryCount, data: JSON.parse(error.config.data) });
              }
              return axiosInstance.request({ ...error.config, retryCount });
            });
          }
          FBLogger.warn('Already retried 2 additional times. Failing due to timeout. ⛔️');
        } else {
          const warningStatuses = [400, 401, 403];
          if (warningStatuses.includes(error?.response?.status)) {
            FBLogger.warn(
              `${error?.config?.method?.toUpperCase()} ${error?.config?.url} => ${error?.response?.status} 😥`,
              { errorContent: JSON.stringify(error, Object.getOwnPropertyNames(error)) },
            );
          } else {
            FBLogger.error(
              `${error?.config?.method?.toUpperCase()} ${error?.config?.url} => ${error?.response?.status} ⛔️`,
              { errorContent: JSON.stringify(error, Object.getOwnPropertyNames(error)) },
            );
          }
        }
        return Promise.reject(error);
      },
    );
  };

  applyVersionInterceptorFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const currentAPIVersion = response?.headers?.[FB_API_VERSION];
        this.handleBrowserRefreshForAPIVersionMismatch(axiosInstance, currentAPIVersion);
        return response;
      },
      (error) => {
        const currentAPIVersion = error?.response?.headers?.[FB_API_VERSION];
        if (currentAPIVersion) {
          this.handleBrowserRefreshForAPIVersionMismatch(axiosInstance, currentAPIVersion);
        }
        return Promise.reject(error);
      },
    );
  };

  applyMaintenanceModeInterceptorFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const maintenanceHeader = response?.headers?.[MAINTENANCE_HEADER];
        if (maintenanceHeader && parseInt(maintenanceHeader, 10)) {
          this.enableMaintenanceMode();
        }
        return response;
      },
      (error) => {
        const maintenanceHeader = error?.response?.headers?.[MAINTENANCE_HEADER];
        if (maintenanceHeader && parseInt(maintenanceHeader, 10)) {
          this.enableMaintenanceMode();
        }
        return Promise.reject(error);
      },
    );
  };

  initialHealthCheck = () => {
    FoodbombAPI.get('/')
      .then((response) => {
        this.setState({ loadingInitialHealthCheck: false });
        if (response?.data?.[MAINTENANCE_FLAG]) {
          this.enableMaintenanceMode();
        }
      })
      .catch(() => {
        this.setState({ loadingInitialHealthCheck: false });
      });
  };

  periodicallyCheckHealthCheck = () => {
    const { maintenanceRefreshRate } = this.state;
    FoodbombAPI.get('/')
      .then((response) => {
        if (response.data === MAINTENANCE_FLAG) {
          throw new FBError('Maintenance mode detected');
        }
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          this.periodicallyCheckHealthCheck();
        }, maintenanceRefreshRate);
        this.props.updateMaintenanceRetryError(maintenanceRefreshRate);
      });
  };

  logout = () => {
    this.props.history.push('/logout');
  };

  logoutAndDisplayNotification = () => {
    this.props.createExpiredSessionNotification();
    this.logout();
  };

  catchExpiredJWTAndLogoutFor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        const tokenIsExpired = checkIfTokenExpired(config.headers.common.Authorization);
        if (this.props.hasToken && tokenIsExpired) {
          this.logoutAndDisplayNotification();
          return {
            ...config,
            cancelToken: axiosInstance.CancelToken((cancel) => cancel('Authorisation expired')),
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  };

  enableMaintenanceMode = () => {
    const { isMaintenanceMode, maintenanceRefreshRate } = this.state;
    const { updateMaintenanceRetryError } = this.props;
    if (!isMaintenanceMode) {
      setTimeout(() => {
        this.periodicallyCheckHealthCheck();
      }, maintenanceRefreshRate);
      updateMaintenanceRetryError(maintenanceRefreshRate);
    }
    this.setState({ isMaintenanceMode: true });
  };

  intercept401sAndRedirectFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          this.logoutAndDisplayNotification();
          return Promise.resolve();
        }
        if (error.response?.status === 503) {
          this.enableMaintenanceMode();
          return Promise.resolve();
        }
        if (axiosInstance.isCancel(error)) {
          // eslint-disable-next-line
          return new Promise(() => {});
        }
        return Promise.reject(error);
      },
    );
  };

  initialiseAxiosInstances = () => {
    [FoodbombAPI, DeliveryPreferencesAPI].forEach((instance) => {
      this.intercept401sAndRedirectFor(instance);
      this.applyVersionInterceptorFor(instance);
      this.applyNetworkInterruptInterceptorFor(instance);
    });

    this.applyMaintenanceModeInterceptorFor(FoodbombAPI);

    this.applyNetworkInterruptInterceptorFor(VenueIntegrationsAPI);

    // Suppliers API is unauthenticated
    [FoodbombAPI, DeliveryPreferencesAPI].forEach((instance) => {
      this.catchExpiredJWTAndLogoutFor(instance);
    });

    [FoodbombAPI, DeliveryPreferencesAPI, VenueIntegrationsAPI, ReportingAPI, UIStoreAPI].forEach((instance) => {
      this.interceptLogRequestsToDDFor(instance);
    });
  };

  handleShowNewVenueDialog = () => {
    this.setState({ showNewVenueDialog: true });
  };

  handleHideNewVenueDialog = () => {
    this.setState({ showNewVenueDialog: false });
  };

  initialiseDatadogListener = () => {
    const ddApplicationId = `${process.env.REACT_APP_DD_APPLICATION_ID}`;
    const ddClientToken = `${process.env.REACT_APP_DD_CLIENT_TOKEN}`;
    const ddSite = `${process.env.REACT_APP_DD_SITE}`;
    const appEnv = `${process.env.REACT_APP_ENVIRONMENT}`;
    const appVersion = `${process.env.REACT_APP_VERSION}`;
    const appName = `${process.env.REACT_APP_NAME}`;
    const appHost = window.location.origin;

    const isDatadogRumEnabled = `${process.env.REACT_APP_IS_DD_RUM_ENABLED}` === 'true';
    if (!isDatadogRumEnabled) {
      return;
    }

    datadogLogs.addLoggerGlobalContext('host', appHost);
    datadogRum.addRumGlobalContext('host', appHost);

    const datadogDefaultConfiguration = {
      clientToken: ddClientToken,
      site: ddSite,
      service: appName,
      env: appEnv,
      version: appVersion,
      silentMultipleInit: true,
    };

    datadogLogs.init({
      ...datadogDefaultConfiguration,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
    });

    datadogLogs.onReady(() => {
      datadogLogs.logger.info('Datadog is ready to collect logs');
      datadogRum.init({
        ...datadogDefaultConfiguration,
        applicationId: ddApplicationId,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        allowedTracingUrls: [FOODBOMB_API_BASE_URL, DELIVERY_PREFERENCES_API_BASE_URL, VENUE_INTEGRATIONS_API_BASE_URL],
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.onReady(() => {
        datadogLogs.logger.info('Datadog is ready to start recording sessions');
        datadogRum.startSessionReplayRecording();
      });
    });
  };

  interceptLogRequestsToDDFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const { message, context } = buildLogDetails(response);
        datadogLogs.logger.info(message, context);
        return response;
      },
      (error) => {
        if (error?.response) {
          const { message, context } = buildLogDetails(error.response);
          datadogLogs.logger.error(message, context);
        }
        return Promise.reject(error);
      },
    );
  };

  initialiseRaygunListener = () => {
    const { history, trackPageView } = this.props;
    history.listen((location) => {
      trackPageView(`${location.pathname}${location.search}`);
    });
  };

  initialiseSegmentTracking = () => {
    const { location, isStaff, venue } = this.props;

    if (location.pathname.includes('/home')) {
      if (isStaff) {
        trackSegmentAnalytics('View Dashboard', {
          isStaff,
          venue,
        });
      }

      if (venue?.venueType === PERSONAL_USE) {
        trackSegmentAnalytics('View Dashboard', {
          venueType: venue.venueType,
          venue,
        });
      }
    }
  };

  render() {
    const {
      hasToken,
      isVenueLoading,
      maintenanceBetaVersion,
      pageToRedirectTo,
      clearPageToRedirectTo,
      setBackShouldGoHome,
      navIsMinimised,
      toggleMinimiseNav,
      minimiseNav,
    } = this.props;
    const { isMaintenanceMode, loadingInitialHealthCheck, showNewVenueDialog } = this.state;
    const { venue } = this.props;

    const showMaintenanceScreen =
      process.env?.REACT_APP_MAINTENANCE_MODE === 'true' ||
      isMaintenanceMode ||
      (maintenanceBetaVersion !== undefined &&
        maintenanceBetaVersion === ACTIVE_BETA_FEATURES[MAINTENANCE_BETA].options.ON.id);

    if (showMaintenanceScreen) {
      return <Maintenance />;
    }

    return (
      <div ref={this.appRouterRef}>
        {loadingInitialHealthCheck ? (
          <FullWidthCSSLoadingSpinner loadingMessage="F-Bomb Loading..." />
        ) : (
          <React.Fragment>
            <Notifications />
            <React.Fragment>
              {hasToken ? (
                <Suspense fallback={<FullWidthCSSLoadingSpinner loadingMessage="F-Bomb Loading..." />}>
                  {venue ? (
                    <Switch>
                      <Route exact path="/accept-existing-invite">
                        <AcceptExistingAccountInvite />
                      </Route>
                      <Route path="/">
                        <App
                          pageToRedirectTo={pageToRedirectTo}
                          clearPageToRedirectTo={clearPageToRedirectTo}
                          setBackShouldGoHome={setBackShouldGoHome}
                          navIsMinimised={navIsMinimised}
                          toggleMinimiseNav={toggleMinimiseNav}
                          minimiseNav={minimiseNav}
                          showNewVenueDialog={showNewVenueDialog}
                          handleHideNewVenueDialog={this.handleHideNewVenueDialog}
                        />
                      </Route>
                    </Switch>
                  ) : (
                    <>
                      {isVenueLoading ? (
                        <FullWidthCSSLoadingSpinner loadingMessage="Loading venue data..." />
                      ) : (
                        <Switch>
                          <Route exact path="/logout">
                            <Logout />
                          </Route>
                          <Route exact path="/verify-invitation">
                            <VerifyInvitation />
                          </Route>
                          <Route exact path="/accept-existing-invite">
                            <AcceptExistingAccountInvite />
                          </Route>
                          <Route path="/">
                            <VenueSelection handleShowNewVenueDialog={this.handleShowNewVenueDialog} />
                          </Route>
                        </Switch>
                      )}
                    </>
                  )}
                </Suspense>
              ) : (
                <PublicWebsite />
              )}
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasToken: Boolean(state.auth.token),
  isStaff: Boolean(state.auth.isStaff),
  hasVenue: Boolean(state.currentVenue.venue),
  venue: state.currentVenue.venue,
  accountDetails: state.auth.account,
  isAuthLoading: state.auth.loading,
  isVenueLoading: state.currentVenue.loading,
  suppliersAPIVersion: state.versions.suppliersAPIVersion,
  foodbombAPIVersion: state.versions.foodbombAPIVersion,
  deliveryPreferencesAPIVersion: state.versions.deliveryPreferencesAPIVersion,
  maintenanceBetaVersion: state.currentVenue?.betaFeatures?.[MAINTENANCE_BETA],
});

const mapDispatchToProps = (dispatch) => ({
  attemptAutoSignIn: () => dispatch(actions.authCheckState()),
  onSetSuppliersAPIVersion: (suppliersAPIVersion) => dispatch(actions.setSuppliersAPIVersion(suppliersAPIVersion)),
  onSetFoodbombAPIVersion: (foodbombAPIVersion) => dispatch(actions.setFoodbombAPIVersion(foodbombAPIVersion)),
  onSetDeliveryPreferencesAPIVersion: (deliveryPreferencesAPIVersion) =>
    dispatch(actions.setDeliveryPreferencesAPIVersion(deliveryPreferencesAPIVersion)),
});

AppRouter.propTypes = {
  hasToken: PropTypes.bool,
  // TODO: replace all of these
  isStaff: PropTypes.bool,
  hasVenue: PropTypes.bool,
  venue: PropTypes.object,
  account: PropTypes.object,
  isAccountLoading: PropTypes.bool,
  isVenueLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  attemptAutoSignIn: PropTypes.func.isRequired,
  suppliersAPIVersion: PropTypes.string,
  onSetSuppliersAPIVersion: PropTypes.func.isRequired,
  foodbombAPIVersion: PropTypes.string,
  onSetFoodbombAPIVersion: PropTypes.func.isRequired,
  trackPageView: PropTypes.func.isRequired,
  createExpiredSessionNotification: PropTypes.func.isRequired,
  redirectToSignUp: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  updateMaintenanceRetryError: PropTypes.func.isRequired,
  maintenanceBetaVersion: PropTypes.number,
  deliveryPreferencesAPIVersion: PropTypes.string,
  onSetDeliveryPreferencesAPIVersion: PropTypes.func.isRequired,
  updateAPIRetryNotification: PropTypes.func.isRequired,
  pageToRedirectTo: PropTypes.string,
  clearPageToRedirectTo: PropTypes.func.isRequired,
  setBackShouldGoHome: PropTypes.func.isRequired,
  navIsMinimised: PropTypes.bool.isRequired,
  toggleMinimiseNav: PropTypes.func.isRequired,
  minimiseNav: PropTypes.func.isRequired,
};

export default withRaygun(
  withNotifications(withRedirectHelper(connect(mapStateToProps, mapDispatchToProps)(AppRouter))),
);
