import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import { Typography } from '../FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './FullWidthLoadingMessage.module.scss';

const fullWidthLoadingMessage = ({ loadingMessage, small = false, className }) => {
  const containerStyles = [styles.FullWidthLoadingMessageContainer, className];
  const progressStyles = [styles.CircularProgress];
  const textStyles = [styles.LoadingText];

  if (small) {
    containerStyles.push(styles.small);
    progressStyles.push(styles.small);
    textStyles.push(styles.small);
  }

  return (
    <div className={containerStyles.join(' ')}>
      <CircularProgress className={progressStyles.join(' ')} size={small ? 30 : 60} thickness={small ? 2 : 3} />
      <Typography type={small ? TYPOGRAPHY_TYPES.BODY : TYPOGRAPHY_TYPES.HEADING_L} className={textStyles.join(' ')}>
        {loadingMessage || 'Loading...'}
      </Typography>
    </div>
  );
};

fullWidthLoadingMessage.propTypes = {
  loadingMessage: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
};

export default fullWidthLoadingMessage;
