import { InputLabel } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './FormikSingleSelectField.module.scss';

export const SelectFieldStyles = {
  base: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    font: "400 16px 'Source Sans Pro'",
    borderRadius: '8px',
    padding: '4px',
    border: '1px solid #6e7370',
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    boxShadow: '0px 0px 8px #F7F7F7',
    border: '1px solid #6e7370',
    padding: '5px 4px',
    background: 'white',
    font: "400 16px 'Source Sans Pro'",
    color: '#485146',
    fontSize: '16px',
    lineHeight: '140%',
    borderRadius: '4px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    cursor: 'text',
    outline: isFocused ? '5px auto #00c65f' : 'none',
    ':hover': {
      border: '1px solid #6e7370',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#6e7370',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#6e7370',
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    font: "400 16px 'Source Sans Pro'",
    color: '#bbbbbb',
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    borderRadius: '4px',
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isSelected ? '#EDFFF6' : isFocused ? '#F7F7F7' : 'white',
    // eslint-disable-next-line no-nested-ternary
    color: isSelected ? '#006B33' : isFocused ? '#00C65F' : '#485146',
    cursor: 'pointer',
    ':active': {
      ...provided[':active'],
      backgroundColor: '#EDFFF6',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#edfff6',
    border: '1px solid #009447',
    borderRadius: '4px',
    color: '#009447',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    font: "400 16px 'Source Sans Pro'",
    color: '#009447',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#009447',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#009447',
      color: 'white',
    },
  }),
};

const defaultLoadingFunc = () => 'Loading...';

const handleDefaultOnChange = (selectedValue, selectedValueField, setTouched, touched, fieldName, setFieldValue) => {
  setTouched({ ...touched, [fieldName]: true });
  setFieldValue(fieldName, selectedValueField ? selectedValue[selectedValueField] : selectedValue.id);
};

const FormikSingleSelectField = ({
  value,
  isLoading,
  loadingMessageFunc,
  options,
  placeholder,
  className,
  fieldName,
  setTouched,
  setFieldValue,
  customOnChange,
  label,
  touched,
  errors,
  hideErrors,
  isDisabled,
  selectedValueField,
  isAsync,
  cacheOptions,
  loadOptions,
  defaultOptions,
  ...props
}) => (
  <div
    className={
      isDisabled ? [styles.FormikSingleSelectField, styles.disabled].join(' ') : styles.FormikSingleSelectField
    }
  >
    {label ? (
      <InputLabel
        className={
          touched && errors && touched[fieldName] && errors[fieldName]
            ? [styles.InputLabel, styles.error].join(' ')
            : styles.InputLabel
        }
      >
        {label}
      </InputLabel>
    ) : null}
    <React.Fragment>
      {isAsync ? (
        <AsyncSelect
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          placeholder={placeholder || 'Select one'}
          className={[className, styles.Input].join(' ')}
          styles={SelectFieldStyles}
          onChange={
            customOnChange
              ? (selectedValue) => customOnChange(selectedValue)
              : (selectedValue) =>
                  handleDefaultOnChange(
                    selectedValue,
                    selectedValueField,
                    setTouched,
                    touched,
                    fieldName,
                    setFieldValue,
                  )
          }
          isDisabled={isDisabled}
          value={value}
          {...props}
        />
      ) : (
        <Select
          value={value}
          isLoading={isLoading}
          isDisabled={isDisabled}
          loadingMessage={loadingMessageFunc || defaultLoadingFunc}
          options={options}
          placeholder={placeholder || 'Select one'}
          className={[className, styles.Input].join(' ')}
          styles={SelectFieldStyles}
          onChange={
            customOnChange
              ? (selectedValue) => customOnChange(selectedValue)
              : (selectedValue) =>
                  handleDefaultOnChange(
                    selectedValue,
                    selectedValueField,
                    setTouched,
                    touched,
                    fieldName,
                    setFieldValue,
                  )
          }
          {...props}
        />
      )}
    </React.Fragment>
    {errors && !hideErrors ? (
      <div className={styles.ErrorMessageContainer}>
        <ErrorMessage name={fieldName} />
      </div>
    ) : null}
  </div>
);

FormikSingleSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array, PropTypes.number]),
  isAsync: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessageFunc: PropTypes.func,
  options: PropTypes.array,
  cacheOptions: PropTypes.bool,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  fieldName: PropTypes.string.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  hideErrors: PropTypes.bool,
  setTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customOnChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  selectedValueField: PropTypes.string,
};

export default FormikSingleSelectField;
