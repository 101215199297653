import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';

const initialState = {
  loading: false,
  paymentMethods: [],
  selectedPaymentMethod: {},
};

const getPaymentMethodsStart = (state) =>
  updateObject(state, { error: undefined, loading: true, paymentMethods: [], selectedPaymentMethod: {} });

const getPaymentMethodsSuccess = (state, action) =>
  updateObject(state, {
    paymentMethods: action.payload.paymentMethods,
    selectedPaymentMethod: action.payload.selectedPaymentMethod,
    error: undefined,
    loading: false,
  });

const getPaymentMethodsFail = (state, action) =>
  updateObject(state, {
    error: action.payload.error,
    loading: false,
    paymentMethods: [],
    selectedPaymentMethod: {},
  });

const setPaymentMethods = (state, action) =>
  updateObject(state, {
    error: undefined,
    loading: false,
    paymentMethods: action.payload.paymentMethods,
    selectedPaymentMethod: action.payload.selectedPaymentMethod,
  });

const clearPaymentMethods = () => initialState;

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_METHODS_START:
      return getPaymentMethodsStart(state, action);
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return getPaymentMethodsSuccess(state, action);
    case actionTypes.GET_PAYMENT_METHODS_FAIL:
      return getPaymentMethodsFail(state, action);
    case actionTypes.SET_PAYMENT_METHODS:
      return setPaymentMethods(state, action);
    case actionTypes.CLEAR_PAYMENT_METHODS:
      return clearPaymentMethods(state, action);
    default:
      return state || initialState;
  }
};

export default reducer;
