import PropTypes from 'prop-types';
import { Error, InfoOutlined, Warning, CheckCircle } from '@material-ui/icons';
import { NOTIFICATION_MESSAGE_TYPES } from '../constants';

const NotificationIcon = ({ type, className }) => {
  const Icons = {
    [NOTIFICATION_MESSAGE_TYPES.ERROR]: <Error className={className} />,
    [NOTIFICATION_MESSAGE_TYPES.INFO]: <InfoOutlined className={className} />,
    [NOTIFICATION_MESSAGE_TYPES.WARNING]: <Warning className={className} />,
    [NOTIFICATION_MESSAGE_TYPES.SUCCESS]: <CheckCircle className={className} />,
  };
  return Icons[type];
};

NotificationIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_TYPES)).isRequired,
  className: PropTypes.string,
};

export default NotificationIcon;
