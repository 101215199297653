import { INTEGRATIONS, INTEGRATION_QUERY_KEY } from './Constants';
import { ValidationError, DataMismatchError } from '../exceptions';
import FBLogger from './FBLogger';

export const localStorageGetItem = (key) => {
  try {
    return window.localStorage.getItem(key);
  } catch (exception) {
    return null;
  }
};

export const sessionStorageGetItem = (key) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (exception) {
    return null;
  }
};

export const localStorageSetItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (exception) {
    // nothing to do
  }
};

export const sessionStorageSetItem = (key, value) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (exception) {
    // nothing to do
  }
};

export const sessionStorageRemoveItem = (key) => {
  try {
    window.sessionStorage.removeItem(key);
  } catch (exception) {
    // nothing to do
  }
};

export const localStorageRemoveItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (exception) {
    // nothing to do
  }
};

export const saveIntegrationValuesToSessionStorage = (typeOfIntegration, integrationParamValues, sendDatadogError) => {
  if (!Object.keys(INTEGRATIONS).includes(typeOfIntegration)) {
    sendDatadogError('Unsupported type of integration', {
      error: new DataMismatchError(
        'Expected a supported integration type',
        `One of ${Object.keys(INTEGRATIONS)}`,
        typeOfIntegration,
      ),
      location: 'App Router',
    });
    return;
  }

  try {
    INTEGRATIONS[typeOfIntegration].forEach((requiredIntegrationParam) => {
      if (!integrationParamValues[requiredIntegrationParam])
        throw new ValidationError(
          `Missing required integration key "${requiredIntegrationParam}" for ${typeOfIntegration} integration`,
        );
      sessionStorageSetItem(requiredIntegrationParam, integrationParamValues[requiredIntegrationParam]);
      FBLogger.info(
        `Saving "${requiredIntegrationParam}" for "${typeOfIntegration}" as "${integrationParamValues[requiredIntegrationParam]}"`,
      );
    });
    sessionStorageSetItem(INTEGRATION_QUERY_KEY, typeOfIntegration);
  } catch (e) {
    sendDatadogError('Invalid integration ', { error: e, location: 'App Router' });
  }
};

export const removeIntegrationValuesFromSessionStorage = (typeOfIntegration) => {
  INTEGRATIONS[typeOfIntegration].forEach((integrationParam) => {
    sessionStorageRemoveItem(integrationParam);
  });
  sessionStorageRemoveItem(INTEGRATION_QUERY_KEY);
};
