import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import styles from './Chip.module.scss';

/**
 * # Chip
 * Simple Foodbomb Chip Component
 *
 * ## Modifiers
 * ### Sizes
 * ```
 * ['small', 'medium']
 * ```
 *
 * ### Clickable
 * ```
 * [true, false]
 * ```
 *
 * ### Icon
 * ```
 * element
 * ```
 *
 * ### Icon
 * ```
 * element
 * ```
 *
 * ### Avatar
 * ```
 * element
 * ```
 * ## PropTypes:
 * ```js
 * FBChip.propTypes = {
 *   label: PropTypes.any,
 *   onDelete: PropTypes.func,
 *   deleteIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
 *   className: PropTypes.string,
 * };
 * ```
 *
 */
const FBChip = ({ onDelete, label, className, deleteIcon, onClick, ...props }) => (
  <Chip
    label={label}
    onDelete={onDelete}
    deleteIcon={deleteIcon || <HighlightOffOutlinedIcon />}
    className={onClick ? [styles.Chip, styles.clickable, className].join(' ') : [styles.Chip, className].join(' ')}
    classes={{ deleteIcon: styles.ChipIcon }}
    onClick={onClick}
    {...props}
  />
);

FBChip.propTypes = {
  label: PropTypes.any,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
  deleteIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default FBChip;
