import PropTypes from 'prop-types';

/**
 * # Emoji
 * Simple Foodbomb Emoji Wrapper Component
 *
 * ## PropTypes:
 * ```js
 *  FBButton.propTypes = {
 *  content: PropTypes.string,
 *  label: PropTypes.string,
 *  className: PropTypes.string,
 *  };
 * ```
 *
 */

const Emoji = ({ content, label, className }) => (
  <span role="img" aria-label={label || 'emoji'} className={className}>
    {content}
  </span>
);

Emoji.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default Emoji;
