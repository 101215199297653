import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullWidthLoadingMessage from '../UI/FullWidthLoadingMessage/FullWidthLoadingMessage';

type ExternalRouteProps = {
  route: string;
};

const ExternalRoute: FC<ExternalRouteProps> = ({ route }) => {
  useEffect(() => {
    window.location.href = route;
  }, [route]);

  return <FullWidthLoadingMessage loadingMessage="Redirecting..." />;
};

ExternalRoute.propTypes = {
  route: PropTypes.string.isRequired,
};

export default ExternalRoute;
