import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';

import styles from './MobileFiltersDrawer.module.scss';

/**
 * # Mobile Filters Drawer
 * Foodbomb Drawer for displaying content on mobile
 *
 * ## PropTypes:
 * ```js
 * MobileFiltersDrawer.propTypes = {
 *   isOpen: PropTypes.bool,
 *   onClose: PropTypes.func.isRequired,
 *   className: PropTypes.string,
 *   classes: PropTypes.shape({
 *     root: PropTypes.string,
 *     paper: PropTypes.string,
 *   }),
 *   children: PropTypes.node,
 * };
 * ```
 */

const MobileFiltersDrawer = ({ isOpen, onClose, className, classes, children, ...props }) => {
  const drawerClasses = [styles.MobileFiltersDrawer];
  drawerClasses.push(className);

  const paperClasses = [styles.MobileFiltersDrawer__paper];
  const rootClasses = [];

  if (classes) {
    paperClasses.push(classes.paper);
    rootClasses.push(classes.root);
  }

  return (
    <Drawer
      open={isOpen}
      anchor="left"
      onClose={onClose}
      className={drawerClasses.join(' ')}
      classes={{
        root: rootClasses.join(' '),
        paper: paperClasses.join(' '),
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
};

MobileFiltersDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    paper: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default MobileFiltersDrawer;
