import PropTypes from 'prop-types';
import { Badge } from '@material-ui/core';

import styles from './Badge.module.scss';

/**
 * # Badge
 * Simple Foodbomb Badge Component
 *
 * ## Modifiers
 * ### Variants
 * ```
 * standalone (bool)
 * ```
 *
 * ## PropTypes:
 * ```js
 * Badge.propTypes = {
 *   children: PropTypes.node,
 *   standalone: PropTypes.bool,
 *   className: PropTypes.string,
 * };
 * ```
 */
const FBBadge = ({ standalone, children, className, ...props }) => {
  const rootClasses = [];
  const badgeClasses = [styles.Badge];

  if (standalone) {
    rootClasses.push(styles.Root__standalone);
  }
  rootClasses.push(className);

  return (
    <Badge classes={{ root: rootClasses.join(' '), badge: badgeClasses.join(' ') }} {...props}>
      {children}
    </Badge>
  );
};

FBBadge.propTypes = {
  children: PropTypes.node,
  standalone: PropTypes.bool,
  className: PropTypes.string,
};

export default FBBadge;
