import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  GROUP_BY_QUERY_STRINGS,
  PRODUCT_SEARCH_QUERY_STRINGS,
  PRODUCT_SORT_BY_QUERY_STRINGS,
  SEARCH_PAGE_VARIANTS,
  SUPPLIER_SORT_BY_QUERY_STRINGS,
  VIEW_TYPES,
} from '../../utils/Constants';
import { getURLParameter } from '../../utils/getURLParameter';
import useQuery from '../useQuery/useQuery';
import { GroupByMethod, PageVariant, ProductSortByMethod, SupplierSortByMethod } from './ProductSearchTypes';

const useProductSearchQuery = (): {
  searchString: string;
  groupBy: GroupByMethod;
  productSortBy: ProductSortByMethod;
  supplierSortBy: SupplierSortByMethod;
  currentViewType: string;
  isFilteringAgainstFavs: boolean;
  isFilteringAgainstBestBuys: boolean;
  isShowingSupplierReviewsModal: boolean;
  isShowingLeaveASupplierReviewModal: boolean;
  isShowingProductIssueModal: boolean;
  supplierIdsToFilterAgainst: number[];
  supplierIdsToFilterAgainstAsJoinedString: string;
  categoryIdsToFilterAgainst: number[];
  categoryIdsToFilterAgainstAsJoinedString: string;
  targetSupplierId: number | undefined;
  targetCategoryId: number | undefined;
  pageVariant: PageVariant;
  updateQueryParams: (
    fieldToUpdate: string,
    newValue: string | number[] | boolean,
    scrollToTop?: boolean,
    clearSearch?: boolean,
  ) => void;
  updateSearchQuery: (query: string) => void;
  toggleFavsFilter: () => void;
  toggleBestBuysFilter: () => void;
  setGroupBy: (method: GroupByMethod) => void;
  setProductSortBy: (method: ProductSortByMethod) => void;
  setSupplierSortBy: (method: SupplierSortByMethod) => void;
  setViewType: (viewTtype: string) => void;
  toggleSupplierFilter: (supplierIdToFilter: number) => void;
  toggleCategoryFilter: (categoryIdToFilter: number) => void;
  clearAllFilters: () => void;
  closeReviewModalAndOpenSupplierReviews: () => void;
} => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  // Note these fields are only avilable within components that use router params!
  const { supplierId, categoryId } = useParams<{ categoryId?: string; supplierId?: string }>();

  let pageVariant = SEARCH_PAGE_VARIANTS.GLOBAL;

  if (pathname.includes(SEARCH_PAGE_VARIANTS.SUPPLIER) && getURLParameter(location.pathname, 'suppliers')) {
    pageVariant = SEARCH_PAGE_VARIANTS.SUPPLIER;
  } else if (pathname.includes(SEARCH_PAGE_VARIANTS.CATEGORY) && getURLParameter(location.pathname, 'categories')) {
    pageVariant = SEARCH_PAGE_VARIANTS.CATEGORY;
  } else if (pathname.includes(SEARCH_PAGE_VARIANTS.FAVOURITES)) {
    pageVariant = SEARCH_PAGE_VARIANTS.FAVOURITES;
  }

  const getDefaultViewType = (): string => {
    const potentiallySavedViewType: string | null = localStorage.getItem(PRODUCT_SEARCH_QUERY_STRINGS.VIEW_TYPE_QS);
    if (potentiallySavedViewType) {
      return potentiallySavedViewType;
    }
    return VIEW_TYPES.LIST;
  };

  const getDefaultGroupByMethodForPageVariant = (variant: PageVariant): GroupByMethod => {
    const potentialSavedResult: ProductSortByMethod | null = localStorage.getItem(
      `${variant === SEARCH_PAGE_VARIANTS.GLOBAL ? SEARCH_PAGE_VARIANTS.CATEGORY : variant}-${
        PRODUCT_SEARCH_QUERY_STRINGS.GROUP_BY_QS
      }`,
    );

    switch (variant) {
      case SEARCH_PAGE_VARIANTS.FAVOURITES:
        return potentialSavedResult || GROUP_BY_QUERY_STRINGS.SUPPLIER.QS;
      case SEARCH_PAGE_VARIANTS.SUPPLIER:
        return GROUP_BY_QUERY_STRINGS.PRODUCT.QS;
      case SEARCH_PAGE_VARIANTS.CATEGORY:
        return potentialSavedResult || GROUP_BY_QUERY_STRINGS.SUPPLIER.QS;
      default:
        return potentialSavedResult || GROUP_BY_QUERY_STRINGS.SUPPLIER.QS;
    }
  };

  const getDefaultProductSortByMethodForPageVariant = (
    variant: PageVariant,
    hasSearchQuery: boolean,
  ): ProductSortByMethod => {
    // Attempt to find a preference saved in local storage
    const potentialSavedResult: ProductSortByMethod | null = localStorage.getItem(
      `${variant === SEARCH_PAGE_VARIANTS.GLOBAL ? SEARCH_PAGE_VARIANTS.CATEGORY : variant}-${
        PRODUCT_SEARCH_QUERY_STRINGS.PRODUCT_SORT_QS
      }`,
    );
    if (potentialSavedResult) return potentialSavedResult;

    // Otherwise fallback to default logic
    switch (variant) {
      case SEARCH_PAGE_VARIANTS.FAVOURITES:
        return PRODUCT_SORT_BY_QUERY_STRINGS.NAME_A_TO_Z.QS;
      case SEARCH_PAGE_VARIANTS.CATEGORY:
        return hasSearchQuery
          ? PRODUCT_SORT_BY_QUERY_STRINGS.RELEVANCE.QS
          : PRODUCT_SORT_BY_QUERY_STRINGS.PRICE_LOW_TO_HIGH.QS;
      case SEARCH_PAGE_VARIANTS.SUPPLIER:
        return hasSearchQuery
          ? PRODUCT_SORT_BY_QUERY_STRINGS.RELEVANCE.QS
          : PRODUCT_SORT_BY_QUERY_STRINGS.NAME_A_TO_Z.QS;
      default:
        // Global & Fallback
        return hasSearchQuery
          ? PRODUCT_SORT_BY_QUERY_STRINGS.RELEVANCE.QS
          : PRODUCT_SORT_BY_QUERY_STRINGS.PRICE_LOW_TO_HIGH.QS;
    }
  };

  const getDefaultSupplierSortByMethodForPageVariant = (
    variant: PageVariant,
    hasSearchQuery: boolean,
  ): SupplierSortByMethod => {
    // Attempt to find a preference saved in local storage
    const potentialSavedResult: SupplierSortByMethod | null = localStorage.getItem(
      `${variant === SEARCH_PAGE_VARIANTS.GLOBAL ? SEARCH_PAGE_VARIANTS.CATEGORY : variant}-${
        PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIER_SORT_QS
      }`,
    );
    if (potentialSavedResult) return potentialSavedResult;

    // Otherwise fallback to default logic
    switch (variant) {
      case SEARCH_PAGE_VARIANTS.FAVOURITES:
        return SUPPLIER_SORT_BY_QUERY_STRINGS.NAME_A_TO_Z.QS;
      case SEARCH_PAGE_VARIANTS.CATEGORY:
        return hasSearchQuery
          ? SUPPLIER_SORT_BY_QUERY_STRINGS.RELEVANCE.QS
          : SUPPLIER_SORT_BY_QUERY_STRINGS.NUMBER_OF_PRODUCTS.QS;
      default:
        return hasSearchQuery
          ? SUPPLIER_SORT_BY_QUERY_STRINGS.RELEVANCE.QS
          : SUPPLIER_SORT_BY_QUERY_STRINGS.NUMBER_OF_PRODUCTS.QS;
    }
  };

  const targetCategoryId = categoryId ? parseInt(categoryId, 10) : undefined;
  const targetSupplierId = supplierId ? parseInt(supplierId, 10) : undefined;

  // Query Strings
  const searchString: string = query.get(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS) || '';
  const groupBy: GroupByMethod =
    query.get(PRODUCT_SEARCH_QUERY_STRINGS.GROUP_BY_QS) || getDefaultGroupByMethodForPageVariant(pageVariant);
  const productSortBy: ProductSortByMethod =
    query.get(PRODUCT_SEARCH_QUERY_STRINGS.PRODUCT_SORT_QS) ||
    getDefaultProductSortByMethodForPageVariant(pageVariant, Boolean(searchString));
  const supplierSortBy: SupplierSortByMethod =
    query.get(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIER_SORT_QS) ||
    getDefaultSupplierSortByMethodForPageVariant(pageVariant, Boolean(searchString));
  const currentViewType: string = query.get(PRODUCT_SEARCH_QUERY_STRINGS.VIEW_TYPE_QS) || getDefaultViewType();
  const isFilteringAgainstFavs: boolean = query.has(PRODUCT_SEARCH_QUERY_STRINGS.FAVOURITES_QS);
  const isFilteringAgainstBestBuys: boolean = query.has(PRODUCT_SEARCH_QUERY_STRINGS.BEST_BUYS_QS);
  const isShowingSupplierReviewsModal: boolean = query.has(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIER_REVIEWS_QS);
  const isShowingProductIssueModal: boolean = query.has(PRODUCT_SEARCH_QUERY_STRINGS.PRODUCT_ISSUE_MODAL);
  const isShowingLeaveASupplierReviewModal: boolean = query.has(PRODUCT_SEARCH_QUERY_STRINGS.LEAVE_SUPPLIER_REVIEW_QS);
  const supplierIdsToFilterAgainst: number[] = query
    .getAll(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIERS_QS)
    .map((idAsString) => parseInt(idAsString, 10));
  // Note: the above array can cause infinite rerenders because the object is always different,
  // however a string is shallow compared in react
  const supplierIdsToFilterAgainstAsJoinedString: string = supplierIdsToFilterAgainst.join(',');
  const categoryIdsToFilterAgainst: number[] = query
    .getAll(PRODUCT_SEARCH_QUERY_STRINGS.CATEGORIES_QS)
    .map((idAsString) => parseInt(idAsString, 10));
  // Note: the above array can cause infinite rerenders because the object is always different,
  // however a string is shallow compared in react
  const categoryIdsToFilterAgainstAsJoinedString: string = categoryIdsToFilterAgainst.join(',');

  const valueIsFalseyOrEmptyArray = (value: any): boolean =>
    (!Array.isArray(value) && !value) || (Array.isArray(value) && !value.length);

  const updateQueryParams = (
    fieldToUpdate: string,
    newValue: string | number[] | boolean,
    scrollToTop: boolean = true,
    clearSearch: boolean = false,
  ): void => {
    const searchParams = new URLSearchParams(query.toString());
    searchParams.delete(fieldToUpdate);

    if (clearSearch) {
      searchParams.delete(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS);
    }

    if (!valueIsFalseyOrEmptyArray(newValue)) {
      if (Array.isArray(newValue)) {
        newValue.forEach((val) => {
          searchParams.append(fieldToUpdate, `${val}`);
        });
      } else {
        searchParams.set(fieldToUpdate, `${newValue}`);
      }
    }

    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };

  /*
   * Anytime we're updating the search query, we also clear any current categories,
   * Hence theyre combined in this method as to only cause a single re-render
   */
  const updateSearchQuery = (searchQuery: string): void => {
    const searchParams = new URLSearchParams(query.toString());
    searchParams.delete(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS);
    searchParams.delete(PRODUCT_SEARCH_QUERY_STRINGS.CATEGORIES_QS);

    if (searchQuery) {
      searchParams.set(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS, searchQuery);
    }

    window.scrollTo(0, 0);
    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };

  const clearAllFilters = (): void => {
    const searchParams = new URLSearchParams(query.toString());
    const searchQuery = searchParams.get(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS) || '';
    const newSearchParams = new URLSearchParams();
    if (searchQuery) {
      newSearchParams.append(PRODUCT_SEARCH_QUERY_STRINGS.SEARCH_QS, searchQuery);
    }

    window.scrollTo(0, 0);
    history.replace({
      pathname,
      search: newSearchParams.toString(),
    });
  };

  const closeReviewModalAndOpenSupplierReviews = (): void => {
    const searchParams = new URLSearchParams(query.toString());
    searchParams.delete(PRODUCT_SEARCH_QUERY_STRINGS.LEAVE_SUPPLIER_REVIEW_QS);
    searchParams.set(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIER_REVIEWS_QS, 'true');

    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };

  const toggleFavsFilter = (): void => {
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.FAVOURITES_QS, !isFilteringAgainstFavs);
  };

  const toggleBestBuysFilter = (): void => {
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.BEST_BUYS_QS, !isFilteringAgainstBestBuys);
  };

  const setGroupBy = (method: GroupByMethod): void => {
    // Supplier page must always be grouped by supplier
    if (pageVariant === SEARCH_PAGE_VARIANTS.SUPPLIER) {
      updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.GROUP_BY_QS, GROUP_BY_QUERY_STRINGS.PRODUCT.QS);
    } else {
      updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.GROUP_BY_QS, method);
    }
  };

  const setProductSortBy = (method: ProductSortByMethod): void => {
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.PRODUCT_SORT_QS, method);
  };

  const setSupplierSortBy = (method: SupplierSortByMethod): void => {
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIER_SORT_QS, method);
  };

  const setViewType = (viewType: string): void => {
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.VIEW_TYPE_QS, viewType);
  };

  const toggleSupplierFilter = (supplierIdToFilter: number): void => {
    let updatedSupplierIds = [...supplierIdsToFilterAgainst];
    if (updatedSupplierIds.includes(supplierIdToFilter)) {
      updatedSupplierIds = updatedSupplierIds.filter((supId) => supId !== supplierIdToFilter);
    } else {
      updatedSupplierIds.push(supplierIdToFilter);
    }
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.SUPPLIERS_QS, updatedSupplierIds);
  };

  const toggleCategoryFilter = (categoryIdToFilter: number): void => {
    let updatedCategoryIds = [...categoryIdsToFilterAgainst];
    if (updatedCategoryIds.includes(categoryIdToFilter)) {
      updatedCategoryIds = updatedCategoryIds.filter((catId) => catId !== categoryIdToFilter);
    } else {
      updatedCategoryIds.push(categoryIdToFilter);
    }
    updateQueryParams(PRODUCT_SEARCH_QUERY_STRINGS.CATEGORIES_QS, updatedCategoryIds);
  };

  return {
    searchString,
    groupBy,
    productSortBy,
    supplierSortBy,
    currentViewType,
    isFilteringAgainstFavs,
    isFilteringAgainstBestBuys,
    isShowingSupplierReviewsModal,
    isShowingLeaveASupplierReviewModal,
    isShowingProductIssueModal,
    supplierIdsToFilterAgainst,
    supplierIdsToFilterAgainstAsJoinedString,
    categoryIdsToFilterAgainst,
    categoryIdsToFilterAgainstAsJoinedString,
    targetSupplierId,
    targetCategoryId,
    updateQueryParams,
    pageVariant,
    toggleFavsFilter,
    toggleBestBuysFilter,
    setGroupBy,
    setProductSortBy,
    setSupplierSortBy,
    setViewType,
    toggleSupplierFilter,
    toggleCategoryFilter,
    updateSearchQuery,
    clearAllFilters,
    closeReviewModalAndOpenSupplierReviews,
  };
};

export default useProductSearchQuery;
