import FBError from './FBError';

class DataMismatchError extends FBError {
  constructor(message, expectedValue, currentValue) {
    super(`${message}. Expected "${expectedValue}". Got: "${currentValue}"`);
    this.currentValue = currentValue;
    this.expectedValue = expectedValue;
  }
}

export default DataMismatchError;
