import PropTypes from 'prop-types';
import { FC } from 'react';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { CustomerType } from '../../../utils/Presenters/PresentVenue/PresentVenue';
import { Typography } from '../../UI/FB';
import styles from './CustomerTypeForm.module.scss';

type CustomerTypeFormProps = {
  handleSelectCustomerType: (customerType: CustomerType) => void;
  smallerButtonStylingForAddVenue?: boolean;
};

const CustomerTypeForm: FC<CustomerTypeFormProps> = ({ handleSelectCustomerType, smallerButtonStylingForAddVenue }) => (
  <div
    className={
      smallerButtonStylingForAddVenue
        ? styles.CustomerType__ButtonContainer__addVenue
        : styles.CustomerType__ButtonContainer
    }
  >
    <button type="button" onClick={() => handleSelectCustomerType(CustomerType.venue)} className={styles.Type__Button}>
      <Typography
        className={smallerButtonStylingForAddVenue ? styles.Type__titleOverride : styles.Type__title}
        type={TYPOGRAPHY_TYPES.HEADING_L}
      >
        I&apos;m Ordering For My Business
      </Typography>
      <Typography
        className={smallerButtonStylingForAddVenue ? styles.Type__subtitleOverride : styles.Type__subtitle}
        type={TYPOGRAPHY_TYPES.BODY}
      >
        I want to order wholesale produce for a cafe, restaurant, caterer, school etc.
      </Typography>
    </button>
    <button
      type="button"
      onClick={() => handleSelectCustomerType(CustomerType.consumer)}
      className={styles.Type__Button}
    >
      <Typography
        className={smallerButtonStylingForAddVenue ? styles.Type__titleOverride : styles.Type__title}
        type={TYPOGRAPHY_TYPES.HEADING_L}
      >
        I&apos;m Ordering for myself
      </Typography>
      <Typography
        className={smallerButtonStylingForAddVenue ? styles.Type__subtitleOverride : styles.Type__subtitle}
        type={TYPOGRAPHY_TYPES.BODY}
      >
        I want to get wholesale produce delivered to my home for personal use.
      </Typography>
    </button>
  </div>
);

CustomerTypeForm.propTypes = {
  handleSelectCustomerType: PropTypes.func.isRequired,
  smallerButtonStylingForAddVenue: PropTypes.bool,
};

export default CustomerTypeForm;
