import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import styles from './BookmarkButton.module.scss';

/**
 * # Bookmark Button
 * Simple Foodbomb Button with Heart Component
 *
 * ## Modifiers
 *
 * ### IsBookmarked
 * ```
 * [true, false]
 * ```
 *
 * ## PropTypes:
 * ```js
 * BookmarkButton.propTypes = {
 *    onClick: PropTypes.func,
 *    isBookmarked: PropTypes.bool,
 *    className: PropTypes.string,
 *  };
 * ```
 */

const BookmarkButton = ({ onClick, isBookmarked, className, ...props }) => {
  const ButtonStyles = [styles.Button];
  if (isBookmarked) {
    ButtonStyles.push(styles.clicked);
  }
  if (!onClick) {
    ButtonStyles.push(styles.inactive);
  }
  ButtonStyles.push(className);

  return (
    <IconButton onClick={onClick} className={ButtonStyles.join(' ')} aria-label="delete" {...props}>
      {isBookmarked ? <BookmarkIcon className={styles.Icon} /> : <BookmarkBorderIcon className={styles.Icon} />}
    </IconButton>
  );
};

BookmarkButton.propTypes = {
  onClick: PropTypes.func,
  isBookmarked: PropTypes.bool,
  className: PropTypes.string,
};

export default BookmarkButton;
