import { FC, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery/useQuery';
import { usePageNavigation } from '../../stores/PageNavigationStore/PageNavigationStore';
import { CURRENTLY_SELECTED_VENUE_ID_KEY } from '../../utils/Constants';
import { urlHasSupplierOrCategory } from '../../utils/getURLParameter';
import { sessionStorageSetItem } from '../../utils/StorageUtils';

const RouteRedirector: FC = () => {
  const location = useLocation();
  const [, { setPageToRedirectTo, setBackShouldGoHome }] = usePageNavigation();
  const query = useQuery();

  useEffect(() => {
    const pageName = `${location.pathname}${location.search}`;
    const supplierOrCategoryPage = urlHasSupplierOrCategory(pageName);

    if (supplierOrCategoryPage) {
      setBackShouldGoHome(true);
    }
    setPageToRedirectTo(pageName);
  }, [location.pathname, location.search, setPageToRedirectTo, setBackShouldGoHome]);

  // We want to set this to automatically log in to the venue if we find a suffixed venueId not directly after the base url
  // i.e http://localhost:8001/orders/2123123?showProductIssueModal=true&venueId=123123
  useEffect(() => {
    const venueId = query.get('venueId');
    if (venueId) {
      sessionStorageSetItem(CURRENTLY_SELECTED_VENUE_ID_KEY, venueId);
    }
  }, [query]);

  return <Redirect to="/login" />;
};

export default RouteRedirector;
