import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PropertyRequiredError } from '../../exceptions';
import {
  AMAKA_XERO_INTEGRATION_ENDPOINT,
  AMAKA_MYOB_INTEGRATION_ENDPOINT,
  BOOK_A_MEETING_FOR_COLLECTIVE_LINK,
  BOOK_A_MEETING_WITH_SALES,
  HELP_CENTRE_LINKS,
} from '../../utils/Constants';
import useEventTracking, { trackSegmentAnalytics } from '../useEventTracking/useEventTracking';

const useRedirect = (): {
  // Web Flow Links
  redirectToAboutPage: () => void;
  redirectToFAQPage: () => void;
  redirectToCareersPage: () => void;
  redirectToContactPage: () => void;
  redirectToBlog: () => void;
  redirectToTermsPage: () => void;
  redirectToPrivacyPage: () => void;
  redirectToLoggedOutHomePage: () => void;
  redirectToLoggedOutFeaturesPage: () => void;
  redirectToForSuppliersPage: () => void;
  // App Links
  redirectToFavourites: () => void;
  redirectToFavouritesWithQuery: (query: string) => void;
  redirectToViewOrders: () => void;
  redirectToViewStandingOrders: () => void;
  redirectToRegisterAndAcceptInvite: (uuid: string, venueId: number, email: string) => void;
  redirectToExistingAccountInvite: (uuid: string, venueId: number) => void;
  redirectToViewOrder: (orderId: number) => void;
  redirectToViewStandingOrder: (standingOrderId: number) => void;
  redirectToReports: () => void;
  redirectToCategory: (categoryId: number) => void;
  redirectToCheckout: () => void;
  redirectToSuppliersDirectory: () => void;
  redirectToSuppliersDirectoryWithBookmarkedFilter: () => void;
  redirectToCategories: () => void;
  redirectToBestBuys: () => void;
  redirectToCleanProductSearch: (searchString: string) => void;
  redirectToSearch: () => void;
  redirectToRootCategory: (categoryId: number) => void;
  redirectToShowAllProductsForSuppliers: (supplierIds: number[]) => void;
  redirectToSupplier: (supplierId: number) => void;
  redirectToCategoryWithSearch: (categoryId: number, searchString: string) => void;
  redirectToSupplierWithSearch: (supplierId: number, searchString: string) => void;
  redirectToOrderConfirmation: () => void;
  redirectToLogin: () => void;
  redirectToSignUp: () => void;
  redirectToHome: () => void;
  redirectToHomeAfterAddingVenue: () => void;
  redirectToZip: () => void;
  redirectToZipSignUp: () => void;
  redirectToFinstro: () => void;
  redirectToAccountPaymentMethodFinstroInfo: () => void;
  redirectToFinstroRegister: (venueId: number) => void;
  redirectToFBTradeRegister: () => void;
  redirectToAccount: () => void;
  redirectToAccountPaymentMethod: () => void;
  redirectToAccountPaymentMethodFinstroSuccess: () => void;
  redirectToAccountPaymentMethodWithQuery: (query: string) => void;
  redirectToPaymentMethodsZip: () => void;
  redirectToPaymentMethodsBombPay: () => void;
  redirectToAccountIntegrations: () => void;
  redirectToAccountVenueDetails: (edit?: boolean) => void;
  redirectToCategoryForSupplier: (supplierId: number, categoryId: number) => void;
  redirectToSupplierBestBuys: (supplierId: number) => void;
  redirectToSupplierFavourites: (supplierId: number) => void;
  redirectToSupplierReviews: (supplierId: number) => void;
  redirectToRootPublicSuppliers: () => void;
  redirectToPublicSuppliersInCategory: (city: string, category: number) => void;
  redirectToPublicSuppliersAll: (city: string) => void;
  replaceCurrentPageWithAdminPortalPageOrExternalLink: (externalLink: string) => void;
  goToHelpCenter: () => void;
  goToHelpCenterPayments: () => void;
  openZendeskWidget: () => void;
  redirectToSupplierPortal: () => void;
  redirectToLogout: () => void;
  redirectToFoodbombCollective: () => void;
  redirectToBookCollectiveMeeting: () => void;
  redirectToBookSalesMeeting: () => void;
  redirectToFeaturesIntegrations: () => void;
  redirectToFeaturesBombPay: () => void;
  redirectToApplyForBombPay: (customerReference: string) => void;
  redirectToBombPay: () => void;
  // Amaka Links
  redirectToAmakaSupport: () => void;
  redirectToConnectXero: () => void;
  redirectToConnectMYOB: () => void;
  // Custom Links
  redirectToSurvey: () => void;
  // Social Links
  redirectToFoodbombInstagram: () => void;
  redirectToFoodbombFacebook: () => void;
  redirectToFoodbombLinkedIn: () => void;
  // Public links
  redirectToPublicAboutPage: () => void;
  redirectToPublicFeaturesPage: () => void;
  redirectToPublicResourcesPage: () => void;
  redirectToPublicCareersPage: () => void;
  redirectToPublicContactsPage: () => void;
  redirectToPublicNSWSuppliersPage: () => void;
  redirectToPublicVICSuppliersPage: () => void;
  redirectToPublicBecomeSupplierPage: () => void;
  redirectToPublicBrandsPage: () => void;
  redirectToPublicVenuesRestaurantsPage: () => void;
  redirectToPublicVenuesCafePage: () => void;
  redirectToPublicVenuesCateringPage: () => void;
  redirectToPublicVenuesPubPage: () => void;
  redirectToPublicVenuesClubPage: () => void;
  redirectToPublicVenuesSchoolsPage: () => void;
  redirectToPublicFoodbombFAQSPage: () => void;
  redirectToPublicBookACallPage: () => void;
} => {
  const history = useHistory();
  const { trackEvent } = useEventTracking();

  const goToExternalLink = useCallback((externalLink: string): void => {
    window.open(externalLink, '_blank');
  }, []);

  const replacePageWithExternalLink = useCallback((externalLink: string): void => {
    window.location.href = externalLink;
  }, []);

  const replacePageWithWebflowLink = useCallback(
    (pageLink: string, inNewTab: boolean = false): void => {
      if (inNewTab) {
        goToExternalLink(`${process.env.REACT_APP_WEBFLOW_URL}/${pageLink}`);
      } else {
        replacePageWithExternalLink(`${process.env.REACT_APP_WEBFLOW_URL}/${pageLink}`);
      }
    },
    [goToExternalLink, replacePageWithExternalLink],
  );

  const goToRoute = useCallback((route: string): void => {
    window.scrollTo(0, 0);
    history.push(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const replaceCurrentPageWithAdminPortalPageOrExternalLink = useCallback(
    (externalLink: string): void => {
      // Timeout to ensure route gets hit (i.e. logout) and then onclick action fired
      setTimeout(() => {
        if (externalLink) {
          replacePageWithExternalLink(externalLink);
        } else {
          switch (window.location.host) {
            case 'localhost:8001':
              replacePageWithExternalLink('http://localhost:8002');
              break;
            case 'testshop.foodbomb.com.au':
              replacePageWithExternalLink('https://testsupplier.foodbomb.com.au');
              break;
            case 'foodbomb.com.au':
              replacePageWithExternalLink('https://supplier.foodbomb.com.au');
              break;
            default:
              replacePageWithExternalLink('https://supplier.foodbomb.com.au');
          }
        }
      }, 200);
    },
    [replacePageWithExternalLink],
  );

  // ---------------------------- PUBLIC FOODBOMB LINKS ---------------------------

  const redirectToPublicAboutPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/about-us');
  }, [replacePageWithExternalLink]);

  const redirectToPublicFeaturesPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/features');
  }, [replacePageWithExternalLink]);

  const redirectToPublicResourcesPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/blog');
  }, [replacePageWithExternalLink]);

  const redirectToPublicCareersPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/careers');
  }, [replacePageWithExternalLink]);

  const redirectToPublicContactsPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/contact-us');
  }, [replacePageWithExternalLink]);

  const redirectToPublicNSWSuppliersPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/wholesale-suppliers/sydney/all');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVICSuppliersPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/wholesale-suppliers/melbourne/all');
  }, [replacePageWithExternalLink]);

  const redirectToPublicBecomeSupplierPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/for-suppliers');
  }, [replacePageWithExternalLink]);

  const redirectToPublicBrandsPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/brands');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesRestaurantsPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/restaurant');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesCafePage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/cafe');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesCateringPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/catering');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesPubPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/pub');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesClubPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/club');
  }, [replacePageWithExternalLink]);

  const redirectToPublicVenuesSchoolsPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/venues/school-canteen');
  }, [replacePageWithExternalLink]);

  const redirectToPublicFoodbombFAQSPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/foodbomb-faqs');
  }, [replacePageWithExternalLink]);

  const redirectToPublicBookACallPage = useCallback((): void => {
    replacePageWithExternalLink('https://www.foodbomb.com.au/consultation');
  }, [replacePageWithExternalLink]);

  // ---------------------------- WEB FLOW LINKS ---------------------------

  const redirectToAboutPage = useCallback((): void => {
    replacePageWithWebflowLink('about-us', true);
  }, [replacePageWithWebflowLink]);

  const redirectToCareersPage = useCallback((): void => {
    replacePageWithWebflowLink('careers', true);
  }, [replacePageWithWebflowLink]);

  const redirectToContactPage = useCallback((): void => {
    replacePageWithWebflowLink('contact-us', true);
  }, [replacePageWithWebflowLink]);

  const redirectToBlog = useCallback((): void => {
    replacePageWithWebflowLink('blog', true);
  }, [replacePageWithWebflowLink]);

  const redirectToTermsPage = useCallback(
    (inNewTab: boolean = true): void => {
      replacePageWithWebflowLink('terms-and-conditions', inNewTab);
    },
    [replacePageWithWebflowLink],
  );

  const redirectToPrivacyPage = useCallback(
    (inNewTab: boolean = true): void => {
      replacePageWithWebflowLink('privacy-policy', inNewTab);
    },
    [replacePageWithWebflowLink],
  );

  const redirectToLoggedOutFeaturesPage = useCallback((): void => {
    replacePageWithWebflowLink('features', true);
  }, [replacePageWithWebflowLink]);

  const redirectToForSuppliersPage = useCallback((): void => {
    replacePageWithWebflowLink('for-suppliers', true);
  }, [replacePageWithWebflowLink]);

  const redirectToLoggedOutHomePage = useCallback((): void => {
    replacePageWithWebflowLink('');
  }, [replacePageWithWebflowLink]);

  const redirectToZip = useCallback((): void => {
    trackEvent('Zip - Click to visit learn more page');
    replacePageWithWebflowLink('zip-biz', true);
  }, [replacePageWithWebflowLink, trackEvent]);

  const redirectToZipSignUp = useCallback((): void => {
    trackEvent('Zip - Apply for account');
    goToExternalLink('https://biz.zip.co/?sso=sign-up&m=9d6296f8-1869-46e9-8d89-2f2616f0fad5');
  }, [goToExternalLink, trackEvent]);

  const redirectToFinstro = useCallback(() => {
    trackEvent('Finstro - Click to visit learn more page');
    trackSegmentAnalytics('Finstro Learn More', {
      url: 'https://www.foodbomb.com.au/features/foodbomb-trade',
    });
    replacePageWithExternalLink('https://www.foodbomb.com.au/features/foodbomb-trade');
  }, [replacePageWithExternalLink, trackEvent]);

  const redirectToFinstroRegister = useCallback(
    (venueId) => {
      trackEvent('Finstro - Apply for account');

      const finstroOrigin = process.env.REACT_APP_FINSTRO_ORIGIN;
      const finstroPartnerId = process.env.REACT_APP_FINSTRO_PARTNER_ID;
      const callbackUrl = `${window.location.origin}/foodbomb-trade/accept?venueId=${venueId}`;
      const redirectURL = encodeURI(`${finstroOrigin}/?partnerId=${finstroPartnerId}&callbackUrl=${callbackUrl}`);

      replacePageWithExternalLink(redirectURL);
    },
    [replacePageWithExternalLink, trackEvent],
  );

  const redirectToFBTradeRegister = useCallback(() => {
    goToRoute('/foodbomb-trade/register');
  }, [goToRoute]);

  // ---------------------------- EXTERNAL LINKS ---------------------------
  const redirectToSupplierPortal = useCallback((): void => {
    switch (window.location.host) {
      case 'localhost:8001':
        replacePageWithExternalLink('http://localhost:8000');
        break;
      case 'testshop.foodbomb.com.au':
        replacePageWithExternalLink('https://testsupplierportal.foodbomb.com.au');
        break;
      case 'foodbomb.com.au':
        replacePageWithExternalLink('https://supplierportal.foodbomb.com.au');
        break;
      default:
        replacePageWithExternalLink('https://supplierportal.foodbomb.com.au');
    }
  }, [replacePageWithExternalLink]);

  const redirectToFeaturesIntegrations = useCallback((): void => {
    goToExternalLink('https://www.foodbomb.com.au/features/accounting-integrations');
  }, [goToExternalLink]);

  const redirectToFeaturesBombPay = useCallback((): void => {
    goToExternalLink('https://www.foodbomb.com.au/features/bombpay');
  }, [goToExternalLink]);

  // Social Links
  const redirectToFoodbombInstagram = useCallback((): void => {
    goToExternalLink('https://www.instagram.com/foodbomb/');
  }, [goToExternalLink]);

  const redirectToFoodbombFacebook = useCallback((): void => {
    goToExternalLink('https://www.facebook.com/foodbombAU/');
  }, [goToExternalLink]);

  const redirectToFoodbombLinkedIn = useCallback((): void => {
    goToExternalLink('https://au.linkedin.com/company/foodbomb');
  }, [goToExternalLink]);

  const redirectToApplyForBombPay = useCallback(
    (customerReference: string): void => {
      const baseUrl = process.env.REACT_APP_BOMBPAY_URL;
      const joinBombPayUrl = `${baseUrl}/apply?client_reference_customer_id=${customerReference}&client_reference_buyer_id=${customerReference}`;

      if (!baseUrl) throw new PropertyRequiredError('baseUrl (in redirectToApplyForBombPay)');
      if (!customerReference) throw new PropertyRequiredError('customerReference (in redirectToApplyForBombPay)');

      goToExternalLink(joinBombPayUrl);
    },
    [goToExternalLink],
  );

  const redirectToBombPay = useCallback((): void => {
    const baseUrl = process.env.REACT_APP_BOMBPAY_URL;
    if (!baseUrl) throw new PropertyRequiredError('baseUrl (in redirectToBombPay)');

    goToExternalLink(baseUrl);
  }, [goToExternalLink]);

  // ---------------------------- AMAKA LINKS -----------------------------
  const redirectToAmakaSupport = useCallback((): void => {
    goToExternalLink('https://amaka.com/support-session/');
  }, [goToExternalLink]);

  // ---------------------------- ZENDESK LINKS ---------------------------
  const goToHelpCenter = useCallback((): void => {
    goToExternalLink(HELP_CENTRE_LINKS.HOME);
  }, [goToExternalLink]);

  const redirectToFAQPage = useCallback((): void => {
    goToExternalLink(HELP_CENTRE_LINKS.FAQ_GENERAL);
  }, [goToExternalLink]);

  const goToHelpCenterPayments = useCallback((): void => {
    goToExternalLink(HELP_CENTRE_LINKS.PAYMENTS);
  }, [goToExternalLink]);

  const openZendeskWidget = useCallback((): void => {
    if (window.zE) {
      window.zE('webWidget', 'open');
    } else {
      window.open(`mailTo:support@foodbomb.com.au?subject=Dashboard Feedback`, '_self');
    }
  }, []);

  // -------------------------- REACT APP REDIRECTS -------------------------

  const redirectToAccount = useCallback((): void => {
    goToRoute('/account');
  }, [goToRoute]);

  const redirectToAccountPaymentMethod = useCallback((): void => {
    goToRoute('/account/payment');
  }, [goToRoute]);

  const redirectToAccountPaymentMethodFinstroInfo = useCallback((): void => {
    goToRoute('/account/payment?fbTradeModal=true');
  }, [goToRoute]);

  const redirectToAccountPaymentMethodFinstroSuccess = useCallback((): void => {
    goToRoute('/account/payment?method=finstro&successModal=true');
  }, [goToRoute]);

  const redirectToAccountPaymentMethodWithQuery = useCallback(
    (query: string): void => {
      goToRoute(`/account/payment?method=${query}`);
    },
    [goToRoute],
  );

  const redirectToSurvey = useCallback((): void => {
    goToExternalLink('https://foodbomb.typeform.com/to/RrFfPPSl');
  }, [goToExternalLink]);

  const redirectToPaymentMethodsBombPay = useCallback((): void => {
    goToRoute('/account/payment?method=bombpay');
  }, [goToRoute]);

  const redirectToPaymentMethodsZip = useCallback((): void => {
    goToRoute('/account/payment?method=zip');
  }, [goToRoute]);

  const redirectToAccountIntegrations = useCallback((): void => {
    goToRoute('/account/integrations');
  }, [goToRoute]);

  const redirectToAccountVenueDetails = useCallback(
    (edit?: boolean): void => {
      const baseRoute = '/account/venue';
      goToRoute(edit ? baseRoute.concat('&mode=edit') : baseRoute);
    },
    [goToRoute],
  );

  const redirectToHome = useCallback((): void => {
    goToRoute('/home');
  }, [goToRoute]);

  const redirectToRegisterAndAcceptInvite = useCallback(
    (uuid: string, venueId: number, email: string): void => {
      goToRoute(`/register-and-accept-invite?uuid=${uuid}&venueId=${venueId}&email=${encodeURIComponent(email)}`);
    },
    [goToRoute],
  );

  const redirectToExistingAccountInvite = useCallback(
    (uuid: string, venueId: number) => {
      goToRoute(`/accept-existing-invite?uuid=${uuid}&venueId=${venueId}`);
    },
    [goToRoute],
  );

  const redirectToHomeAfterAddingVenue = useCallback((): void => {
    goToRoute('/home?welcome=true');
  }, [goToRoute]);

  const redirectToOrderConfirmation = useCallback((): void => {
    goToRoute('/orderConfirmation');
  }, [goToRoute]);

  const redirectToLogin = useCallback((): void => {
    goToRoute('/login');
  }, [goToRoute]);

  const redirectToSignUp = useCallback((): void => {
    // goToRoute('/signup'); // sign up is disabled, Grant 2024-02-28
    goToRoute('/');
  }, [goToRoute]);

  const redirectToViewOrders = useCallback(
    (pageNumber?: number): void => {
      if (pageNumber) {
        goToRoute(`/orders?page=${pageNumber}`);
      } else {
        goToRoute('/orders');
      }
    },
    [goToRoute],
  );

  const redirectToViewStandingOrders = useCallback(
    (pageNumber?: number): void => {
      if (pageNumber) {
        goToRoute(`/standing-orders?page=${pageNumber}`);
      } else {
        goToRoute('/standing-orders');
      }
    },
    [goToRoute],
  );

  const redirectToViewOrder = useCallback(
    (orderId: number): void => {
      goToRoute(`/orders/${orderId}`);
    },
    [goToRoute],
  );

  const redirectToViewStandingOrder = useCallback(
    (standingOrderId: number): void => {
      goToRoute(`/standing-orders/${standingOrderId}`);
    },
    [goToRoute],
  );

  const redirectToSuppliersDirectory = useCallback((): void => {
    goToRoute('/suppliers');
  }, [goToRoute]);

  const redirectToReports = useCallback((): void => {
    goToRoute('/reports');
  }, [goToRoute]);

  const redirectToSuppliersDirectoryWithBookmarkedFilter = useCallback((): void => {
    goToRoute('/suppliers?bookmarked=true');
  }, [goToRoute]);

  const redirectToCategories = useCallback((): void => {
    goToRoute('/categories');
  }, [goToRoute]);

  const redirectToCheckout = useCallback((): void => {
    goToRoute('/checkout');
  }, [goToRoute]);

  const redirectToBestBuys = useCallback((): void => {
    goToRoute('/best-buys');
  }, [goToRoute]);

  const buildNewSearchParams = (paramsToSearch: { [key: string]: any }): URLSearchParams => {
    const searchParams = new URLSearchParams();
    Object.entries(paramsToSearch).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, v);
        });
      } else {
        searchParams.append(key, value);
      }
    });
    return searchParams;
  };

  const redirectToCleanSupplierSearchWithNewParams = useCallback(
    (supplierId: number, paramsToSearch: { [key: string]: any }): void => {
      window.scrollTo(0, 0);
      const searchParams = buildNewSearchParams(paramsToSearch);
      history.push({
        pathname: `/suppliers/${supplierId}`,
        search: searchParams.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const redirectToCleanSearchWithNewParams = useCallback((paramsToSearch: { [key: string]: any }): void => {
    window.scrollTo(0, 0);
    const searchParams = buildNewSearchParams(paramsToSearch);
    history.push({
      pathname: '/search',
      search: searchParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToSearch = useCallback((): void => {
    goToRoute('/search');
  }, [goToRoute]);

  const redirectToCleanProductSearch = useCallback(
    (searchString: string): void => {
      if (searchString) {
        redirectToCleanSearchWithNewParams({ q: searchString });
      } else {
        redirectToSearch();
      }
    },
    [redirectToCleanSearchWithNewParams, redirectToSearch],
  );

  const redirectToRootCategory = useCallback(
    (categoryId: number): void => {
      goToRoute(`/categories/${categoryId}`);
    },
    [goToRoute],
  );

  const redirectToCategory = useCallback(
    (categoryId: number): void => {
      redirectToCleanSearchWithNewParams({ categories: [categoryId] });
    },
    [redirectToCleanSearchWithNewParams],
  );

  const redirectToSupplierWithSearch = useCallback(
    (supplierId: number, searchString: string): void => {
      window.scrollTo(0, 0);
      const searchParams = buildNewSearchParams({ q: searchString });
      history.push({
        pathname: `/suppliers/${supplierId}`,
        search: searchParams.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const redirectToCategoryWithSearch = useCallback((categoryId: number, searchString: string): void => {
    window.scrollTo(0, 0);
    const searchParams = buildNewSearchParams({ q: searchString });
    history.push({
      pathname: `/categories/${categoryId}`,
      search: searchParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToFavourites = useCallback((): void => {
    goToRoute('/favourites');
  }, [goToRoute]);

  const redirectToFavouritesWithQuery = useCallback(
    (query: string): void => {
      goToRoute(`/favourites?${query}`);
    },
    [goToRoute],
  );

  const redirectToShowAllProductsForSuppliers = useCallback(
    (supplierIds: number[]): void => {
      redirectToCleanSearchWithNewParams({ suppliers: supplierIds });
    },
    [redirectToCleanSearchWithNewParams],
  );

  // SUPPLIER LINKS
  const redirectToSupplier = useCallback(
    (supplierId: number): void => {
      goToRoute(`/suppliers/${supplierId}`);
    },
    [goToRoute],
  );

  const redirectToCategoryForSupplier = useCallback(
    (supplierId: number, categoryId: number): void => {
      redirectToCleanSupplierSearchWithNewParams(supplierId, { categories: [categoryId] });
    },
    [redirectToCleanSupplierSearchWithNewParams],
  );

  const redirectToSupplierBestBuys = useCallback(
    (supplierId: number): void => {
      redirectToCleanSupplierSearchWithNewParams(supplierId, { 'best-buys': true });
    },
    [redirectToCleanSupplierSearchWithNewParams],
  );

  const redirectToSupplierFavourites = useCallback(
    (supplierId: number): void => {
      redirectToCleanSupplierSearchWithNewParams(supplierId, { favourites: true });
    },
    [redirectToCleanSupplierSearchWithNewParams],
  );

  const redirectToSupplierReviews = useCallback(
    (supplierId: number): void => {
      redirectToCleanSupplierSearchWithNewParams(supplierId, { showSupplierDetails: true });
    },
    [redirectToCleanSupplierSearchWithNewParams],
  );

  // PUBLIC SUPPLIERS
  const redirectToRootPublicSuppliers = useCallback((): void => {
    goToRoute('/wholesale-suppliers');
  }, [goToRoute]);

  const redirectToPublicSuppliersInCategory = useCallback(
    (city: string, category: number): void => {
      goToRoute(`/wholesale-suppliers/${city}/categories/${category}`);
    },
    [goToRoute],
  );

  const redirectToPublicSuppliersAll = useCallback(
    (city: string): void => {
      goToRoute(`/wholesale-suppliers/${city}/all`);
    },
    [goToRoute],
  );

  const redirectToLogout = useCallback((): void => {
    goToRoute('/logout');
  }, [goToRoute]);

  const redirectToConnectXero = useCallback((): void => {
    goToExternalLink(AMAKA_XERO_INTEGRATION_ENDPOINT);
  }, [goToExternalLink]);

  const redirectToConnectMYOB = useCallback((): void => {
    goToExternalLink(AMAKA_MYOB_INTEGRATION_ENDPOINT);
  }, [goToExternalLink]);

  const redirectToFoodbombCollective = useCallback((): void => {
    goToRoute('/collective');
  }, [goToRoute]);

  const redirectToBookCollectiveMeeting = useCallback((): void => {
    goToExternalLink(BOOK_A_MEETING_FOR_COLLECTIVE_LINK);
  }, [goToExternalLink]);

  const redirectToBookSalesMeeting = useCallback((): void => {
    goToExternalLink(BOOK_A_MEETING_WITH_SALES);
  }, [goToExternalLink]);

  return {
    // Web Flow Links
    redirectToAboutPage,
    redirectToFAQPage,
    redirectToCareersPage,
    redirectToContactPage,
    redirectToBlog,
    redirectToTermsPage,
    redirectToPrivacyPage,
    redirectToLoggedOutHomePage,
    redirectToLoggedOutFeaturesPage,
    redirectToForSuppliersPage,
    // App Links
    redirectToFavourites,
    redirectToFavouritesWithQuery,
    redirectToViewOrders,
    redirectToViewStandingOrders,
    redirectToViewOrder,
    redirectToViewStandingOrder,
    redirectToReports,
    redirectToCategory,
    redirectToCheckout,
    redirectToSuppliersDirectory,
    redirectToSuppliersDirectoryWithBookmarkedFilter,
    redirectToCategories,
    redirectToBestBuys,
    redirectToCleanProductSearch,
    redirectToSearch,
    redirectToRootCategory,
    redirectToShowAllProductsForSuppliers,
    redirectToSupplier,
    redirectToCategoryWithSearch,
    redirectToSupplierWithSearch,
    redirectToOrderConfirmation,
    redirectToLogin,
    redirectToSignUp,
    redirectToRegisterAndAcceptInvite,
    redirectToExistingAccountInvite,
    redirectToHome,
    redirectToHomeAfterAddingVenue,
    redirectToZip,
    redirectToZipSignUp,
    redirectToFinstro,
    redirectToFinstroRegister,
    redirectToFBTradeRegister,
    redirectToAccount,
    redirectToAccountPaymentMethod,
    redirectToAccountPaymentMethodFinstroInfo,
    redirectToAccountPaymentMethodFinstroSuccess,
    redirectToAccountPaymentMethodWithQuery,
    redirectToPaymentMethodsZip,
    redirectToPaymentMethodsBombPay,
    redirectToAccountIntegrations,
    redirectToAccountVenueDetails,
    redirectToCategoryForSupplier,
    redirectToSupplierBestBuys,
    redirectToSupplierFavourites,
    redirectToSupplierReviews,
    redirectToRootPublicSuppliers,
    redirectToPublicSuppliersInCategory,
    redirectToPublicSuppliersAll,
    replaceCurrentPageWithAdminPortalPageOrExternalLink,
    goToHelpCenter,
    goToHelpCenterPayments,
    openZendeskWidget,
    redirectToSupplierPortal,
    redirectToLogout,
    redirectToConnectXero,
    redirectToConnectMYOB,
    redirectToFoodbombCollective,
    redirectToBookCollectiveMeeting,
    redirectToBookSalesMeeting,
    redirectToFeaturesIntegrations,
    redirectToFeaturesBombPay,
    redirectToApplyForBombPay,
    redirectToBombPay,
    // Amaka Links
    redirectToAmakaSupport,
    // Custom Links
    redirectToSurvey,
    // Social Lins
    redirectToFoodbombInstagram,
    redirectToFoodbombFacebook,
    redirectToFoodbombLinkedIn,
    // Public links
    redirectToPublicAboutPage,
    redirectToPublicFeaturesPage,
    redirectToPublicResourcesPage,
    redirectToPublicCareersPage,
    redirectToPublicContactsPage,
    redirectToPublicNSWSuppliersPage,
    redirectToPublicVICSuppliersPage,
    redirectToPublicBecomeSupplierPage,
    redirectToPublicBrandsPage,
    redirectToPublicVenuesRestaurantsPage,
    redirectToPublicVenuesCafePage,
    redirectToPublicVenuesCateringPage,
    redirectToPublicVenuesPubPage,
    redirectToPublicVenuesClubPage,
    redirectToPublicVenuesSchoolsPage,
    redirectToPublicBookACallPage,
    redirectToPublicFoodbombFAQSPage,
  };
};

export default useRedirect;
