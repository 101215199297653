import PropTypes from 'prop-types';

import styles from './Typography.module.scss';
import { TYPOGRAPHY_TYPES } from '../constants';

/**
 * # Typography
 * Simple Foodbomb Typography Component
 *
 *
 * ## Variants
 * ### Types
 * ```
 *  const TYPOGRAPHY_TYPES = {
 *    HEADING_XXXL: 'Heading_xxxl',
 *    HEADING_XXL: 'Heading_xxl',
 *    HEADING_XL: 'Heading_xl',
 *    HEADING_L: 'Heading_l',
 *    HEADING_M: 'Heading_m',
 *    HEADING_S: 'Heading_s',
 *    BODY_L: 'Body_l',
 *    BODY_L_BOLD: 'Body_l__bold',
 *    BODY: 'Body',
 *    BODY_BOLD: 'Body__bold',
 *    BODY_S: 'Body_s',
 *    BODY_S_BOLD: 'Body_s__bold',
 *  };
 * ```
 *
 * ## PropTypes:
 * ```js
 *  Typography.propTypes = {
 *    type: PropTypes.oneOf(Object.values(TYPOGRAPHY_TYPES)).isRequired,
 *    children: PropTypes.node,
 *    className: PropTypes.string,
 *  };
 * ```
 */

const Typography = ({ type, className, children, ...props }) => {
  const classes = [styles.Typography];
  classes.push(styles[type]);
  classes.push(className);

  return (
    <div className={classes.join(' ')} {...props}>
      {children}
    </div>
  );
};

Typography.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPOGRAPHY_TYPES)).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Typography;
