import { FC } from 'react';
import Confetti from 'react-confetti';
import { useConfetti } from '../../stores/ConfettiStore/ConfettiStore';
import styles from './ConfettiDispencer.module.scss';

const ConfettiDispencer: FC = () => {
  const [{ confettiCount, confettiInstances }, { removeConfettiInstance }] = useConfetti();

  return (
    <div className={styles.ConfettiContainer}>
      {confettiInstances.map((instance: string) => (
        <Confetti
          key={instance}
          numberOfPieces={confettiCount}
          height={window.innerHeight}
          width={window.innerWidth}
          recycle={false}
          onConfettiComplete={(confetti: any) => {
            confetti.reset();
            removeConfettiInstance(instance);
          }}
        />
      ))}
    </div>
  );
};

export default ConfettiDispencer;
