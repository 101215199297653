import * as actionTypes from './ActionTypes';

export const notificationNew = (notification) => ({
  type: actionTypes.NOTIFICATION_ADD,
  payload: {
    notification,
  },
});

export const notificationRemove = (notificationId) => ({
  type: actionTypes.NOTIFICATION_REMOVE,
  payload: {
    notificationId,
  },
});

export const checkNotificationTimeout = (notification) => (dispatch) => {
  setTimeout(() => {
    dispatch(notificationRemove(notification.id));
  }, notification.timeout);
};

export const notificationAdd = (notificationConfig) => (dispatch) => {
  const newNotification = { ...notificationConfig };
  newNotification.id = `${new Date().getTime().toString()}-${Math.random().toString(36).substring(2)}`;
  dispatch(notificationNew(newNotification));
  if (newNotification.timeout) {
    dispatch(checkNotificationTimeout(newNotification));
  }
};
