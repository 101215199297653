import { Action, createContainer, createHook, createStore, createSubscriber } from 'react-sweet-state';

type Actions = typeof actions;

type State = {
  pageTransitionsDisabled: boolean;
  lastAnimationWasSlide: boolean;
  pageToRedirectTo?: string;
  backShouldGoHome: boolean;
  navIsMinimised: boolean;
};

const initialState = {
  pageTransitionsDisabled: false,
  lastAnimationWasSlide: false,
  pageToRedirectTo: undefined,
  backShouldGoHome: false,
  navIsMinimised: false,
};

const enablePageTransitions =
  (): Action<State> =>
  ({ setState }) => {
    setState({ pageTransitionsDisabled: false });
  };

const disablePageTransitions =
  (): Action<State> =>
  ({ setState }) => {
    setState({ pageTransitionsDisabled: true });
  };

const updateLastAnimationWasSlide =
  (value: boolean): Action<State> =>
  ({ setState }) => {
    setState({ lastAnimationWasSlide: value });
  };

const setPageToRedirectTo =
  (value: string): Action<State> =>
  ({ setState }) => {
    setState({ pageToRedirectTo: value });
  };

const clearPageToRedirectTo =
  (): Action<State> =>
  ({ setState }) => {
    setState({ pageToRedirectTo: undefined });
  };

const toggleMinimiseNav =
  (): Action<State> =>
  ({ setState, getState }) => {
    setState({ navIsMinimised: !getState().navIsMinimised });
  };

const minimiseNav =
  (): Action<State> =>
  ({ setState }) => {
    setState({ navIsMinimised: true });
  };

const setBackShouldGoHome =
  (value: boolean): Action<State> =>
  ({ setState }) => {
    setState({ backShouldGoHome: value });
  };

const actions = {
  enablePageTransitions,
  disablePageTransitions,
  updateLastAnimationWasSlide,
  setPageToRedirectTo,
  clearPageToRedirectTo,
  setBackShouldGoHome,
  toggleMinimiseNav,
  minimiseNav,
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'PageNavigationStore',
});

export const PageNavigationSubscriber = createSubscriber(Store);
export const usePageNavigation = createHook(Store);
export const PageNavigationContainer = createContainer(Store, {});
