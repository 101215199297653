// Polyfil everything
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'array-flat-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import { ResizeObserver as ResizeObserverPolyfil } from '@juggle/resize-observer';
// End polyfil

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { defaults } from 'react-sweet-state';

import AdminProvider from './context/Admin/AdminProvider';
import FBStyleSheetRelocator from './components/FBStyleSheetRelocator/FBStyleSheetRelocator';
import RootReducer from './reduxStore/reducers/RootReducer';
import { PageNavigationContainer, PageNavigationSubscriber } from './stores/PageNavigationStore/PageNavigationStore.ts';

import './css/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/custom-slick.css';
import AppRouter from './containers/AppRouter';
import ConfettiDispencer from './components/ConfettiDispencer/ConfettiDispencer.tsx';

// Activate dev tools for sweet-state
defaults.devtools = true;

// Activate Redux Dev Tools - install for chrome
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk)));

Array.from(document.querySelectorAll('[data-jss]')).forEach((elem) => elem.setAttribute('data-jss-pre-render', ''));

window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfil;

const app = (
  <Provider store={store}>
    <StylesProvider injectFirst>
      <FBStyleSheetRelocator>
        <React.Fragment>
          <BrowserRouter>
            <AdminProvider>
              <PageNavigationContainer>
                <PageNavigationSubscriber>
                  {(pageNavigationState, pageNavigationActions) => (
                    <>
                      <ConfettiDispencer />
                      <AppRouter
                        pageToRedirectTo={pageNavigationState.pageToRedirectTo}
                        clearPageToRedirectTo={pageNavigationActions.clearPageToRedirectTo}
                        setBackShouldGoHome={pageNavigationActions.setBackShouldGoHome}
                        navIsMinimised={pageNavigationState.navIsMinimised}
                        toggleMinimiseNav={pageNavigationActions.toggleMinimiseNav}
                        minimiseNav={pageNavigationActions.minimiseNav}
                      />
                    </>
                  )}
                </PageNavigationSubscriber>
              </PageNavigationContainer>
            </AdminProvider>
          </BrowserRouter>
        </React.Fragment>
      </FBStyleSheetRelocator>
    </StylesProvider>
  </Provider>
);

render(app, document.querySelector('#main'));
