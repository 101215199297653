import * as actionTypes from './ActionTypes';
import { buildErrorMessages } from '../ReduxUtilities';
import { FoodbombAPI } from '../../AxiosInstances';
import { PAYMENT_METHODS, DEFAULT_CC_FEE, DEFAULT_PRE_AUTH } from '../../utils/Constants';
import {
  isPaymentMethodEnabledAndConfiguredAndActive,
  potentiallyGetPaymentMethod,
  checkPaymentMethodEnabled,
} from '../../utils/PaymentMethodUtils/PaymentMethodUtils.ts';
import * as ErrorReportHelper from '../../utils/ErrorReportHelper/ErrorReportHelper';

// THIS IS A FALLBACK INCASE WE HAVE BAD DATA
const DEFAULT_PAYMENT_METHOD = {
  method: PAYMENT_METHODS.CREDIT_CARD,
  fee: DEFAULT_CC_FEE,
  preAuth: DEFAULT_PRE_AUTH,
  trevipayStatus: undefined,
};

// Logic for getting the default method - we currently dont store this preference.
// Assume Finstro if available. Then Bombpay, Waddle and Zip. Finally, fallback to credit card.
const getDefaultSelectedPaymentMethod = (paymentMethods) => {
  if (!paymentMethods) {
    return DEFAULT_PAYMENT_METHOD;
  }

  const methodsInOrder = [
    PAYMENT_METHODS.FINSTRO,
    PAYMENT_METHODS.TREVIPAY,
    PAYMENT_METHODS.WADDLE,
    PAYMENT_METHODS.ZIP,
    PAYMENT_METHODS.CREDIT_CARD,
  ];
  const firstAvailableMethod = methodsInOrder.find((method) =>
    isPaymentMethodEnabledAndConfiguredAndActive(paymentMethods, method),
  );
  if (firstAvailableMethod) {
    return potentiallyGetPaymentMethod(paymentMethods, firstAvailableMethod);
  }

  return DEFAULT_PAYMENT_METHOD;
};

const getSelectedPaymentMethod = (paymentMethods, paymentMethodNameToSelect) => {
  if (!paymentMethods.length || !paymentMethodNameToSelect) {
    return DEFAULT_PAYMENT_METHOD;
  }

  const selectedMethod = potentiallyGetPaymentMethod(paymentMethods, paymentMethodNameToSelect);
  if (isPaymentMethodEnabledAndConfiguredAndActive(paymentMethods, paymentMethodNameToSelect)) {
    return selectedMethod;
  }

  // If the selected payment method is invalid, fall back to the default
  return getDefaultSelectedPaymentMethod(paymentMethods);
};

const getPaymentMethodsStart = () => ({
  type: actionTypes.GET_PAYMENT_METHODS_START,
});

const getPaymentMethodsSuccess = (paymentMethods, selectedPaymentMethod) => ({
  type: actionTypes.GET_PAYMENT_METHODS_SUCCESS,
  payload: {
    paymentMethods,
    selectedPaymentMethod,
  },
});

export const setPaymentMethods = (paymentMethods, paymentMethodNameToSelect) => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  payload: {
    paymentMethods,
    selectedPaymentMethod: getSelectedPaymentMethod(paymentMethods, paymentMethodNameToSelect),
  },
});

const getPaymentMethodsFail = (error) => ({
  type: actionTypes.GET_PAYMENT_METHODS_FAIL,
  payload: {
    error: buildErrorMessages(error),
  },
});

export const clearPaymentMethods = () => ({ type: actionTypes.CLEAR_PAYMENT_METHODS });

export const callToRefreshFinstroPaymentMethod = (venueId) => {
  FoodbombAPI.get(`venues/${venueId}/payment-methods/finstro/refresh`)
    .then(() => {
      // Do nothing. We're just sending this to the backend
    })
    .catch((error) => {
      if (error?.response?.status === 404) {
        // Do nothing. This is expected if the venue has never attempted to register for finstro
      } else {
        ErrorReportHelper.sendError(
          error,
          'Unable to fetch payment methods',
          {
            error: { status: error?.response?.status, data: error?.response?.data },
            location: 'Payment Method Redux Action',
          },
          [error.response.status],
        );
      }
    });
};

export const getPaymentMethods =
  (venue, currentlySelectedPaymentMethod = undefined) =>
  (dispatch) => {
    dispatch(getPaymentMethodsStart());

    FoodbombAPI.get(`venues/${venue?.id}/payment-methods`)
      .then((response) => {
        const { paymentMethods } = response.data;

        if (checkPaymentMethodEnabled(paymentMethods, PAYMENT_METHODS.FINSTRO)) {
          dispatch(() => callToRefreshFinstroPaymentMethod(venue?.id));
        }

        let previouslySelectedPaymentMethod;
        const defaultPaymentMethod = getDefaultSelectedPaymentMethod(paymentMethods);
        if (currentlySelectedPaymentMethod) {
          previouslySelectedPaymentMethod = getSelectedPaymentMethod(paymentMethods, currentlySelectedPaymentMethod);
        }

        dispatch(getPaymentMethodsSuccess(paymentMethods, previouslySelectedPaymentMethod || defaultPaymentMethod));
      })
      .catch((err) => {
        const tags = [];
        if (err.response && err.response.status) {
          tags.push(err.response.status);
        }

        ErrorReportHelper.sendError(
          err,
          'Unable to fetch payment methods',
          {
            error: { status: err?.response?.status, data: err?.response?.data },
            location: 'Payment Method Redux Action',
          },
          tags,
        );
        dispatch(getPaymentMethodsFail(err));
      });
  };
