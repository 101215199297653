import PropTypes from 'prop-types';

import Typography from '../Typography/Typography';
import NotificationMessageSection from '../NotificationMessageSection/NotificationMessageSection';
import styles from './ErrorNotification.module.scss';

import { TYPOGRAPHY_TYPES, NOTIFICATION_MESSAGE_TYPES, NOTIFICATION_MESSAGE_SIZES } from '../constants';

/**
 * # Error Notification Component
 * A simple wrapper for the NOtification Message Section component designed for better handling of errors.
 *
 * ## Modifiers
 * ### Sizes
 * ```
 * ['regular', 'large', 'small']
 * ```
 *
 * ## PropTypes:
 * ```js
 * ErrorNotification.propTypes = {
 * title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
 * body: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
 * hideContactInfo: PropTypes.bool,
 * actions: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
 * size: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_SIZES)),
 * className: PropTypes.string,
 * };
 *  };
 * ```
 *
 * ## Error Notification API
 *
 *| PROPERTY            | REQUIRED           | TYPE             | DESCRIPTION                                                                                                                                |
 *|---------------------|--------------------|------------------|--------------------------------------------------------------------------------------------------------------------------------------------|
 *| className           | false              | string           | The CSS class to apply to the table                                                                                                        |
 *| title               | false              | any              | The title of the error notification. displayed in bold at the top.                                                                         |
 *| body                | false              | any              | the main body of the error message                                                                                                         |
 *| hideContactInfo     | false              | bool             | switch to choose whether to display the support email and phone number to contact                                                          |
 *| actions             | false              | any              | to add actions offered to the user to handle the error, eg. button.                                                                        |
 *| size                | false              | constants string | Changes the size of the button.                                                                                                            |
 *
 *
 * ## Example
 * ```js
 * <ErrorNotification />
 * ```
 */

const ErrorNotification = ({ size, title, body, hideContactInfo, actions, className, fullWidth }) => {
  const notificationClasses = [];
  if (className) {
    notificationClasses.push(className);
  }
  if (fullWidth) {
    notificationClasses.push(styles.FullWidthNotification);
  }
  return (
    <NotificationMessageSection
      type={NOTIFICATION_MESSAGE_TYPES.ERROR}
      size={size || NOTIFICATION_MESSAGE_SIZES.LARGE}
      title={title || 'Whoops... Something went wrong!'}
      className={notificationClasses.join(' ')}
    >
      <div className={styles.ErrorNotification__content}>
        <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ErrorBody}>
          {body || <span>We were unable to load this page. Don&apos;t worry, our engineers have been notified!</span>}
        </Typography>
        {hideContactInfo ? null : (
          <Typography className={styles.ContactInfo} type={TYPOGRAPHY_TYPES.BODY}>
            Please try again or contact us on&nbsp;
            <a className={styles.RedLink} href={`tel:1300309055`}>
              1300 309 055
            </a>
            &nbsp;or&nbsp;
            <a className={styles.RedLink} href={`mailto:support@foodbomb.com.au`}>
              support@foodbomb.com.au
            </a>
            &nbsp;for assistance
          </Typography>
        )}
        {actions ? <div className={styles.ErrorNotification__actions}>{actions}</div> : null}
      </div>
    </NotificationMessageSection>
  );
};

ErrorNotification.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  hideContactInfo: PropTypes.bool,
  actions: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
  size: PropTypes.oneOf(Object.values(NOTIFICATION_MESSAGE_SIZES)),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default ErrorNotification;
