// These are the constants that are required for the FB UI library - keeping them external to shop specific constants

export const BUTTON_SIZES = {
  REGULAR: 'regular',
  LARGE: 'large',
  SMALL: 'small',
  EXTRA_SMALL: 'extra_small',
};

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  SUCCESS: 'success',
  LIGHT_PRIMARY: 'light_primary',
};

export const FAVOURITE_BUTTON_VARIANTS = {
  GREEN: 'green',
  LIGHT_GREEN: 'light_green',
};

export const NOTIFICATION_MESSAGE_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const NOTIFICATION_MESSAGE_SIZES = {
  SMALL: 'small',
  LARGE: 'large',
};

export const STATUS_CHIP_TYPES = {
  SUCCESS: 'success',
  FAILED: 'failed',
  PENDING: 'pending',
  DEFAULT: 'default',
  INFO: 'info',
};

export const TYPOGRAPHY_TYPES = {
  HEADING_XXXL: 'Heading_xxxl',
  HEADING_XXL: 'Heading_xxl',
  HEADING_XL: 'Heading_xl',
  HEADING_L: 'Heading_l',
  HEADING_M: 'Heading_m',
  HEADING_S: 'Heading_s',
  BODY_L: 'Body_l',
  BODY_L_BOLD: 'Body_l__bold',
  BODY: 'Body',
  BODY_BOLD: 'Body__bold',
  BODY_S: 'Body_s',
  BODY_S_BOLD: 'Body_s__bold',
};

export const ALERT_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  CONFIRM: 'confirm',
};

export const JOINED_TOGGLE_BTN_VARIANTS = {
  LOGGED_OUT: 'loggedOut',
};

export const EDITABLE_TABLE_CELL_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  CUSTOM: 'custom',
};
