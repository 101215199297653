import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { InputBase } from '@material-ui/core';

import Button from '../Button/Button';
import styles from './SearchBar.module.scss';

/**
 * # Search bar
 * Simple Foodbomb Search Bar Component
 *
 * ## PropTypes:
 * ```js
 * SearchBar.propTypes = {
 *   handleSearch: PropTypes.func.isRequired,
 *   onChange: PropTypes.func.isRequired,
 *   value: PropTypes.string,
 *   onFocus: PropTypes.func,
 *   onBlur: PropTypes.func,
 *   placeholder: PropTypes.string,
 *   classes: PropTypes.shape({
 *     searchBar: PropTypes.string,
 *     inputRoot: PropTypes.string,
 *     inputInput: PropTypes.string,
 *     searchButton: PropTypes.string,
 *     searchForm: PropTypes.string,
 *   }),
 *   handleClear: PropTypes.func,
 *   forceFocus: PropTypes.bool,
 * };
 * ```
 */

const SearchBar = ({
  placeholder,
  handleSearch,
  onChange,
  onFocus,
  onBlur,
  value,
  handleClear,
  forceFocus,
  classes,
  ...props
}) => {
  // Classes you can pass in classes object -> searchBar, inputRoot, inputInput, searchButton, searchForm

  const SearchBarStyles = [styles.SearchBar];
  const InputRootStyles = [styles.Input__root];
  const InputInputStyles = [styles.Input__input];
  const SearchButtonStyles = [styles.SearchBar__SearchBtn];
  const SearchFormStyles = [styles.SearchBarForm];

  const searchInputElem = useRef();
  const searchFormElem = useRef();

  useEffect(() => {
    if (forceFocus) {
      searchInputElem.current.focus();
    }
  }, [forceFocus]);

  if (classes) {
    SearchBarStyles.push(classes.searchBar);
    InputRootStyles.push(classes.inputRoot);
    InputInputStyles.push(classes.inputInput);
    SearchButtonStyles.push(classes.searchButton);
    SearchFormStyles.push(classes.searchForm);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    searchInputElem.current.blur();
    handleSearch();
  };

  const handleClearAndRefocus = () => {
    handleClear();
    searchInputElem.current.focus();
  };

  // Note: as of React 17, focus and blur events arent able to be tested with our current Jest implementation
  const handleOnFocus = () => {
    if (onFocus) {
      onFocus();
    }
    searchFormElem.current.classList.add(styles.active);
  };

  // Note: as of React 17, focus and blur events arent able to be tested with our current Jest implementation
  const handleOnBlur = () => {
    if (onBlur) {
      onBlur();
    }
    searchFormElem.current.classList.remove(styles.active);
  };

  return (
    <div className={SearchBarStyles.join(' ')}>
      <form
        onSubmit={handleSubmit}
        acceptCharset="utf-8"
        action="#"
        className={SearchFormStyles.join(' ')}
        ref={searchFormElem}
        autoComplete="off"
      >
        <InputBase
          fullWidth
          value={value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          classes={{
            root: InputRootStyles.join(' '),
            input: InputInputStyles.join(' '),
          }}
          type="search"
          inputProps={{ 'aria-label': 'Search product, category or wholesaler', ref: searchInputElem }}
          onChange={onChange}
          {...props}
        />
        <HighlightOffIcon
          className={
            value && handleClear ? [styles.SearchBar__ClearIcon, styles.show].join(' ') : styles.SearchBar__ClearIcon
          }
          onClick={handleClearAndRefocus}
        />
        <Button variant="secondary" onClick={handleSearch} className={SearchButtonStyles.join(' ')}>
          <SearchIcon />
        </Button>
      </form>
    </div>
  );
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  classes: PropTypes.shape({
    searchBar: PropTypes.string,
    inputRoot: PropTypes.string,
    inputInput: PropTypes.string,
    searchButton: PropTypes.string,
    searchForm: PropTypes.string,
  }),
  handleClear: PropTypes.func,
  forceFocus: PropTypes.bool,
};

export default SearchBar;
