export const getURLParameter = (pathname: string, param: string): string | undefined => {
  const re = new RegExp(`/${param}/([^/]+)(/|$)`);
  const matches = re.exec(pathname);
  return matches?.[1];
};

export const urlHasSupplierOrCategory = (pathname: string): boolean =>
  Boolean(getURLParameter(pathname, 'categories') || getURLParameter(pathname, 'suppliers'));

export default getURLParameter;
