import { AccountVenueInformation } from '../PresentVenue/PresentVenue';

export interface PresentedAccount {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  isStaff?: boolean;
  venues: AccountVenueInformation[];
}

export enum AccountRoleIds {
  owner = 1,
  manager = 2,
  operator = 3,
}

export enum ApiMethodTypes {
  get = 'get',
  post = 'post',
  patch = 'patch',
  delete = 'delete',
}

export type AccountListType = {
  accountId: number;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  venueAccountRoleId: AccountRoleIds;
  venueAccountId: number;
  status: string;
  updatedAt: string;
};

export type InvitationAccountListType = {
  createdAt: string;
  createdBy: number;
  email: string;
  expiresAt: string;
  id: number;
  isAccepted: number;
  jobTitle: string;
  updatedAt: string;
  updatedBy: number;
  uuid: string;
  venueAccountRoleId: AccountRoleIds;
  venueId: number;
};

export const createBackgroundColor = (roleId?: AccountRoleIds, styles?: any): number | void => {
  if (roleId === AccountRoleIds.owner) {
    return styles?.Owner;
  }
  if (roleId === AccountRoleIds.manager) {
    return styles?.Manager;
  }
  return styles?.Operator;
};

const PresentAccount = (account: PresentedAccount): PresentedAccount => ({
  ...account,
});

export default PresentAccount;
