export enum DismissibleHolidayNames {
  AUSTRALIA_DAY = `Australia Day`,
}

export const STATES: { [abreviation: string]: string } = {
  NSW: 'NSW',
  VIC: 'VIC',
  QLD: 'QLD',
  WA: 'WA',
  ACT: 'ACT',
  SA: 'SA',
  NT: 'NT',
  TAS: 'TAS',
};

// TODO: use enum
type State =
  | typeof STATES.NSW
  | typeof STATES.VIC
  | typeof STATES.QLD
  | typeof STATES.WA
  | typeof STATES.ACT
  | typeof STATES.SA
  | typeof STATES.NT
  | typeof STATES.TAS;

type PublicHoliday = {
  title: string;
  message: string;
  states: State[];
  dates: Date[];
  excludedSupplierIds: number[];
  dismissibleGroupId: DismissibleHolidayNames;
};

type CustomCutoff = {
  supplierId: number;
  deliveryDate: Date;
  cutoffDateTime: Date;
};

export const PUBLIC_HOLIDAYS: PublicHoliday[] = [
  // VICTORIA
  // 27 Jan
  {
    dismissibleGroupId: DismissibleHolidayNames.AUSTRALIA_DAY,
    title: `${DismissibleHolidayNames.AUSTRALIA_DAY} 2025`,
    message: `Most suppliers won't be delivering on ${DismissibleHolidayNames.AUSTRALIA_DAY}, 27 Jan.`,
    states: [STATES.VIC],
    dates: [new Date(2025, 0, 27)],
    excludedSupplierIds: [
      // Only grain emporium delivers this day
      589,
    ],
  },
  // NSW
  // 27 Jan
  {
    dismissibleGroupId: DismissibleHolidayNames.AUSTRALIA_DAY,
    title: `${DismissibleHolidayNames.AUSTRALIA_DAY} 2025`,
    message: `Most suppliers won't be delivering on ${DismissibleHolidayNames.AUSTRALIA_DAY}, 27 Jan.`,
    states: [STATES.NSW],
    dates: [new Date(2025, 0, 27)],
    excludedSupplierIds: [
      // Luxe and Big Crunch
      240, 543,
    ],
  },
  // NSW
  // 28 Jan
  {
    dismissibleGroupId: DismissibleHolidayNames.AUSTRALIA_DAY,
    title: `${DismissibleHolidayNames.AUSTRALIA_DAY} 2025`,
    message: `Some suppliers won't be delivering on ${DismissibleHolidayNames.AUSTRALIA_DAY}, 28 Jan.`,
    states: [STATES.NSW],
    dates: [new Date(2025, 0, 28)],
    excludedSupplierIds: [
      // everyone except Big Crunch
      72, 81, 84, 88, 115, 119, 120, 132, 156, 174, 192, 202, 206, 208, 209, 213, 228, 240, 259, 283, 309, 341, 388,
      401, 402, 428, 439, 445, 455, 473, 477, 488, 492, 500, 504, 507, 512, 516, 518, 528, 573, 578, 582, 583, 584, 592,
      594, 599, 602,
    ],
  },
];

export const CUSTOM_CUTOFFS_FOR_DATES: CustomCutoff[] = [
  {
    supplierId: 192,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('23/01/25 00:00'),
  },
  {
    supplierId: 341,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 15:00'),
  },
  {
    supplierId: 431,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('23/01/25 17:00'),
  },
  {
    supplierId: 442,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 16:00'),
  },
  {
    supplierId: 447,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 17:00'),
  },
  {
    supplierId: 458,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('23/01/25 23:00'),
  },
  {
    supplierId: 464,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 15:00'),
  },
  {
    supplierId: 465,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 15:00'),
  },
  {
    supplierId: 473,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 15:00'),
  },
  {
    supplierId: 500,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('23/01/25 12:00'),
  },
  {
    supplierId: 504,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 14:00'),
  },
  {
    supplierId: 512,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 12:00'),
  },
  {
    supplierId: 516,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 14:00'),
  },
  {
    supplierId: 518,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 15:00'),
  },
  {
    supplierId: 519,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 14:00'),
  },
  {
    supplierId: 524,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 14:00'),
  },
  {
    supplierId: 538,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 16:00'),
  },
  {
    supplierId: 548,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 16:00'),
  },
  {
    supplierId: 573,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 11:00'),
  },
  {
    supplierId: 582,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('24/01/25 14:00'),
  },
  {
    supplierId: 597,
    deliveryDate: new Date(2025, 0, 28),
    cutoffDateTime: new Date('23/01/25 23:00'),
  },
];

/*
 * This array is used to determine if we should display the banner on a suppliers
 * page. We probably only want to add block-periods of public holidays here.
 */
export const SUPPLIER_HOLIDAY_DATES: Date[] = [new Date(2025, 0, 27), new Date(2025, 0, 28)];
