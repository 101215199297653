import { datadogLogs } from '@datadog/browser-logs';
import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../utils/Presenters/ReduxTypes';
import { sendError, trackPageView } from '../../utils/ErrorReportHelper/ErrorReportHelper';

const useDDErrorReporting = (): {
  sendDatadogError: (
    errorMessage: string,
    data: { error: Error | AxiosError | any; location?: string; [key: string]: any },
  ) => void;
  trackPageView: (pathName: string) => void;
} => {
  const venueId = useSelector((state: ReduxState) => state.currentVenue?.venue?.id);
  const tokenSubjectId = useSelector((state: ReduxState) => state.auth.tokenSub);
  const accountId = useSelector((state: ReduxState) => state.auth.account?.id);
  const isStaff = useSelector((state: ReduxState) => Boolean(state.auth.account?.isStaff));

  const potentiallyGetStatusCodeForError = (error: AxiosError): number | undefined => {
    if (error?.response) {
      return error?.response?.status;
    }
    return undefined;
  };

  const potentiallyStripHeadersFromData = (error: AxiosError) => {
    const dataToSend = { ...error };
    if (dataToSend?.config) {
      dataToSend.config.headers.Authorization = undefined;
    }
    return dataToSend;
  };

  const sendDatadogError = useCallback(
    (errorMessage: string, data: { error: Error | AxiosError; location?: string }) => {
      const tags = [];
      const isAxiosError = axios.isAxiosError(data.error);

      const dataToSend = isAxiosError ? potentiallyStripHeadersFromData(data.error as AxiosError) : data.error;

      if (isAxiosError) {
        const statusCode = potentiallyGetStatusCodeForError(data.error as AxiosError);
        tags.push(statusCode);
      }

      sendError(data.error, errorMessage, {
        data: dataToSend,
        userDetails: {
          venueId,
          isStaff,
          accountId,
          tokenSubjectId,
        },
        tags,
      });

      const userDetails = {
        venueId,
        isStaff,
        accountId,
        tokenSubjectId,
      };
      datadogLogs.logger.error(errorMessage, {
        error: { ...data.error, dataToSend },
        location: data.location,
        userDetails,
      });
    },
    [venueId, isStaff, accountId, tokenSubjectId],
  );
  const logPageview = (pathName: string) => {
    trackPageView(pathName);
  };

  return {
    sendDatadogError,
    trackPageView: logPageview,
  };
};

export default useDDErrorReporting;
