import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import withRedirectHelper from '../../../hoc/withRedirectHelper/withRedirectHelper';
import { BUTTON_VARIANTS } from '../../../utils/Constants';
import { Button } from '../../UI/FB';
import styles from './WebsiteNavigation.module.scss';

const WebsiteMobileDrawer = ({
  handleCloseMobileDrawer,
  redirectToLogin,
  redirectToLoggedOutHomePage,
  redirectToLoggedOutFeaturesPage,
  redirectToForSuppliersPage,
  redirectToAboutPage,
  redirectToContactPage,
  redirectToCareersPage,
}) => {
  const closeMobileDrawerAndCallback = (callback) => {
    handleCloseMobileDrawer();
    callback();
  };

  const unauthenticatedNavLinks = [
    {
      copy: 'Home',
      onClick: () => closeMobileDrawerAndCallback(redirectToLoggedOutHomePage),
    },
    {
      copy: 'Features',
      onClick: () => closeMobileDrawerAndCallback(redirectToLoggedOutFeaturesPage),
    },
    {
      copy: 'Suppliers',
      onClick: () => closeMobileDrawerAndCallback(redirectToForSuppliersPage),
    },
    {
      copy: 'About',
      onClick: () => closeMobileDrawerAndCallback(redirectToAboutPage),
    },
    {
      copy: 'Contact',
      onClick: () => closeMobileDrawerAndCallback(redirectToContactPage),
    },
    {
      copy: 'Careers',
      onClick: () => closeMobileDrawerAndCallback(redirectToCareersPage),
    },
  ];

  return (
    <React.Fragment>
      <div className={styles.MobileDrawerContainer}>
        <div className={styles.MobileDrawerContainer__Top}>
          <Button
            onClick={() => closeMobileDrawerAndCallback(redirectToLogin)}
            fullWidth
            variant={BUTTON_VARIANTS.SECONDARY}
            className={styles.MobileDrawerButton}
          >
            Sign In
          </Button>
          <Divider light className={styles.MobileDrawerDivider} />
          <ul className={styles.AppBar__MobileLinkList}>
            {unauthenticatedNavLinks.map((link) => (
              <NavLink
                key={link.copy}
                onClick={link.onClick || undefined}
                exact
                to={link.link || '#'}
                className={styles.Link}
                activeClassName={styles.Link__Active}
              >
                <li className={styles.AppBar__MobileLinkItem}>{link.copy}</li>
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

WebsiteMobileDrawer.propTypes = {
  handleCloseMobileDrawer: PropTypes.func.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  redirectToLoggedOutHomePage: PropTypes.func.isRequired,
  redirectToAboutPage: PropTypes.func.isRequired,
  redirectToContactPage: PropTypes.func.isRequired,
  redirectToCareersPage: PropTypes.func.isRequired,
  redirectToLoggedOutFeaturesPage: PropTypes.func.isRequired,
  redirectToForSuppliersPage: PropTypes.func.isRequired,
  redirectToSignUp: PropTypes.func.isRequired,
};

export default withRedirectHelper(WebsiteMobileDrawer);
