import { useState } from 'react';
import PropTypes from 'prop-types';

import useScrollPosition from '../ScrollPositionHelper';
import styles from './HidingFixedHeader.module.scss';

/**
 * # Hiding Fixed Header
 * A header fixed to the window that only becomes visible once the user scrolls vertically past a set trigger point
 *
 * ## Modifiers
 * ```js
 * scrollActivationDistance //Distance at which the header should become fixed to the top of the window
 * ```
 *
 * ## PropTypes:
 * ```js
 *  FBButton.propTypes = {
 *    scrollActivationDistance: PropTypes.number.isRequired,
 *    className: PropTypes.string,
 *    children: PropTypes.node,
 *  };
 * ```
 */

const HidingFixedHeader = ({
  scrollActivationDistance,
  className,
  showClassOverride,
  hideClassOverride,
  children,
  ...props
}) => {
  const [fixHeader, setFixHeader] = useState(false);

  const showHeaderStyles = [styles.FixedHeader, styles.show, showClassOverride];
  const hideHeaderStyles = [styles.FixedHeader, styles.hide, hideClassOverride];
  showHeaderStyles.push(className);
  hideHeaderStyles.push(className);

  useScrollPosition(
    ({ currentPosition }) => {
      const pastScrollBoundary = currentPosition.y > scrollActivationDistance;

      const shouldFixHeader = pastScrollBoundary;

      if (shouldFixHeader !== fixHeader) {
        setFixHeader(shouldFixHeader);
      }
    },
    [fixHeader],
    undefined,
    true,
    100,
  );
  return (
    <div className={fixHeader ? showHeaderStyles.join(' ') : hideHeaderStyles.join(' ')} {...props}>
      <div className={styles.FixedHeader__content}>{children}</div>
    </div>
  );
};

HidingFixedHeader.propTypes = {
  scrollActivationDistance: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  showClassOverride: PropTypes.string,
  hideClassOverride: PropTypes.string,
};

export default HidingFixedHeader;
