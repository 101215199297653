import { STATES } from './Constants';

const postcodeToState = (postcode) => {
  const parsedPostcode = parseInt(postcode, 10);

  // See https://en.wikipedia.org/wiki/Postcodes_in_Australia
  // Australian Capital Territory	ACT	0200—0299 (LVRs and PO Boxes only)
  // ACT is silly and has lots of things
  if (
    (parsedPostcode >= 200 && parsedPostcode <= 299) ||
    (parsedPostcode >= 2600 && parsedPostcode <= 2618) ||
    (parsedPostcode >= 2900 && parsedPostcode <= 2920)
  )
    return STATES.ACT;
  // Northern Territory	NT	0800—0899 0900—0999 (LVRs and PO Boxes only)
  if (parsedPostcode >= 800 && parsedPostcode <= 999) return STATES.NT;
  // New South Wales	NSW	1000—2999
  if (parsedPostcode >= 1000 && parsedPostcode <= 2999) return STATES.NSW;
  // Victoria	VIC 3000—3999 8000—8999 (LVRs and PO Boxes only)
  if ((parsedPostcode >= 3000 && parsedPostcode <= 3999) || (parsedPostcode >= 8000 && parsedPostcode <= 8999))
    return STATES.VIC;
  // Queensland	QLD	4000—4999 9000—9999 (LVRs and PO Boxes only)
  if ((parsedPostcode >= 4000 && parsedPostcode <= 4999) || (parsedPostcode >= 9000 && parsedPostcode <= 9999))
    return STATES.QLD;
  // South Australia	SA	5000—5799 5800—5999 (LVRs and PO Boxes only)
  if (parsedPostcode >= 5000 && parsedPostcode <= 5999) return STATES.SA;
  // Western Australia	WA	6000—6797 6800—6999 (LVRs and PO Boxes only)
  if ((parsedPostcode >= 6000 && parsedPostcode <= 6797) || (parsedPostcode >= 6800 && parsedPostcode <= 6999))
    return STATES.WA;
  // Tasmania	TAS	7000—7799 7800—7999 (LVRs and PO Boxes only)
  if (parsedPostcode >= 7000 && parsedPostcode <= 7999) return STATES.TAS;
  return STATES.NSW;
};

export default postcodeToState;
