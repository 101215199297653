import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as Yup from 'yup';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting';
import useNotifications from '../../hooks/useNotifications/useNotifications';
import useQuery from '../../hooks/useQuery/useQuery';
import useRedirect from '../../hooks/useRedirect/useRedirect';
import { FoodbombAPI } from '../../AxiosInstances';
import Image from '../../img/signup_image.svg';
import { authLogin } from '../../reduxStore/actions';
import { Button, ErrorNotification, FormikFormField, Typography } from '../../components/UI/FB';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import styles from './AcceptInvitationSignUp.module.scss';

type ValuesTypes = {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string;
  password: string;
  passwordConfirmation: string;
};

const AcceptInvitationSignUp = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const dispatch = useDispatch();
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const { redirectToHome } = useRedirect();
  const query = useQuery();
  const venueId = query.get('venueId');
  const uuid = query.get('uuid');
  const emailQuery = query.get('email');
  const email = emailQuery ? decodeURIComponent(emailQuery) : null;

  const handleToggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const signUpValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(32, 'Please enter a first name less than 32 characters')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .max(32, 'Please enter a last name less than 32 characters')
      .required('Last name is required'),
    email: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
    phone: Yup.string().trim().required('Phone number is required'),
    password: Yup.string().trim().required('Password is required').min(8, 'Password should be minimum of 8 characters'),
    passwordConfirmation: Yup.string()
      .trim()
      .required('Password is required')
      .when('password', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('password')], 'Password does not match, please try again.'),
      }),
  });

  const formatDataForAPI = (values: ValuesTypes) => ({
    firstname: values.firstName,
    lastname: values.lastName,
    email: values.email,
    phone: values.phone,
    password: values.password,
    passwordConfirmation: values.passwordConfirmation,
  });

  const mapAPIErrorsToFormFields = (errors: any) => {
    const formErrors: any = {};
    errors.forEach((e: any) => {
      formErrors[e.param] = e.message;
    });

    return formErrors;
  };

  const handleSubmit = (values: ValuesTypes, actions: FormikHelpers<ValuesTypes>) => {
    setApiErrorMessage('');
    const { passwordConfirmation, ...payload } = formatDataForAPI(values);
    FoodbombAPI.post(`account/invitations/${venueId}/${uuid}/register`, payload)
      .then(() => {
        dispatch(authLogin(email, passwordConfirmation));
        redirectToHome();
        createNotification({
          timeout: 5000,
          closable: true,
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'You have successfully signed up to Foodbomb',
        });
      })
      .catch((error) => {
        createNotification({
          timeout: 5000,
          closable: true,
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to sign up, please try again.',
        });
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          const formFields = ['firstName', 'lastName', 'email', 'password'];
          const firstNonFormError = error.response.data.errors.filter(
            (err: any) => !formFields.includes(err.param),
          )?.[0];

          if (firstNonFormError) {
            setApiErrorMessage(firstNonFormError.message);
          } else {
            actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
          }
        }
        sendDatadogError('Failed to sign up', { error, location: 'Accept invitation sign up page' });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={styles.SignUp__Form__Container}>
      <div className={styles.Image__Container}>
        <div className={styles.Image__Wrapper}>
          <div className={styles.SignUp__Image}>
            {/* @ts-expect-error */}
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>All your wholesale ordering in one place</Typography>
            <img src={Image} alt="Foodbomb app" className={styles.Image} />
          </div>
          <div className={styles.Image__Content}>
            <div className={styles.Image__Details}>
              <CheckCircleOutlineIcon classes={{ root: styles.Icon }} />
              {/* @ts-expect-error */}
              <Typography type={TYPOGRAPHY_TYPES.BODY}>Hundreds of local suppliers</Typography>
            </div>
            <div className={styles.Image__Details}>
              <CheckCircleOutlineIcon classes={{ root: styles.Icon }} />
              {/* @ts-expect-error */}
              <Typography type={TYPOGRAPHY_TYPES.BODY}>Transparent wholesale, pricing</Typography>
            </div>
            <div className={styles.Image__Details}>
              <CheckCircleOutlineIcon classes={{ root: styles.Icon }} />
              {/* @ts-expect-error */}
              <Typography type={TYPOGRAPHY_TYPES.BODY}>No credit application</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Form__Container}>
        <div className={styles.Form__Content}>
          <div className={styles.Form__header}>
            {/* @ts-expect-error */}
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L}>Create your account</Typography>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email,
              phone: '',
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={signUpValidationSchema}
            onSubmit={handleSubmit}
            initialStatus={{
              APIError: undefined,
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormikFormField
                  fieldName="firstName"
                  touched={touched}
                  errors={errors}
                  label="First Name"
                  placeholder="Gordon"
                  className={styles.Input__fullWidth}
                />
                <FormikFormField
                  fieldName="lastName"
                  touched={touched}
                  errors={errors}
                  label="Last Name"
                  placeholder="Ramsey"
                  className={styles.Input__fullWidth}
                />
                <FormikFormField
                  fieldName="email"
                  touched={touched}
                  errors={errors}
                  disabled
                  className={styles.Input__fullWidth__Email}
                  fieldProps={{
                    type: 'email',
                  }}
                />
                <FormikFormField
                  fieldName="phone"
                  touched={touched}
                  errors={errors}
                  label="Phone"
                  placeholder="0400666666"
                  className={styles.Input__fullWidth}
                />
                <div className={styles.SignUp__Details__Form__Section}>
                  <FormikFormField
                    fieldName="password"
                    touched={touched}
                    errors={errors}
                    label="Create a password"
                    placeholder="password (8+ Characters)"
                    className={styles.Input__fullWidth}
                    fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'new-password' }}
                    endAdornment={
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={styles.PasswordField__endAdornment}
                        onClick={handleToggleShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                  <FormikFormField
                    fieldName="passwordConfirmation"
                    touched={touched}
                    errors={errors}
                    label="Password confirmation"
                    placeholder="Confirm password"
                    className={styles.Input__fullWidth}
                    fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'new-password' }}
                    endAdornment={
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={styles.PasswordField__endAdornment}
                        onClick={handleToggleShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                  <div className={styles.Submit__Btn__Container}>
                    {/* @ts-expect-error */}
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      type="submit"
                      className={styles.Submit__Btn}
                      disabled={Boolean(errors && Object.keys(errors).length) || isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                      ) : (
                        <React.Fragment>Join Team</React.Fragment>
                      )}
                    </Button>
                  </div>
                  {apiErrorMessage ? (
                    <ErrorNotification
                      title="We were unable to register you!"
                      body={apiErrorMessage}
                      hideContactInfo
                      className={styles.LoginForm__error}
                    />
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitationSignUp;
