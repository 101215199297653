import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { JOINED_TOGGLE_BTN_VARIANTS, BUTTON_VARIANTS, BUTTON_SIZES } from '../constants';

import styles from './JoinedToggleButton.module.scss';

/**
 * # Joined Toggle Button
 * Foodbomb Adjoining Button to act like a switch
 *
 * ### Variants
 * ```
 * ['regular', 'loggedOut']
 * ```
 *
 * ## PropTypes:
 * ```js
 * JoinedToggleButton.propTypes = {
 *   buttonALabel: PropTypes.string.isRequired,
 *   buttonBLabel: PropTypes.string.isRequired,
 *   onClickButtonA: PropTypes.func.isRequired,
 *   onClickButtonB: PropTypes.func.isRequired,
 *   buttonAActive: PropTypes.bool,
 *   buttonBActive: PropTypes.bool,
 *   variant: PropTypes.string,
 *   className: PropTypes.string,
 * };
 * ```
 *
 * ## Example
 * ```js
 * <Button
 *   variant="priamry"
 *   onClick={handleClick}
 * >Sign In</Button>
 * ```
 */

const JoinedToggleButton = ({
  buttonALabel,
  buttonBLabel,
  onClickButtonA,
  onClickButtonB,
  buttonAActive,
  buttonBActive,
  variant,
  className,
  buttonClassName,
  size,
  ...props
}) => {
  const primaryVariant =
    variant !== JOINED_TOGGLE_BTN_VARIANTS.LOGGED_OUT ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.LIGHT_PRIMARY;
  const secondaryVariant =
    variant !== JOINED_TOGGLE_BTN_VARIANTS.LOGGED_OUT ? BUTTON_VARIANTS.SECONDARY : BUTTON_VARIANTS.LIGHT_SECONDARY;

  return (
    <div className={[styles.JoinedToggleButtonContainer, className].join(' ')} {...props}>
      <Button
        onClick={onClickButtonA}
        variant={buttonAActive ? primaryVariant : secondaryVariant}
        className={[styles.JoinedToggleButton, buttonClassName].join(' ')}
        size={size}
      >
        {buttonALabel}
      </Button>
      <Button
        onClick={onClickButtonB}
        variant={buttonBActive ? primaryVariant : secondaryVariant}
        className={[styles.JoinedToggleButton, buttonClassName].join(' ')}
        size={size}
      >
        {buttonBLabel}
      </Button>
    </div>
  );
};

export default JoinedToggleButton;

JoinedToggleButton.propTypes = {
  buttonALabel: PropTypes.string.isRequired,
  buttonBLabel: PropTypes.string.isRequired,
  onClickButtonA: PropTypes.func.isRequired,
  onClickButtonB: PropTypes.func.isRequired,
  buttonAActive: PropTypes.bool,
  buttonBActive: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  buttonClassName: PropTypes.string,
};
