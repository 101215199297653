import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import Paper from '../Paper/Paper';
import Button from '../Button/Button';
import TableLoadingBackground from './TableLoadingBackground';
import styles from './Table.module.scss';

/**
 * # Table
 * Foodbomb Table Component with sorting, pagination and row actions
 *
 * ## PropTypes:
 * ```js
 *Table.propTypes = {
 *  columnSpecs: PropTypes.arrayOf(
 *    PropTypes.exact({
 *      index: PropTypes.number.isRequired,
 *      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
 *      mobileText: PropTypes.string,
 *      sortMethod: PropTypes.string,
 *      className: PropTypes.string,
 *      columnDataFunction: PropTypes.func.isRequired,
 *      searchable: PropTypes.bool,
 *      rightAlign: PropTypes.bool,
 *      getChild: PropTypes.func,
 *    }),
 *  ),
 *  rowActions: PropTypes.func,
 *  rowOverlayColSpan: (props, propName, componentName) => {
 *    if (props.rowActions && typeof props.rowActions === 'function' && props[propName] === undefined) {
 *      return new Error(`Missing prop '${propName}' must be supplied to ${componentName} if 'rowActions' is defined. `);
 *    }
 *    return 0;
 *  },
 *  handleRowClick: PropTypes.func,
 *  noResultsComponent: PropTypes.object.isRequired,
 *  className: PropTypes.string,
 *  numberOfRowsPerPage: PropTypes.number,
 *  searchQuery: PropTypes.string,
 *  isFiltering: PropTypes.bool,
 *  loadingRowData: PropTypes.bool,
 *  rowData: PropTypes.array.isRequired,
 *  stickyFirstColumn: PropTypes.bool,
 *  loadingComponent: PropTypes.node,
 *  grow: PropTypes.bool,
 *  noPadding: PropTypes.bool,
 *  hideMobileArrow: PropTypes.bool,
 *  hideNavigationData: PropTypes.bool,
 *  defaultSortColumn: PropTypes.number,
 *  sortAscending: PropTypes.bool,
 *  sortOverrideMethod: PropTypes.func,
 *  paginationOverrideData: PropTypes.exact({
 *    currentPageNumber: PropTypes.number.isRequired,
 *    onNextPageClick: PropTypes.func,
 *    onPreviousPageClick: PropTypes.func,
 *    pageSize: PropTypes.number.isRequired,
 *    numberOfResults: PropTypes.number.isRequired
 *    resetToPageOne: PropTypes.func,
 *  }),
 *  tableContentContainerStyle: PropTypes.string,
 *  useServerSidePagination: PropTypes.bool,
 *};
 * ```
 *
 * ## Table API
 *
 *| PROPERTY                | REQUIRED           | TYPE             | DESCRIPTION                                                                                                                                |
 *|-------------------------|--------------------|------------------|--------------------------------------------------------------------------------------------------------------------------------------------|
 *| className               | false              | string           | The CSS class to apply to the table                                                                                                        |
 *| columnSpecs             | true               | array of objects | Column specs which define the data and behaviour of each column. See example.                                                              |
 *| handleRowClick          | false              | function         | The function to be called when a row in the table is clicked.  NOTE: you may wish to trigger this on screens < 768px if you haveRowActions |
 *| rowOverlayColSpan       | true if rowActions | integer          | The number of columns to be taken up by the supplier row actions when hovering. Required if actions are supplied.                          |
 *| rowActions              | false              | function         | A function that returns a React.Fragment containing the buttons/text to be displayed on the row                                            |
 *| noResultsComponent      | true               | node             | The component to be displayed instead of the table, if the table has no results                                                            |
 *| numberOfRowsPerPage     | false              | number           | The number of rows to show per paginated page                                                                                              |
 *| searchQuery             | false              | string           | The current search query submitted to the parent component search bar (if present)                                                         |
 *| isFiltering             | false              | bool             | Is the current data set being filterd, hence should the no results replace the 0 results comp                                              |
 *| loadingRowData          | false              | bool             | Boolean value representing if the rowData variable is still loading from an async function                                                 |
 *| rowData                 | true               | array            | Array of rows of data to display matching the column specs                                                                                 |
 *| paginationOverrideData  |  false             | object           | Object with override information for pagination                                                                                            |
 *| stickyFirstColumn       | false              | bool             | Should the first column be sticky to the left on mobile devices                                                                            |
 *| loadingComponent        | false              | object           | Optional loading component to override the default                                                                                         |
 *| grow                    | false              | bool             | Should the component expand to take up its container                                                                                       |
 *| noPadding               | false              | bool             | Should the component remove all padding from its container                                                                                 |
 *| hideMobileArrow         | false              | bool             | Should hide the mobile green arrow                                                                                                         |
 *| hideNavigationData      | false              | bool             | Should hide the "showing x / y" results and navigational elements                                                                          |
 *| defaultSortColumn       | false              | number           | Allows for the selection of the default sort column by providing the column ID. First column used if none provided.                        |
 *| sortAscending           | false              | bool             | Allows for the selection of the default sort direction for the default sort column                                                         |
 *| sortOverrideMethod      | false              | func             | Callback function to override sorting and enable sorting to happen outside the table. Returns valueToSortBy and shouldSortByMin            |
 *| useServerSidePagination | false              | bool             | Flag to specify if the implementor is using serverSidePagination (used in combination with paginationOverrideData to handle pagination)    |
 *
 * ## Column Spec Definition
 * ```
 * const columnSpecs = [
 *   {
 *      index: 0,                                                --> Columns are zero indexed
 *      title: 'Price',
 *      mobileText: 'mobile column title',
 *      sortMethod: 'totals.grandTotal',                      --> This is a string to reference which column is currently being sorted
 *      className: styles.Price,
 *      columnDataFunction: order => order.totals.grandTotal, --> How to get the data to be displayed
 *      getChild: createPriceDisplay,                         --> Optional: Provide this if you want to display another widget instead of displaying the result of the column data function
 *      rightAlign: true,                                     --> if the column data must be right aligned
 *
 *   },
 * ]
 *
 *
 */

const Table = ({
  columnSpecs,
  rowActions,
  handleRowClick,
  noResultsComponent,
  className,
  rowOverlayColSpan, // equivalent to number of actions to be added
  numberOfRowsPerPage,
  searchQuery,
  isFiltering,
  loadingRowData,
  rowData,
  paginationOverrideData,
  stickyFirstColumn,
  loadingComponent,
  grow,
  noPadding,
  hideMobileArrow,
  hideNavigationData,
  defaultSortColumn,
  sortAscending,
  sortOverrideMethod,
  tableContentContainerStyle,
  useServerSidePagination,
}) => {
  const HAS_PAGINATION_OVERRIDE_DATA = paginationOverrideData && Object.keys(paginationOverrideData).length;
  const NUMBER_OF_ROWS_PER_PAGE = HAS_PAGINATION_OVERRIDE_DATA
    ? paginationOverrideData.pageSize
    : numberOfRowsPerPage || 15;
  const [sortedRowData, setSortedRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [flipMobileArrow, setFlipMobileArrow] = useState(true);
  const [sortByMin, setSortByMin] = useState(sortAscending === undefined ? true : sortAscending);
  const [tableSortMethod, setTableSortMethod] = useState(columnSpecs[defaultSortColumn || 0].sortMethod);

  const currentlyVisiblePage = HAS_PAGINATION_OVERRIDE_DATA ? paginationOverrideData.currentPageNumber : currentPage;

  const scrollableTableContainer = useRef();

  const currentPageMinLimit = (currentlyVisiblePage - 1) * NUMBER_OF_ROWS_PER_PAGE + 1;
  const currentPageMaxLimit = currentlyVisiblePage * NUMBER_OF_ROWS_PER_PAGE;
  const currentPageToDisplay = useServerSidePagination ? currentPage : currentlyVisiblePage;

  const dataLength = HAS_PAGINATION_OVERRIDE_DATA ? paginationOverrideData.numberOfResults : sortedRowData.length;
  const paginatedElements = sortedRowData.slice(
    (currentPageToDisplay - 1) * NUMBER_OF_ROWS_PER_PAGE,
    currentPageToDisplay * NUMBER_OF_ROWS_PER_PAGE,
  );

  const scrollTableToEnd = () => {
    scrollableTableContainer.current.scroll({ left: 1500, behavior: 'smooth' });
  };

  const scrollTableToStart = () => {
    scrollableTableContainer.current.scroll({ left: 0, behavior: 'smooth' });
  };

  const handleFlipMobileArrow = () => {
    if (flipMobileArrow) {
      scrollTableToEnd();
    } else {
      scrollTableToStart();
    }
  };

  const handleScrollTableContainer = () => {
    const currentScrollPosition = scrollableTableContainer.current.scrollLeft;
    const elemWidth = scrollableTableContainer.current.scrollWidth;
    const viewPortWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const offset = 0;

    const percentageScrolled = (currentScrollPosition + viewPortWidth - offset) / elemWidth;
    setFlipMobileArrow(percentageScrolled <= 0.9);
  };

  const incrementCurrentPage = () => {
    if (HAS_PAGINATION_OVERRIDE_DATA) {
      paginationOverrideData.onNextPageClick();
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const decrementCurrentPage = () => {
    if (HAS_PAGINATION_OVERRIDE_DATA) {
      paginationOverrideData.onPreviousPageClick();
    } else if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  const sortElements = (elementsToSort, sortField, shouldSortByMin) =>
    elementsToSort.sort((a, b) => {
      const value1 = sortField.split('.').reduce((o, k) => o && o[k], !shouldSortByMin ? b : a);
      const value2 = sortField.split('.').reduce((o, k) => o && o[k], !shouldSortByMin ? a : b);
      if (value1 === undefined || value1 === null) {
        return 0;
      }
      if (value2 === undefined || value2 === null) {
        return 1;
      }
      if (typeof value1 === 'number' || value1 instanceof Date) {
        return value1 - value2;
      }
      if (typeof value1 === 'string') {
        return value1.localeCompare(value2);
      }
      if (typeof value1 === 'boolean') {
        if (value1 === value2) {
          return 0;
        }
        return value1 ? -1 : 1;
      }
      return -1;
    });

  const setSortMethod = (valueToSortBy) => {
    const shouldSortByMin = valueToSortBy === tableSortMethod ? !sortByMin : false;
    if (sortOverrideMethod) {
      sortOverrideMethod(valueToSortBy, shouldSortByMin);
    } else {
      const sortedElements = sortElements(rowData, valueToSortBy, shouldSortByMin);
      setSortedRowData(sortedElements);
    }
    setTableSortMethod(valueToSortBy);
    setSortByMin(shouldSortByMin);
    resetCurrentPage();
    if (HAS_PAGINATION_OVERRIDE_DATA && paginationOverrideData?.resetToPageOne) {
      paginationOverrideData.resetToPageOne();
    }
  };

  useEffect(() => {
    const sortedElements = tableSortMethod ? sortElements(rowData, tableSortMethod, sortByMin) : rowData;
    setSortedRowData(sortedElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, loadingRowData]);

  useEffect(() => {
    if (!HAS_PAGINATION_OVERRIDE_DATA) {
      resetCurrentPage();
    }
  }, [searchQuery, rowData, HAS_PAGINATION_OVERRIDE_DATA]);

  const getTableHeaderClasses = (currentSortMethod, columnSortMethod) => {
    if (columnSortMethod) {
      if (currentSortMethod === columnSortMethod) {
        return [styles.TableHeaderContainer, styles.Clickable, styles.active].join(' ');
      }
      return [styles.TableHeaderContainer, styles.Clickable].join(' ');
    }
    return styles.TableHeaderContainer;
  };

  const createTableCell = (columnSpec, rowElement, hideOnHover) => {
    const cellClass = hideOnHover
      ? [styles.TableCell__defaultShow, columnSpec.className].join(' ')
      : columnSpec.className;
    return (
      <React.Fragment
        key={`cell_${columnSpec.index}_${Object.values(rowElement)[0]}_${columnSpec.columnDataFunction(
          rowElement,
        )}_${hideOnHover}`}
      >
        {columnSpec.index === 0 ? (
          <th className={cellClass}>
            <div
              className={
                columnSpec.rightAlign
                  ? [styles.MobileWhiteBackgroundFaker, styles.RightAlign].join(' ')
                  : styles.MobileWhiteBackgroundFaker
              }
            >
              {columnSpec.getChild ? columnSpec.getChild(rowElement) : columnSpec.columnDataFunction(rowElement)}
            </div>
            {stickyFirstColumn ? <div className={styles.MobileBoxShadowFaker}></div> : null}
          </th>
        ) : (
          <td className={columnSpec.rightAlign ? [styles.RightAlign, cellClass].join(' ') : cellClass}>
            {columnSpec.getChild ? columnSpec.getChild(rowElement) : columnSpec.columnDataFunction(rowElement)}
          </td>
        )}
      </React.Fragment>
    );
  };

  const tableContentStyles = [styles.Table__content];
  if (grow) {
    tableContentStyles.push(styles.grow);
  }
  if (noPadding) {
    tableContentStyles.push(styles.noPadding);
  }

  const potentiallyHandleRowClick = (e, elem) => {
    const excludedTagNames = ['INPUT', 'BUTTON'];
    if (!excludedTagNames.includes(e.target.tagName) && handleRowClick) {
      handleRowClick(elem);
    }
  };

  const contentContainerStyles = [styles.Table__contentContainer];
  if (tableContentContainerStyle) {
    contentContainerStyles.push(tableContentContainerStyle);
  }

  return (
    <div>
      <div className={contentContainerStyles.join(' ')}>
        <div className={tableContentStyles.join(' ')}>
          {loadingRowData ? (
            <React.Fragment>
              {loadingComponent || (
                <Paper className={styles.LoadingTablePaperWrapper}>
                  <TableLoadingBackground />
                </Paper>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isFiltering || searchQuery || rowData.length ? (
                <Paper className={styles.TablePaperWrapper}>
                  <div
                    className={styles.TableContainer}
                    ref={scrollableTableContainer}
                    onScroll={handleScrollTableContainer}
                  >
                    {hideMobileArrow ? null : (
                      <button className={styles.MobileArrowContainer} onClick={handleFlipMobileArrow}>
                        <div
                          className={
                            flipMobileArrow
                              ? [styles.ReversibleArrow, styles.reverse].join(' ')
                              : styles.ReversibleArrow
                          }
                        >
                          <span className={styles.TopBar}></span>
                          <span className={styles.BottomBar}></span>
                        </div>
                      </button>
                    )}
                    <table
                      className={
                        stickyFirstColumn
                          ? [styles.Table, className, styles.stickyFirstColumn].join(' ')
                          : [styles.Table, className].join(' ')
                      }
                    >
                      <thead>
                        <tr className={styles.Table__row}>
                          {columnSpecs.map((col, idx) => (
                            <th key={`col_${col.index}_col.title`} className={[col.className].join(' ')}>
                              <div
                                className={
                                  col.rightAlign
                                    ? [styles.MobileWhiteBackgroundFaker, styles.RightAlign].join(' ')
                                    : styles.MobileWhiteBackgroundFaker
                                }
                              >
                                <button
                                  className={getTableHeaderClasses(tableSortMethod, col.sortMethod)}
                                  onClick={col.sortMethod ? () => setSortMethod(col.sortMethod) : undefined}
                                >
                                  <div className={col.mobileText ? styles.HideMobile : undefined}>{col.title}</div>
                                  {col.mobileText ? <div className={styles.HideDesktop}>{col.mobileText}</div> : null}
                                  {col.sortMethod ? (
                                    <SwapVertIcon
                                      className={
                                        tableSortMethod === col.sortMethod
                                          ? [styles.SortByIcon, styles.active].join(' ')
                                          : styles.SortByIcon
                                      }
                                    />
                                  ) : null}
                                </button>
                              </div>
                              {idx === 0 && stickyFirstColumn ? (
                                <div className={styles.MobileBoxShadowFaker}></div>
                              ) : null}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedElements.map((elem) => (
                          <tr
                            key={`elem_${Object.values(elem).join('_')}`}
                            className={[styles.Table__row, styles.bodyRow].join(' ')}
                            onClick={(e) => potentiallyHandleRowClick(e, elem)}
                          >
                            {columnSpecs.map((spec) => {
                              // if there are no row actions - just render the columns
                              if (!rowActions) {
                                return createTableCell(spec, elem, false);
                              }
                              if (spec.index < columnSpecs.length - rowOverlayColSpan) {
                                return createTableCell(spec, elem, false);
                              }
                              if (spec.index === columnSpecs.length - rowOverlayColSpan) {
                                return (
                                  <React.Fragment
                                    key={`overlayColumn_${JSON.stringify(Object.values(elem).join('_'))}`}
                                  >
                                    <td
                                      colSpan={rowOverlayColSpan}
                                      className={[styles.Grow, styles.TableCell__showOnHover].join(' ')}
                                    >
                                      <div className={styles.TableFlexContainer}>{rowActions(elem)}</div>
                                    </td>
                                    {createTableCell(spec, elem, true)}
                                  </React.Fragment>
                                );
                              }
                              return createTableCell(spec, elem, true);
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {!hideNavigationData ? (
                    <React.Fragment>
                      <div className={styles.ElementsCountContainer}>
                        {dataLength ? (
                          <React.Fragment>
                            Showing rows {currentPageMinLimit} -{' '}
                            {currentPageMaxLimit < dataLength ? currentPageMaxLimit : dataLength} of&nbsp;
                            {dataLength}
                            {searchQuery ? ` for search "${searchQuery}"` : null}
                            {isFiltering ? ` in current filter` : null}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            No rows to display
                            {searchQuery ? ` for search "${searchQuery}"` : null}
                            {isFiltering ? ` in current filter` : null}
                          </React.Fragment>
                        )}
                      </div>

                      {dataLength > NUMBER_OF_ROWS_PER_PAGE ? (
                        <div className={styles.PaginationButtonsContainer}>
                          <Button
                            className={[styles.PaginationBtn, styles.PaginationBtn__prev].join(' ')}
                            variant="secondary"
                            size="extra_small"
                            disabled={currentlyVisiblePage === 1}
                            onClick={decrementCurrentPage}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          Page {currentlyVisiblePage} of {Math.max(Math.ceil(dataLength / NUMBER_OF_ROWS_PER_PAGE), 1)}
                          <Button
                            className={[styles.PaginationBtn, styles.PaginationBtn__next].join(' ')}
                            variant="secondary"
                            size="extra_small"
                            onClick={incrementCurrentPage}
                            disabled={currentlyVisiblePage >= Math.ceil(dataLength / NUMBER_OF_ROWS_PER_PAGE)}
                          >
                            <NavigateNextIcon />
                          </Button>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </Paper>
              ) : (
                <div>{noResultsComponent}</div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  columnSpecs: PropTypes.arrayOf(
    PropTypes.exact({
      index: PropTypes.number.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
      mobileText: PropTypes.string,
      sortMethod: PropTypes.string,
      className: PropTypes.string,
      columnDataFunction: PropTypes.func.isRequired,
      searchable: PropTypes.bool,
      rightAlign: PropTypes.bool,
      getChild: PropTypes.func,
    }),
  ),
  rowActions: PropTypes.func,
  rowOverlayColSpan: (props, propName, componentName) => {
    if (props.rowActions && typeof props.rowActions === 'function' && props[propName] === undefined) {
      return new Error(`Missing prop '${propName}' must be supplied to ${componentName} if 'rowActions' is defined. `);
    }
    return 0;
  },
  paginationOverrideData: PropTypes.exact({
    currentPageNumber: PropTypes.number.isRequired,
    onNextPageClick: PropTypes.func,
    onPreviousPageClick: PropTypes.func,
    pageSize: PropTypes.number.isRequired,
    numberOfResults: PropTypes.number.isRequired,
    resetToPageOne: PropTypes.func,
  }),
  handleRowClick: PropTypes.func,
  noResultsComponent: PropTypes.object.isRequired,
  className: PropTypes.string,
  numberOfRowsPerPage: PropTypes.number,
  searchQuery: PropTypes.string,
  isFiltering: PropTypes.bool,
  loadingRowData: PropTypes.bool,
  rowData: PropTypes.array.isRequired,
  stickyFirstColumn: PropTypes.bool,
  loadingComponent: PropTypes.object,
  grow: PropTypes.bool,
  noPadding: PropTypes.bool,
  hideMobileArrow: PropTypes.bool,
  hideNavigationData: PropTypes.bool,
  defaultSortColumn: PropTypes.number,
  sortAscending: PropTypes.bool,
  sortOverrideMethod: PropTypes.func,
  tableContentContainerStyle: PropTypes.string,
  useServerSidePagination: PropTypes.bool,
};
export default Table;
