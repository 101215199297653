import { IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import useRedirect from '../../../hooks/useRedirect/useRedirect.ts';
import { BUTTON_VARIANTS } from '../../../utils/Constants';
import { Button } from '../../UI/FB';
import styles from './WebsiteNavigation.module.scss';

const UnauthenticatedNavigation = ({ logo, handleMobileDrawerClick, toggleClasses, closeMobileDrawer }) => {
  const { redirectToPublicBookACallPage, redirectToLogin, redirectToLoggedOutHomePage } = useRedirect();
  const closeMobileDrawerAndRedirectToLoggedOutHomePage = () => {
    closeMobileDrawer();
    redirectToLoggedOutHomePage();
  };

  return (
    <React.Fragment>
      <div className={[styles.AppBar__Content, styles.AppBar__DesktopContent].join(' ')}>
        <div className={styles.AppBar__Left}>
          <Link to="#" className={styles.LogoBtn} onClick={closeMobileDrawerAndRedirectToLoggedOutHomePage}>
            <img src={logo} className={styles.AppBar__logo} alt="" />
          </Link>
        </div>
        <div className={styles.AppBar__Right}>
          <div className={styles.AppBar__ButtonContainer}>
            <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={redirectToLogin}>
              Sign In
            </Button>

            <Button
              className={styles.BookACall__button}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={redirectToPublicBookACallPage}
            >
              <span className={[styles.BookACall__buttonText, styles.Desktop].join(' ')}>Talk to us</span>
            </Button>
          </div>
        </div>
      </div>
      <div className={[styles.AppBar__Content, styles.AppBar__MobileContent].join(' ')}>
        <div className={styles.AppBar__Left}>
          <IconButton onClick={handleMobileDrawerClick}>
            <div className={toggleClasses.join(' ')}>
              <div className={[styles.DrawToggle__spinner, styles.spinner1].join(' ')} />
              <div className={[styles.DrawToggle__spinner, styles.spinner2].join(' ')} />
              <div className={[styles.DrawToggle__spinner, styles.spinner3].join(' ')} />
            </div>
          </IconButton>
        </div>
        <div className={styles.AppBar__Center}>
          <Link onClick={closeMobileDrawerAndRedirectToLoggedOutHomePage} to="#">
            <img src={logo} className={styles.AppBar__logo} alt="" />
          </Link>
        </div>
        <div className={styles.AppBar__Right}>
          <IconButton
            onClick={redirectToLogin}
            aria-label="login to foodbomb"
            className={[styles.AppBar__Icon, styles.light].join(' ')}
          >
            <AccountCircle />
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );
};

UnauthenticatedNavigation.propTypes = {
  logo: PropTypes.string.isRequired,
  handleMobileDrawerClick: PropTypes.func.isRequired,
  toggleClasses: PropTypes.array.isRequired,
  closeMobileDrawer: PropTypes.func.isRequired,
};

export default UnauthenticatedNavigation;
