import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import AdminContext from './AdminContext';
import { onMobileDevice } from '../../utils/ScreenUtils';

class AdminProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnToAdminLink: undefined,
      hideMasqueradingBanner: onMobileDevice(),
    };
  }

  componentDidMount() {
    this.potentiallyExtractReferrer();
  }

  onHideMasqueradingBanner = () => {
    this.setState((prevState) => ({
      hideMasqueradingBanner: !prevState.hideMasqueradingBanner,
    }));
  };

  potentiallyExtractReferrer = () => {
    const filterQuery = queryString.parse(this.props.location.search);
    if (Object.keys(filterQuery).includes('returnToAdminLink'))
      this.setState({ returnToAdminLink: filterQuery.returnToAdminLink });
  };

  render() {
    const { children } = this.props;
    const { returnToAdminLink, hideMasqueradingBanner } = this.state;

    return (
      <AdminContext.Provider
        value={{
          returnToAdminLink,
          hideMasqueradingBanner,
          onHideMasqueradingBanner: this.onHideMasqueradingBanner,
        }}
      >
        {children}
      </AdminContext.Provider>
    );
  }
}

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(AdminProvider);
