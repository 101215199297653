import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './TextField.module.scss';

/**
 * # TextField
 * Simple Foodbomb Textfield Component
 *
 * ## PropTypes:
 * ```js
 *  TextField.propTypes = {
 *    className: PropTypes.string,
 *  };
 * ```
 */

const FBTextField = ({ className, ...props }) => (
  <TextField
    className={[styles.TextField, className].join(' ')}
    InputLabelProps={{
      classes: {
        root: styles.TextField__label,
        focused: styles.TextField__labelFocused,
      },
    }}
    InputProps={{
      classes: {
        input: styles.TextField__input,
        underline: styles.TextField__underline,
        focused: styles.TextField__focused,
      },
    }}
    {...props}
  />
);

FBTextField.propTypes = {
  className: PropTypes.string,
};

export default FBTextField;
