import PropTypes from 'prop-types';
import Paper from '../Paper/Paper';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { TYPOGRAPHY_TYPES } from '../constants';
import styles from './NoResults.module.scss';

const NoResults = ({ title, redirectForNoResults, redirectButtonText, children }) => (
  <Paper className={styles.NoResultsContainer}>
    <div className={styles.TypographySection}>
      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
        <span role="img" aria-label="heartbreak">
          👋
        </span>
      </Typography>
      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>{title}</Typography>
    </div>
    {children}
    <div className={styles.NoResultsBtnContainer}>
      <Button onClick={redirectForNoResults} className={styles.NoResultsBtn}>
        {redirectButtonText}
      </Button>
    </div>
  </Paper>
);

NoResults.propTypes = {
  title: PropTypes.string.isRequired,
  redirectForNoResults: PropTypes.func.isRequired,
  redirectButtonText: PropTypes.string.isRequired,
  children: PropTypes.object,
};

export default NoResults;
