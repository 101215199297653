import { CircularProgress } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { FoodbombAPI } from '../../../AxiosInstances';
import useEventTracking from '../../../hooks/useEventTracking/useEventTracking';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import { ADDRESS_ERROR_STATES, BUTTON_VARIANTS, NOTIFICATION_MESSAGE_TYPES } from '../../../utils/Constants';
import { Button, FormikFormField, NotificationMessageSection } from '../../UI/FB';
import AutoCompleteError from '../../UI/GoogleAutoCompleteFields/AutoCompleteError/AutoCompleteError';
import LocationSearchField from '../../UI/GoogleAutoCompleteFields/LocationSearchField/LocationSearchField';
import SuburbSearchField from '../../UI/GoogleAutoCompleteFields/SuburbSearchField/SuburbSearchField';
import styles from './SuburbCheckForm.module.scss';

type SuburbType = {
  suburb: string;
  postcode: number;
  displayAddress: string;
  streetAddress: string;
};

type AddressType = {
  suburb: string;
  postcode: number;
  displayAddress: string;
  streetAddress: string;
};

type FormAddressType = {
  suburb: string;
  postcode: string;
  displayAddress?: string;
  streetAddress?: string;
};

interface RequiredFormAddressType extends Omit<FormAddressType, 'displayAddress' | 'streetAddress'> {
  displayAddress: string;
  streetAddress: string;
}

type SuburbCheckFormTypes = {
  customerType: string;
  onSuccess: (address: any, postcode: number) => void;
  onFailure: (address: any) => void;
  onBack: () => void;
  hideBackButton?: boolean;
  initialAddress?: AddressType;
  fillAddressOnly?: boolean;
  handleManualAddressOveride?: () => void;
  showManualAddressEntry?: boolean;
  isMultiStepFormWithRealTimeValidation?: boolean;
};

const SuburbCheckForm: FC<SuburbCheckFormTypes> = ({
  customerType,
  onSuccess,
  onFailure,
  onBack,
  hideBackButton,
  initialAddress,
  fillAddressOnly,
  handleManualAddressOveride,
  showManualAddressEntry,
  isMultiStepFormWithRealTimeValidation,
}) => {
  const [errorState, setErrorState] = useState<string | undefined>(undefined);
  const [suburbSuccess, setSuburbSuccess] = useState<boolean>(false);
  const [checkSuburbFailure, setCheckSuburbFailure] = useState<boolean>(false);
  const { trackAttemptSuburbCheck } = useEventTracking();
  const { sendDatadogError } = useDDErrorReporting();

  const checkSuppliers = async (address: AddressType, setSubmitting: (val: boolean) => void): Promise<number> => {
    trackAttemptSuburbCheck({ suburb: address.suburb, postcode: address.postcode });
    const checkEndpoint = `check-suburb-suppliers?suburb=${address.suburb}&postcode=${address.postcode}&type=${customerType}`;
    let supplierCount = 0;

    try {
      const postcodeResponse = await FoodbombAPI.get(checkEndpoint);
      setSubmitting(false);
      supplierCount = postcodeResponse.data.numberOfSuppliers;
    } catch (error: any) {
      sendDatadogError('Unable to check the postcode for suppliers', {
        error,
        location: 'Postcode Check form in sign up modal',
      });
      setErrorState(ADDRESS_ERROR_STATES.DEFAULT_ERROR);
      setSubmitting(false);
    }
    return supplierCount;
  };

  const buildAddressFromFormValues = (values: FormAddressType): AddressType => {
    const streetAddress = values.streetAddress ? `${values.streetAddress},` : '';
    return {
      ...values,
      postcode: parseInt(values.postcode, 10),
      suburb: values.suburb.replace('.', ''),
      displayAddress: values.displayAddress || `${streetAddress} ${values.suburb.replace('.', '')}, ${values.postcode}`,
      streetAddress: values.streetAddress || '',
    };
  };

  const checkSuppliersForPostcode = async (
    values: FormAddressType,
    actions: FormikHelpers<RequiredFormAddressType>,
  ) => {
    const address = buildAddressFromFormValues(values);
    const numberOfSuppliers = await checkSuppliers(address, actions.setSubmitting);
    if (numberOfSuppliers >= 1) {
      onSuccess(address, numberOfSuppliers);
    } else {
      onFailure(address);
    }
  };

  const checkSuppliersForPostcodeInAddVenue = async (
    values: FormAddressType,
    setSubmitting: (val: boolean) => void,
  ) => {
    const address = buildAddressFromFormValues(values);
    const numberOfSuppliers = await checkSuppliers(address, setSubmitting);
    if (numberOfSuppliers >= 1) {
      setSuburbSuccess(true);
    } else {
      setSuburbSuccess(false);
      setCheckSuburbFailure(true);
    }
  };

  const clearErrors = () => {
    setCheckSuburbFailure(false);
    setErrorState(undefined);
    setSuburbSuccess(false);
  };

  const handleAddressError = (errorType: string) => {
    setErrorState(errorType);
  };

  const addressValidationSchema = Yup.object().shape({
    suburb: Yup.string()
      .trim()
      .max(128, "your suburb can't have more than 128 characters")
      .required('Suburb is required'),
    postcode: Yup.number()
      .integer()
      .typeError('Must be a valid Australian postcode')
      .max(8000, 'Must be a valid Australian postcode')
      .min(800, 'Must be a valid Australian postcode')
      .required('Postcode is required'),
    streetAddress: showManualAddressEntry ? Yup.string().required('Address is required') : Yup.string(),
  });

  const handleNext = (values: FormAddressType) => {
    const address = buildAddressFromFormValues(values);
    onSuccess(address, 1);
  };

  const buttonContainerStyles = [styles.ButtonContainerOverride];

  if (showManualAddressEntry) {
    buttonContainerStyles.push(styles.ManualAddress__extraHeight);
  }

  if (suburbSuccess) {
    buttonContainerStyles.push(styles.ManualAddress__maxHeight);
  }

  return (
    <Formik
      initialValues={{
        suburb: initialAddress?.suburb || '',
        postcode: '',
        displayAddress: initialAddress?.displayAddress || '',
        streetAddress: initialAddress?.streetAddress || '',
      }}
      validationSchema={addressValidationSchema}
      onSubmit={isMultiStepFormWithRealTimeValidation ? handleNext : checkSuppliersForPostcode}
    >
      {({ setValues, touched, errors, values, isSubmitting, setTouched, setSubmitting }) => (
        <Form className={isMultiStepFormWithRealTimeValidation ? styles.FormOverride : undefined}>
          <div>
            {isMultiStepFormWithRealTimeValidation ? (
              <AutoCompleteError errorState={errorState} openManualAddressEntry={handleManualAddressOveride} noLink />
            ) : null}
            {checkSuburbFailure ? (
              <NotificationMessageSection className={styles.NegativeMargin} type={NOTIFICATION_MESSAGE_TYPES.WARNING}>
                None of our suppliers currently deliver to your address.
              </NotificationMessageSection>
            ) : null}
            {suburbSuccess ? (
              <NotificationMessageSection className={styles.NegativeMargin} type={NOTIFICATION_MESSAGE_TYPES.SUCCESS}>
                Our suppliers deliver to your address!
              </NotificationMessageSection>
            ) : null}
            {fillAddressOnly ? (
              <LocationSearchField
                handleAddressError={handleAddressError}
                handleManualAddressOveride={handleManualAddressOveride}
                handleSelectAddress={({ postcode, suburb, displayAddress, streetAddress }: AddressType) => {
                  setTouched({ ...touched, postcode: true, suburb: true });
                  setValues({ ...values, postcode: `${postcode}`, suburb, displayAddress, streetAddress });
                  clearErrors();
                }}
                clearErrors={clearErrors}
                onSuburbSelected={(addressObject: FormAddressType) =>
                  checkSuppliersForPostcodeInAddVenue(addressObject, setSubmitting)
                }
                showManualAddressOverride
              />
            ) : (
              <>
                {showManualAddressEntry ? (
                  <div className={styles.SuburbSearchField}>
                    <div className={styles.SuburbSearch__inputContainer}>
                      <FormikFormField
                        fieldName="streetAddress"
                        placeholder="123 Main Street"
                        label="Venue Address"
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  </div>
                ) : null}
                <SuburbSearchField
                  handleSelectSuburb={({ postcode, suburb }: SuburbType) => {
                    setTouched({ ...touched, postcode: true, suburb: true });
                    setValues({ ...values, postcode: `${postcode}`, suburb });
                    clearErrors();
                  }}
                  handleAddressError={handleAddressError}
                  errors={errors}
                  touched={touched}
                  showManualAddressEntry={showManualAddressEntry}
                  handleClearSuburb={() => {
                    setTouched({ ...touched, suburb: true, postcode: true });
                    setValues({ ...values, suburb: '', postcode: '' });
                    clearErrors();
                  }}
                  dropDownOverride={isMultiStepFormWithRealTimeValidation}
                  onSuburbSelected={
                    isMultiStepFormWithRealTimeValidation
                      ? (addressObject: FormAddressType) => {
                          const { streetAddress } = values;
                          const { suburb, postcode } = addressObject;
                          const googleStreetAddress = addressObject.streetAddress;
                          checkSuppliersForPostcodeInAddVenue(
                            { streetAddress: streetAddress || googleStreetAddress, suburb, postcode },
                            setSubmitting,
                          );
                        }
                      : undefined
                  }
                />
              </>
            )}
          </div>
          {isMultiStepFormWithRealTimeValidation ? null : <AutoCompleteError errorState={errorState} noLink />}
          <div
            className={isMultiStepFormWithRealTimeValidation ? buttonContainerStyles.join(' ') : styles.ButtonContainer}
          >
            {hideBackButton ? null : (
              <>
                {isMultiStepFormWithRealTimeValidation ? (
                  /** @ts-expect-error */
                  <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={onBack} className={styles.BackButtonOverride}>
                    Go Back
                  </Button>
                ) : (
                  <button onClick={onBack} className={styles.BackButton}>
                    Go Back
                  </button>
                )}
              </>
            )}
            {/** @ts-expect-error */}
            <Button
              type="submit"
              variant={BUTTON_VARIANTS.SECONDARY}
              className={isMultiStepFormWithRealTimeValidation ? styles.SubmitButtonOverride : styles.SubmitButton}
              disabled={
                isMultiStepFormWithRealTimeValidation
                  ? !suburbSuccess ||
                    checkSuburbFailure ||
                    isSubmitting ||
                    Boolean(errors && Object.keys(errors).length)
                  : isSubmitting || Boolean(errors && Object.keys(errors).length)
              }
            >
              {isSubmitting ? (
                <CircularProgress size={24} className={styles.SubmitButton__loading} />
              ) : (
                <>{<>Check</>}</>
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SuburbCheckForm.propTypes = {
  customerType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  hideBackButton: PropTypes.bool,
  initialAddress: PropTypes.any,
  handleManualAddressOveride: PropTypes.func,
  fillAddressOnly: PropTypes.bool,
  showManualAddressEntry: PropTypes.bool,
  isMultiStepFormWithRealTimeValidation: PropTypes.bool,
};

export default SuburbCheckForm;
