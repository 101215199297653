const MOBILE_BREAKPOINT = 768;
const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const onDesktopDevice = () => getWindowWidth() > MOBILE_BREAKPOINT;

export const onMobileDevice = () => !onDesktopDevice();

// Check the viewport is wider than max width with a cart on each side
export const wideEnoughToDockCart = () => getWindowWidth() > 1230 + 380 + 380;

export const isIOS = () => process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
