import { useLocation, useHistory } from 'react-router-dom';
import useEventTracking from '../../hooks/useEventTracking/useEventTracking.ts';
import useRedirect from '../../hooks/useRedirect/useRedirect.ts';

const WithRedirectHelper = (WrappedComponent) => {
  const WithRedirectHelperWrapper = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { trackEvent } = useEventTracking();
    const redirectFunctions = useRedirect();

    return (
      <WrappedComponent
        history={history}
        location={location}
        trackEvent={trackEvent}
        {...redirectFunctions}
        {...props}
      />
    );
  };

  return WithRedirectHelperWrapper;
};

export default WithRedirectHelper;
