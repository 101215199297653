import PropTypes from 'prop-types';

import styles from './Paper.module.scss';

/**
 * # Paper
 * Simple Foodbomb Paper Wrapper Component
 *
 * ## PropTypes:
 * ```js
 * FBPaper.propTypes = {
 *   children: PropTypes.node,
 *   className: PropTypes.string,
 * };
 * ```
 */

const FBPaper = ({ children, className, ...props }) => {
  const classes = [styles.FBPaper];
  classes.push(className);

  return (
    <div {...props} className={classes.join(' ')}>
      {children}
    </div>
  );
};

FBPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FBPaper;
