import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import BlockIcon from '@material-ui/icons/Block';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './Checkbox.module.scss';

/**
 * # Checkbox
 * Simple Foodbomb Checkbox Component
 * If a label prop is provided the checkbox will become a checkbox with label
 *
 * ## Modifiers
 * ### Variants
 * ```
 * circle (bool)
 * ```
 *
 * ## PropTypes:
 * ```js
 *  FBCheckbox.propTypes = {
 *    checked: PropTypes.bool,
 *    onChange: PropTypes.func,
 *    value: PropTypes.any,
 *    checkboxProps: PropTypes.object,
 *    circle: PropTypes.bool,
 *    className: PropTypes.string,
 *  };
 * ```
 */
const FBCheckbox = ({ checked, onChange, value, circle, className, label, labelClasses, disabled, ...props }) => {
  let icon = circle ? <RadioButtonUncheckedIcon /> : undefined;
  const checkboxRootStyles = [styles.Checkbox__root, className];

  const formControlLabelClasses = [styles.Label, labelClasses];
  if (disabled) {
    formControlLabelClasses.push(styles.disabled);
    checkboxRootStyles.push(styles.disabled);
    icon = <BlockIcon />;
  }
  if (checked) {
    formControlLabelClasses.push(styles.checked);
    checkboxRootStyles.push(styles.checked);
  }

  if (label) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            value={value}
            classes={{
              root: checkboxRootStyles.join(' '),
            }}
            icon={icon}
            checkedIcon={circle ? <CheckCircleIcon fontSize="small" /> : undefined}
            {...props}
          />
        }
        label={label}
        classes={{
          label: formControlLabelClasses.join(' '),
        }}
      />
    );
  }

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      value={value}
      classes={{
        root: checkboxRootStyles.join(' '),
      }}
      icon={icon}
      checkedIcon={circle ? <CheckCircleIcon fontSize="small" /> : undefined}
      {...props}
    />
  );
};

FBCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  checkboxProps: PropTypes.object,
  circle: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FBCheckbox;
