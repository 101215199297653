import { FC } from 'react';
import PropTypes from 'prop-types';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Typography } from '../FB';
import styles from './FullWidthCSSLoadingSpinner.module.scss';

type FullWidthCSSLoadingSpinnerProps = {
  loadingMessage?: string;
  small?: boolean;
};

const FullWidthCSSLoadingSpinner: FC<FullWidthCSSLoadingSpinnerProps> = ({ loadingMessage, small }) => (
  <div className={small ? [styles.CSSSpinnerContainer, styles.small].join(' ') : styles.CSSSpinnerContainer}>
    <div className={small ? [styles.CSSSpinner, styles.small].join(' ') : styles.CSSSpinner}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <Typography
      className={small ? [styles.CSSSpinnerText, styles.small].join(' ') : styles.CSSSpinnerText}
      type={small ? TYPOGRAPHY_TYPES.BODY : TYPOGRAPHY_TYPES.HEADING_L}
    >
      {loadingMessage || 'Loading...'}
    </Typography>
  </div>
);

FullWidthCSSLoadingSpinner.defaultProps = {
  loadingMessage: undefined,
  small: false,
};

FullWidthCSSLoadingSpinner.propTypes = {
  loadingMessage: PropTypes.string,
  small: PropTypes.bool,
};

export default FullWidthCSSLoadingSpinner;
