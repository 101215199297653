import { AxiosRequestConfig, AxiosResponse } from 'axios';

const buildRequestMessage = (config: AxiosRequestConfig, status: number): string => {
  const baseUrl = config.baseURL ?? '';
  const url = config.url ?? '';
  const method = config.method ?? '';
  const urlPieces: string[] = [baseUrl.replace(/\/$/, ''), url.replace(/^\//, '')];
  return `${method.toUpperCase()} ${urlPieces.join('/')} ${status}`;
};

const buildLogDetails = (response: AxiosResponse<Response>): object => {
  const { config, status, data, headers } = response;
  const method: string = config.method ?? '';

  const message: string = buildRequestMessage(config, status);
  const context = {
    method: method.toUpperCase(),
    status_code: status,
    headers: {
      request: config.headers,
      response: headers,
    },
    body: config.data,
    params: config.params,
    response: data,
  };
  return { message, context };
};

export default buildLogDetails;
