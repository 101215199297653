import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFoodbombLogo } from '../../utils/Logos';
import FullWidthCSSLoadingSpinner from '../UI/FullWidthCSSLoadingSpinner/FullWidthCSSLoadingSpinner';
import { FoodbombAPI } from '../../AxiosInstances';
import useQuery from '../../hooks/useQuery/useQuery';
import useNotifications from '../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting';
import * as actionTypes from '../../reduxStore/actions/VenueActions';
import { NOTIFICATION_TYPES } from '../../utils/Constants';
import useRedirect from '../../hooks/useRedirect/useRedirect';
import { ErrorNotification } from '../UI/FB';

import styles from './AcceptExistingAccountInvite.module.scss';

const AcceptExistingAccountInvite: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const { redirectToHome } = useRedirect();
  const history = useHistory();
  const query = useQuery();
  const venueId = query.get('venueId');
  const uuid = query.get('uuid');
  const dispatch = useDispatch();

  const acceptInvitation = useCallback(() => {
    setIsLoading(true);
    if (uuid && venueId) {
      FoodbombAPI.post(`account/invitations/${venueId}/${uuid}/accept`)
        .then(() => {
          dispatch(actionTypes.clearVenue());
          const searchParams = new URLSearchParams();
          history.replace({
            pathname: history.location.pathname,
            search: searchParams.toString(),
          });
        })
        .catch((error) => {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: `Sorry, we are unable accept your invitation, please try again.`,
            timeout: 6000,
            closable: true,
          });
          if (error?.response?.status === 400 && error?.response?.data?.errors) {
            const apiValidateError = error.response.data.errors?.[0];

            if (apiValidateError) {
              setValidationError(apiValidateError.message);
            }
          }
          sendDatadogError('Failed to validate the invite for user', {
            error,
            location: 'Accept existing account invite page',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      redirectToHome();
    }
  }, [venueId, uuid, dispatch, createNotification, sendDatadogError, redirectToHome, history]);

  useEffect(() => {
    acceptInvitation();
  }, [acceptInvitation]);

  return (
    <div className={styles.GradientContainer}>
      <div className={styles.SolidContainer}>
        <div className={styles.ContentContainer}>
          <div className={styles.HeaderContainer}>
            <img src={getFoodbombLogo()} className={styles.FoodbombLogo} alt="foodbomb" />
          </div>
          {isLoading ? (
            <FullWidthCSSLoadingSpinner loadingMessage="Accepting invitation..." />
          ) : (
            <>
              {validationError ? (
                <ErrorNotification
                  title="We couldn't accept your invitation!"
                  body={validationError}
                  hideContactInfo
                  className={styles.NoAddressNotification}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptExistingAccountInvite;
