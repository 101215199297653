import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ErrorIcon from '@material-ui/icons/Error';

import { STATUS_CHIP_TYPES } from '../constants';
import styles from './StatusChip.module.scss';

/**
 * # Status Chip
 * Simple Foodbomb Button Component
 *
 * ## Modifiers
 * ### Type
 * ```
 * ['success', 'failed', 'pending']
 * ```
 *
 * ## PropTypes:
 * ```js
 *  StatusChip.propTypes = {
 *    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
 *    type: PropTypes.oneOf(Object.values(STATUS_CHIP_TYPES)).isRequired,
 *    hideIcon: PropTypes.bool,
 *    className: PropTypes.string,
 *    iconOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
 *  };
 * ```
 */

const StatusChip = ({ title, type, hideIcon, className, iconOverride }) => {
  const statusChipStyles = [styles.StatusChip];
  if (type) {
    statusChipStyles.push(styles[type.toLowerCase()]);
  }

  const getIcon = () => {
    if (iconOverride) {
      return iconOverride;
    }
    if (!type) {
      return <CheckCircleIcon className={styles.StatusChip__icon} />;
    }
    switch (type.toLowerCase()) {
      case STATUS_CHIP_TYPES.FAILED:
        return <ErrorIcon className={styles.StatusChip__icon} />;
      case STATUS_CHIP_TYPES.PENDING:
        return <WatchLaterIcon className={styles.StatusChip__icon} />;
      default:
        return <CheckCircleIcon className={styles.StatusChip__icon} />;
    }
  };

  const icon = getIcon();

  return (
    <div className={[styles.StatusChipContainer, className].join(' ')}>
      <div className={statusChipStyles.join(' ')}>
        {!hideIcon && icon}
        {title}
      </div>
    </div>
  );
};

StatusChip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  type: PropTypes.oneOf(Object.values(STATUS_CHIP_TYPES)).isRequired,
  hideIcon: PropTypes.bool,
  className: PropTypes.string,
  iconOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
};

export default StatusChip;
