import { AppBar, SwipeableDrawer } from '@material-ui/core';
import { useState } from 'react';
import { getFoodbombLogo } from '../../../utils/Logos.ts';
import WebsiteMobileDrawer from './WebsiteMobileDrawer';
import styles from './WebsiteNavigation.module.scss';
import WebsiteNavigationBar from './WebsiteNavigationBar';

const PublicWebsiteNavigation = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const handleMobileDrawerClick = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  const openMobileDrawer = () => setMobileDrawerOpen(true);
  const closeMobileDrawer = () => setMobileDrawerOpen(false);

  const toggleClasses = [styles.DrawToggle];
  if (mobileDrawerOpen) {
    toggleClasses.push(styles.open);
  }

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <AppBar className={styles.Navigation__AppBar}>
      <WebsiteNavigationBar
        logo={getFoodbombLogo()}
        toggleClasses={toggleClasses}
        handleMobileDrawerClick={handleMobileDrawerClick}
        closeMobileDrawer={closeMobileDrawer}
      />
      <SwipeableDrawer
        anchor="left"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={mobileDrawerOpen}
        onClose={closeMobileDrawer}
        onOpen={openMobileDrawer}
      >
        <WebsiteMobileDrawer handleCloseMobileDrawer={closeMobileDrawer} />
      </SwipeableDrawer>
    </AppBar>
  );
};

export default PublicWebsiteNavigation;
